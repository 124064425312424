import axios from "@/utils/axios";
import { SupplierParamsType, SuppliersListParamsType } from "app/types";

const API_BASE: string = process.env.GATSBY_API_BASE?.trimEnd();

// получение списка поставщиков
export const getSuppliers = async (params: SuppliersListParamsType) => {
  return axios.GET(API_BASE, "/api/iss/v1/suppliers", params);
};

export const createSupplier = async (
  name: string,
  inn: string,
  email: string,
  phone: string,
) => {
  return  axios.POST(API_BASE, "/api/iss/v1/suppliers", {
    name: name,
    inn: inn,
    email: email,
    phone: phone,
  });
};

export const getSupplier = async (supplierId: number) => {
  return axios.GET(API_BASE, `/api/iss/v1/supplier/${supplierId}`);
};

export const updateSupplier = async (supplierId: number, params: SupplierParamsType) => {
  return axios.PATCH(API_BASE, `/api/iss/v1/supplier/${supplierId}`, params);
};

export const deleteSupplier = async (supplierId: number) => {
  return axios.DELETE(API_BASE, `/api/iss/v1/supplier/${supplierId}`);
};

export const saveSupplierServiceSettings = async (
  getNewProductsFromTpPeriod: number,
  sendContractInTpPeriod: number,
  syncProductsWithTpPeriod: number,
  syncSuppliersWithTpPeriod: number,
  syncTpCategoriesPeriod: number,
  updateProductsFromTpPeriod: number,
) => {
  return  axios.POST(API_BASE, "/api/iss/v1/settings", {
    getNewProductsFromTpPeriod,
    sendContractInTpPeriod,
    syncProductsWithTpPeriod,
    syncSuppliersWithTpPeriod,
    syncTpCategoriesPeriod,
    updateProductsFromTpPeriod,
  });
};

export const getSupplierServiceSettings = async () => {
  return  axios.GET(API_BASE, "/api/iss/v1/settings");
};
