import React, { FC } from "react";

import Card from "@/components/ui-kit/card";

import {
  OrderItemsProductType,
  OrderItemsType,
  OrderType,
  OrderValueByCategoryType,
} from "app/types";
import CatalogPositionItem from "@/components/pages/platform/pages/cart/catalogPosition/catalogPositionItem";
import { useDispatch } from "react-redux";
import { deleteOrderPositionFailure, deleteOrderQuantityInfo } from "@/actions/orders.actions";
import css from "./structure.modules.scss";
import { formatAmount } from "@/utils/formatPrice";
import Button from "@/components/ui-kit/button";
import NewOrderPosition from "@/components/pages/platform/pages/CardOrder/structure/NewOrderPosition/NewOrderPosition";
import { AdditionalProductType } from "@/components/pages/platform/pages/CardOrder";

type StructurePropsType = {
  isEditMode: boolean;
  items?: OrderItemsType[];
  setCurrentOrder: React.Dispatch<React.SetStateAction<OrderType>>;
  additionalProducts: AdditionalProductType[];
  setAdditionalProducts: React.Dispatch<React.SetStateAction<AdditionalProductType[]>>;
  currency?: string;
  totalSum?: string;
  totalDiscount?: number;
  isProducts?: boolean;
  orderId?: number;
  totalValue?: number;
  diffValue?: number;
  valueByCategory?: OrderValueByCategoryType[];
};

const Structure: FC<StructurePropsType> = ({
  orderId,
  items,
  currency,
  totalValue,
  diffValue,
  isEditMode,
  setCurrentOrder,
  valueByCategory,
  additionalProducts,
  setAdditionalProducts,
}) => {
  const dispatch = useDispatch();

  const deleteOrderPosition = (productId: number) => {
    dispatch(deleteOrderQuantityInfo(orderId, productId));
    dispatch(deleteOrderPositionFailure());
  };

  // заглушка для новой позиции
  const addNewPosition = (): void => {
    setAdditionalProducts((prev: AdditionalProductType[]) => {
      return [
        ...prev,
        {
          productId: null,
          count: 1,
          itemPrice: 1,
        }
      ];
    })
  };

  const renderCatalogPositionItem = (): JSX.Element[] => {
    return items
      ?.flatMap((item: OrderItemsType) => item?.product)
      ?.map((item: OrderItemsProductType) => (
        <CatalogPositionItem
          item={item}
          items={items}
          images={item.images}
          key={item.id}
          status={item?.status}
          orderId={orderId}
          productId={item?.id}
          isOrderCard
          currency={currency}
          setCurrentOrder={setCurrentOrder}
          isEditMode={isEditMode}
          productName={item?.name}
          productPrice={item?.price}
          productValue={item?.value}
          productArticle={item?.article}
          cartPositionId={item.id}
          deleteCartPosition={deleteOrderPosition}
          valueByCategory={valueByCategory}
        />
    ));
  };

  const isDisabled: boolean = additionalProducts?.some(({ productId }) => !productId);

  return (
    <Card
      title="Состав"
      className="mt-1"
      containerClassName="mobile:mt-4"
      descriptionCardShown
    >
      <div>
        <ul className={`flex ${css.list}`}>
          <li className={`text-t1 ${css.columnImage}`} />
          <li className={`text-n2 text-blue-grey font-normal ${css.columnName}`}>Наименование / Артикул</li>
          {/*TODO: пока скрываем от всех цену */}
          {/*<li className={`text-n2 text-blue-grey font-normal ${css.columnPrice}`}>Цена</li>*/}
          <li className={`text-n2 text-blue-grey font-normal ${css.columnCount}`}>Количество</li>
          {/*TODO: пока скрываем от всех стоимость */}
          {/*<li className={`text-n2 text-blue-grey font-normal ${css.columnCost}`}>Стоимость</li>*/}
        </ul>
        <ul className="mb-3">
          {renderCatalogPositionItem()}
          {additionalProducts?.map((product: AdditionalProductType) => (
            <NewOrderPosition
              key={product.productId}
              product={product}
              items={items}
              currency={currency}
              additionalProducts={additionalProducts}
              setAdditionalProducts={setAdditionalProducts}
              isDisabledFields={isDisabled}
            />
          ))}
        </ul>
        <div className={`flex ${isEditMode ? "justify-between" : "justify-end"}`}>
          {isEditMode && (
            <Button
              className="mt-2"
              onClick={addNewPosition}
              disabled={isDisabled}
            >
              Добавить позицию
            </Button>
          )}
          <div className="flex flex-col justify-end">
            {/*TODO: пока скрываем от всех стоимость */}
            {/*{!!totalValue && (*/}
            {/*  <div className="flex items-center justify-end">*/}
            {/*    <span className="inline-block mr-3 text-n1 text-blue-grey">Итого:</span>*/}
            {/*    {formatAmount(totalValue.toString(), currency)}*/}
            {/*  </div>*/}
            {/*)}*/}
            {/*{diffValue &&*/}
            {/*  (diffValue > 0 ? (*/}
            {/*    <div className="flex items-center justify-end text-dark-asparagus">*/}
            {/*      + {formatAmount(diffValue.toString(), currency)}*/}
            {/*    </div>*/}
            {/*  ) : (*/}
            {/*    <div className="flex items-center justify-end text-error">*/}
            {/*      {formatAmount(diffValue.toString(), currency)}*/}
            {/*    </div>*/}
            {/*  ))}*/}
            <div className={`flex items-center justify-end`}>
              <span className="inline-block mr-3 text-n1 text-blue-grey">Кол-во позиций:</span>
              {items?.length}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Structure;
