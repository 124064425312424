import React, { FC, useState } from "react";
import * as OrdersAPI from "@/api/orders.api";
import dayjs from "dayjs";

import { Button, DatePicker, Form, message, Select } from "antd";

interface IReportsFormProps {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
type SelectOptionsType = {
  value: "orders" | "orders_items",
  label: string
};

const options: SelectOptionsType[] = [
  {
    value: "orders",
    label: "Отчет по заказам"
  },
  {
    value: "orders_items",
    label: "Отчет по товарным позициям"
  }
];

const ReportsForm: FC<IReportsFormProps> = ({isLoading, setIsLoading}): JSX.Element => {
  const [form] = Form.useForm();

  const { getFieldValue } = form;

  const [selectedReportType, setSelectedReportType] = useState<SelectOptionsType | null>(null);
  const [reportDateFrom, setReportDateFrom] = useState<string>("");
  const [reportDateTo, setReportDateTo] = useState<string>("");

  const reportDateFromTo = getFieldValue("reportDateFromTo");
  const reportType = getFieldValue("reportType");

  const isDisabled: boolean = !reportDateFromTo || !reportType || isLoading;

  const applyReportDateFromToFilter = (date: string[]): void => {
    setReportDateFrom(date[0]);
    setReportDateTo(date[1]);
  };

  const downloadReport = async (): Promise<void> => {
    setIsLoading(true);

    try {
      const response = await OrdersAPI.getReports(selectedReportType.value, reportDateFrom, reportDateTo);

      const fileName: string = `${selectedReportType.label}_${reportDateFrom}__${reportDateTo}`;
      const url: string = window.URL.createObjectURL(response.data);
      const link: HTMLAnchorElement = document.createElement("a");

      link.href = url;
      link.download = fileName;
      link.click();
    } catch (error) {
      message.error("При загрузке отчета произошла ошибка. Попробуйте еще раз");
    } finally {
      setIsLoading(false);
    }
  };

  const disabledDate = (current: dayjs.Dayjs): boolean => {
    return !!current && current > dayjs().endOf("day");
  };

  return (
    <Form className="flex" form={form}>
      <Form.Item
        style={{ width: "20%" }}
        className="mr-5"
        name="reportType"
      >
        <Select
          value={selectedReportType?.value}
          placeholder="Выберите тип отчета"
          options={options}
          onChange={(value, option) => setSelectedReportType(option as SelectOptionsType)}
        />
      </Form.Item>
      <Form.Item
        style={{ width: "31%" }}
        className="mr-5"
        name="reportDateFromTo"
      >
        <DatePicker.RangePicker
          style={{width: "100%"}}
          allowClear
          disabledDate={disabledDate}
          placeholder={["Начальная дата", "Конечная дата"]}
          format="YYYY-MM-DD"
          onChange={(date, dateString) => applyReportDateFromToFilter(dateString)}
        />
      </Form.Item>
      <Button
        type="primary"
        disabled={isDisabled}
        onClick={downloadReport}
      >
        Скачать отчет
      </Button>
    </Form>
  );
};

export default ReportsForm;
