import React, { FC } from "react";
import css from "./faq.modules.scss";

type FAQType = {
  requests: string[];
  handleClick: (e: any) => void;
};

const FAQ: FC<FAQType> = ({ requests, handleClick }) => {
  return (
    <section className={css.faq}>
      <h5 className={`text-h5 ${css.faq__header}`}>Популярные запросы</h5>
      <ul className={css.faq__list}>
        {requests.map((request) => (
          <li className={`text-h4 ${css.faq__item}`} key={request}>
            <a href="#" onClick={handleClick}>
              {request}
            </a>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default FAQ;
