export const roleResolver = (roles: string[]): { [key: string]: boolean } =>  {
  return {
    isSupplier: roles?.includes("Поставщик"),
    isPurchaser: roles?.includes("Заказчик"),
    isAuditor: roles?.includes("Аудитор"),
    isController: roles?.includes("Контролёр") || roles?.includes("Контролер"),
    isConcordant: roles?.includes("Согласовант"),
    isDemo: roles?.includes("Демо")
  };
};
