import React, { FC } from "react";
import { navigate } from "gatsby";

import css from "./catalogPositionType.modules.scss";

import CatalogPositionItem from "../catalogPositionItem";
import Button from "@/components/ui-kit/button";
import { BasketType, UserCartItemType } from "app/types";

type CatalogPositionTypeType = {
  positions?: UserCartItemType[];
  admin?: boolean;
  catalogPositionIndex?: number;
  deleteCartPosition: (productId: number) => void;
  id: number;
  onSubmit?: ({ positions }: { positions: BasketType["positions"] }) => void;
  seller: string;
};

const CatalogPositionType: FC<CatalogPositionTypeType> = ({
  id,
  positions,
  admin,
  deleteCartPosition,
}) => {
  const hasDeclineStatus = positions?.filter(position => position?.status !== "accepted");

  return (
    <>
      <div className={`${css.products}`}>
        <div className={`flex ${css.columnContainer}`}>
          <ul className={`flex ${css.list}`}>
            <li className={`text-t1 ${css.columnImage}`} />
            <li className={`text-n2 text-blue-grey font-normal ${css.columnName}`}>
              Наименование / Артикул
            </li>
            <li className={`text-n2 text-blue-grey font-normal ${css.columnCount}`}>
              Количество
            </li>
          </ul>
        </div>
        <ul>
          {positions?.map((item) => (
            <CatalogPositionItem
              isCart
              item={item}
              key={item.id}
              admin={admin}
              status={item?.status}
              images={item?.product?.images}
              productId={item?.product?.id}
              productName={item?.product?.name}
              productPrice={+item?.product?.price}
              productArticle={item?.product?.article}
              productValue={item?.value}
              cartPositionId={item.id}
              deleteCartPosition={deleteCartPosition}
            />
          ))}
        </ul>
      </div>
      {/*TODO: вернуть, когда на бэке сделают возможность создавать заказы по каждому поставщику отдельно */}
      {/*<div className="flex justify-center tablet:justify-end mt-4">*/}
      {/*  <Button*/}
      {/*    theme="filled"*/}
      {/*    className={`self-end ${css.button}`}*/}
      {/*    disabled={!!hasDeclineStatus?.length}*/}
      {/*    onClick={() => {*/}
      {/*      navigate(`${id}`)*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    создать запрос*/}
      {/*  </Button>*/}
      {/*</div>*/}
    </>
  );
};

export default CatalogPositionType;
