import React, { FC, useMemo } from "react";
import css from "./bidCard.modules.scss";
import cn from "classnames";
import pluralize from "@/utils/pluralize";
import { useSelector } from "react-redux";
import { AppStateType } from "@/reducers";
import { BidType } from "app/types";
import { format, formatDistanceToNowStrict } from "date-fns";
import ruLocales from "date-fns/locale/ru";
import Tooltip from "../ui-kit/tooltip";
import { Link } from "gatsby";
import { colorNameRequest, STATUS_COLOR } from "@/utils/statusColor";

type BidCardPropsType = {
  item: BidType;
  containerClassName?: string;
  aosDuration?: string;
  aosOffset?: string;
};

const BidCard: FC<BidCardPropsType> = ({
  item,
  containerClassName,
  aosDuration,
  aosOffset = "0",
}) => {
  const {
    id,
    name,
    initialPrice,
    betsAmount,
    status,
    startDate,
    expirationDate,
    cityName,
    msp,
    purchaserName,
  } = item;

  const formatDate = (dateString: string): string => {
    return format(new Date(dateString), "dd.MM.yyyy");
  };

  const actualColorNameRequest = useMemo(() => colorNameRequest(status), [
    status,
  ]);

  return (
    <a
      href={`/trade/${id}`}
      className={cn(
        `flex justify-between flex-col tablet:flex-row bg-white pt-4 pb-5 tablet:py-8 border-none ${css.item} ${containerClassName}`
      )}
      data-aos="fade-up"
      data-aos-duration={aosDuration}
      data-aos-once="true"
      data-aos-offset={aosOffset}
      target="_blank"
      rel="noopener"
    >
      <div
        className={cn(
          `absolute top-0 left-0 bottom-0 bg-electric-blue ${css.line}`,
          {
            "bg-light-blue": actualColorNameRequest === STATUS_COLOR.LIGHT_BLUE,
            "bg-blue": actualColorNameRequest === STATUS_COLOR.BLUE,
            "bg-sea​-wave": actualColorNameRequest === STATUS_COLOR.SEA_WAVE,
            "bg-coral": actualColorNameRequest === STATUS_COLOR.CORAL,
            "bg-silver": actualColorNameRequest === STATUS_COLOR.SILVER,
            "bg-dark-vivid-purple-crayola": actualColorNameRequest === STATUS_COLOR.DARK_VIVID_PURPLE_CRAYOLA,
            "bg-asparagus": actualColorNameRequest === STATUS_COLOR.ASPARAGUS,
            "bg-light-carmine-pink": actualColorNameRequest === STATUS_COLOR.LIGHT_CARMINE_PINK,
            "bg-slight-blue-gray": actualColorNameRequest === STATUS_COLOR.LIGHT_BLUE_GRAY,
            "bg-blue-frosty-sky": actualColorNameRequest === STATUS_COLOR.BLUE_FROSTY_SKY,
            "bg-pearlescent-light-gray": actualColorNameRequest === STATUS_COLOR.PEARLESCENT_LIGHT_GRAY,
            "bg-blue-crayola": actualColorNameRequest === STATUS_COLOR.BLUE_CRAYOLA,
            "bg-olive-yellow": actualColorNameRequest === STATUS_COLOR.OLIVE_YELLOW,
            "bg-dark-asparagus": actualColorNameRequest === STATUS_COLOR.DARK_ASPARAGUS,
            "bg-vivid-purple-crayola": actualColorNameRequest === STATUS_COLOR.VIVID_PURPLE_CRAYOLA,
            "bg-blue-grey": actualColorNameRequest === STATUS_COLOR.BLUE_GRAY,
            "bg-green": actualColorNameRequest === STATUS_COLOR.GREEN,
            "bg-error": actualColorNameRequest === STATUS_COLOR.ERROR,
          }
        )}
      ></div>
      <div
        className={`flex tablet:block tablet:pr-4 tablet:w-1/7 mb-3 tablet:mb-0`}
      >
        <div className={`w-1/2 tablet:w-auto`}>
          <p className={`text-n2 mb-0 tablet:mb-1`}>№ {id}</p>
          <p className={`uppercase text-n1 font-medium ${css.status}`}>
            {status}
          </p>
        </div>
        {msp && (
          <span className={`flex justify-center items-center ${css.msp}`}>
            МСП
          </span>
        )}
      </div>
      <div className={`tablet:w-2/6 tablet:mr-8 mb-auto tablet:mb-0`}>
        <p className={`text-s1 font-medium mb-1 ${css.description}`}>{name}</p>
        <p className={`text-n2 text-blue-grey`}>
          {betsAmount} {pluralize(betsAmount, ["предложение", "предложения", "предложений"])}
        </p>
      </div>

      <div className={`w-1/2 hidden tablet:flex`}>
        <div className={`block mr-8 w-1/3`}>
          <div className={`mb-3`}>
            <p className={`text-n2 text-blue-grey mb-1`}>Начальная цена</p>
            <p className={`text-s1 font-medium`}>
              {initialPrice ? initialPrice : "Не выставлена"}
            </p>
          </div>
        </div>
        <div className={`block justify-between w-1/3`}>
          <div className={`mb-3`}>
            <p className={`text-n2 text-blue-grey mb-1`}>Дата публикации</p>
            <p className={`text-s1 font-medium`}>{formatDate(startDate)}</p>
          </div>
          {cityName && (
            <div>
              <p className={`text-n2 text-blue-grey mb-1`}>Место торга</p>
              <div className={`flex`}>
                <Tooltip
                  className={css.tooltip}
                  text={
                    <p className={`text-s1 font-medium ${css.cityName}`}>
                      {cityName}
                    </p>
                  }
                  tooltipText={cityName}
                  isIconShow={false}
                  type="top-left"
                />
              </div>
            </div>
          )}
        </div>
        <div className={`block pr-4 pl-6 w-1/3`}>
          <p className={`text-n2 text-blue-grey mb-1`}>Приём заявок до</p>
          <p className={`text-s1 font-medium mb-1`}>
            {formatDate(expirationDate)}
          </p>
          <p className={`text-n2 ${css.term}`}>
            {new Date() < new Date(expirationDate)
              ? "еще " +
                formatDistanceToNowStrict(new Date(expirationDate), {
                  locale: ruLocales,
                })
              : "срок истек"}
          </p>
        </div>
      </div>

      <div className={`flex tablet:hidden justify-between`}>
        <div className={`w-1/2 mr-3`}>
          <p className={`text-n2 text-blue-grey mb-1`}>Начальная цена</p>
          <p className={`text-s1 font-medium`}>
            {initialPrice ? initialPrice : "Не выставлена"}
          </p>
        </div>
        <div className={`w-1/2`}>
          <p className={`text-n2 text-blue-grey mb-1`}>Приём заявок до</p>
          <p className={`text-s1 font-medium mb-1`}>
            {formatDate(expirationDate)}
          </p>
          <p className={`text-n2 ${css.term}`}>
            {new Date() < new Date(expirationDate)
              ? "еще " +
                formatDistanceToNowStrict(new Date(expirationDate), {
                  locale: ruLocales,
                })
              : "срок истек"}
          </p>
        </div>
      </div>
    </a>
  );
};

export default BidCard;
