import React, {
  FC,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { ProductType } from "app/types";
import css from "./CatalogProductHeadingBlock.modules.scss";
import CatalogProductDescriptionBlock from "../../CatalogProductBlocks/CatalogProductBlock/CatalogProductDescriptionBlock";
import PictureSlider from "@/components/ui-kit/pictureSlider/PictureSlider";
import CatalogProductCartBlock
  from "@/components/pages/platform/pages/Catalog/CatalogPage/CatalogProductCard/CatalogProductBlocks/CatalogProductBlock/CatalogProductCartBlock";
import {
  addProduct,
  addProductInCartFailure,
  removeProduct,
  removeProductFromCartFailure,
  updateProduct, updateProductInCartFailure
} from "@/actions/cart.actions";

interface ICatalogProductHeadingBlockProps {
  productData: ProductType;
  currentCategoryId?: string | number;
  productId: number;
}

const CatalogProductHeadingBlock: FC<ICatalogProductHeadingBlockProps> = ({
  productData,
  currentCategoryId,
  productId,
}) => {
  const dispatch = useDispatch();

  const [isCounterActive, setIsCounterActive] = useState<boolean>(false);
  const [itemCount, setItemCount] = useState<number>(1);

  const createBasket = (productId: number, quantity: number) => {
    dispatch(addProduct(productId, quantity));
    dispatch(addProductInCartFailure());
  };

  const deleteBasket = (productId: number) => {
    dispatch(removeProduct(productId));
    dispatch(removeProductFromCartFailure());
  };

  const putBasket = (productId: number, quantity: number) => {
    dispatch(updateProduct(productId, quantity));
    dispatch(updateProductInCartFailure());
  };

  return (
    <div className={`bg-white`}>
      <div className={css.content}>
        <PictureSlider images={productData?.images}/>
        <CatalogProductDescriptionBlock
          description={productData.description}
          baseCode={productData.article}
          category={productData?.tpCategoryName}
          //modifications={productData}
          //modificationCode={modificationCode}
          //changeModification={changeModification}
        />
        <CatalogProductCartBlock
          productId={productId}
          basePrice={productData.price}
          putBasket={putBasket}
          itemCount={itemCount}
          name={productData.name}
          amount={productData.rest}
          currency={productData.currencyCharCode}
          createBasket={createBasket}
          deleteBasket={deleteBasket}
          setItemCount={setItemCount}
          supplier={productData.supplier}
          isCounterActive={isCounterActive}
          currentCategoryId={currentCategoryId}
          setIsCounterActive={setIsCounterActive}
          //isMsp={productData.isMsp}
          //modificationRest={modificationRest}
          //deliveryRules={getDeliveryRules()[0]}
          //modificationPrice={modificationPrice}
          //organization={productData.organization}
          //product_value={productData.product_value}
          //hasModificationPrice={hasModificationPrice}
          //basePrice={
          //  productData.baseModification?.prices[0]?.currencyPrice ||
          //  productData.baseModification?.prices[0]?.currency_price
          //}
          //modificationCountInUsercart={modificationCountInUsercart}
          //supplierCompanyColor={productData.supplierCompanyColor}
        />
      </div>
    </div>
  );
};

export default CatalogProductHeadingBlock;
