import React, { FC } from "react";

import cn from "classnames";

import RectangleIcon from "@/assets/icons/rectangle.inline.svg";
import DeliveryTermsElement from "@/assets/icons/delivery-terms-element.inline.svg";

import css from "./deliveryTermsIcon.modules.scss";

type DeliveryTermsIconType = {
  className?: string;
};

const DeliveryTermsIcon: FC<DeliveryTermsIconType> = ({ className }) => (
  <div
    className={cn("", {
      [className]: className,
    })}
  >
    <DeliveryTermsElement />
    <div className={`flex ${css.rectangle}`}>
      <RectangleIcon className={css.rectangle__item} />
      <RectangleIcon className={css.rectangle__item} />
    </div>
  </div>
);

export default DeliveryTermsIcon;
