import React, { FC } from "react";

import cn from "classnames";

import CheckOnIcon from "@/assets/icons/check-on.inline.svg";
import CheckDisableIcon from "@/assets/icons/check-disable.inline.svg";
import CheckCircleOnIcon from "@/assets/icons/check-circle-on.inline.svg";

import css from "./checkbox.modules.scss";

type CheckboxPropsType = {
  containerClassName?: string;
  label?: string | React.ReactChild;
  disabled?: boolean;
  checked: boolean;
  onChange?: (e?: any) => void;
  type?: "default" | "radio" | "inactive";
};

const Checkbox: FC<CheckboxPropsType> = ({
  containerClassName = "",
  label,
  disabled = false,
  checked,
  onChange = () => {},
  type = "default",
}) => {
  return (
    <div className={`flex text-t1 ${containerClassName}`}>
      <label
        className={cn(
          `inline-flex items-center duration-300 text-black ${css.container}`,
          {
            [`text-blue-grey cursor-default`]: disabled,
            [`hover:text-electric-blue cursor-pointer`]: !disabled,
          }
        )}
      >
        <input
          className={`hidden ${css.checkbox}`}
          type="checkbox"
          disabled={disabled}
          checked={checked}
          onChange={onChange}
        />
        <div
          className={cn(`mr-2 ${css.customCheckbox}`, {
            [css.customCheckbox__default]: type === "default",
            [css.customCheckbox__radio]: type === "radio",
          })}
        >
          {type === "default" && <CheckOnIcon className={css.icon} />}
          {type === "radio" && <CheckCircleOnIcon className={css.icon} />}
          {type === "inactive" && <CheckDisableIcon className={css.icon} />}
        </div>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
