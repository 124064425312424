import React, { FC } from "react";

import { ImportantBannersType } from "app/types";
import Button from "@/components/ui-kit/button";
import Info from "@/assets/icons/info.inline.svg";
import css from "./importantCard.modules.scss";

type ImportantCardPropsType = {
  data: ImportantBannersType;
  containerClassName?: string;
};

const ImportantCard: FC<ImportantCardPropsType> = ({
  data,
  containerClassName,
}) => {

  return (
    <div
      className={`flex flex-col justify-between w-full ${css.item} ${containerClassName}`}
      style={{
        backgroundColor: data?.backgroundColor || "#ffffff",
        backgroundImage: data?.backgroundImage && `url(${document.location.origin}${data?.backgroundImage})`,
      }}
    >
      <div className={`flex justify-between items-center tablet:absolute ${css.iconWrapper}`}>
        {data?.icon ? (
          <img
            src={`${document.location.origin}${data?.icon}`}
            alt=""
            className={`${css.icon}`}
          />
        ) : (
          <Info className={`${css.icon}`} />
        )}
      </div>
      <div className={`mt-0 ${css.headerText} tablet:mt-14 leading-10 font-medium text-x3`}>
        {data?.headerText}
      </div>
      <Button className={`hidden bg-white tablet:block ${css.button}`}>
        {data?.buttonText || "Смотреть"}
      </Button>
    </div>
  );
};

export default ImportantCard;
