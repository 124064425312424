import React, {
  FC,
  useRef,
  useMemo,
  useState,
  useEffect,
} from "react";

import cn from "classnames";

import { OrderStatusesType } from "app/types";
import { useSelector } from "react-redux";
import { AppStateType } from "@/reducers";

import useMobile from "@/hooks/useMobile";

import Checkbox from "@/components/ui-kit/checkox";
import { Modal } from "antd";
import { roleResolver } from "@/utils/roleResolver";

import css from "./tabsBlock.modules.scss";

type TabsBlockPropsType = {
  statuses: OrderStatusesType[];
  updateStatus: () => void;
  setActiveStatuses: React.Dispatch<React.SetStateAction<OrderStatusesType[]>>;
  setNotActiveStatuses: React.Dispatch<React.SetStateAction<OrderStatusesType[]>>;
  count?: { current: number, total: number };
  namesForShowCount?: string[];
  currentActiveStatusIndex?: number;
};

const TabsBlock: FC<TabsBlockPropsType> = ({
  statuses,
  updateStatus,
  count,
  setActiveStatuses,
  setNotActiveStatuses,
  currentActiveStatusIndex,
  namesForShowCount,
}) => {
  const { isMobile } = useMobile();

  const { userInfo } = useSelector((state: AppStateType) => state.account);

  const { isSupplier, isPurchaser } = roleResolver(userInfo?.roles);

  const progressBarRef = useRef(null);

  const [lastActive, setLastActive] = useState<number>(null);
  const [activeTab, setActiveTab] = useState<number>(null);

  useEffect(() => {
    if (progressBarRef.current && currentActiveStatusIndex <= 4) {
      progressBarRef.current.scrollBy({
        left: (window.innerWidth / 4) * (currentActiveStatusIndex - 0.4),
        behavior: "smooth",
      });
    }
    if (progressBarRef.current && currentActiveStatusIndex >= 5) {
      progressBarRef.current.scrollBy({
        left: (window.innerWidth / 4) * (currentActiveStatusIndex - 0.5),
        behavior: "smooth",
      });
    }
  }, []);

  const openConfirmModal = (selectedStatusIndex: number): void => {
    const isFromNewToProcessingStatus: boolean = currentActiveStatusIndex === 0
      && selectedStatusIndex === 1;
    const isFromApprovedToClosedStatus: boolean = currentActiveStatusIndex === 5
      && selectedStatusIndex === 6;

    if (isSupplier && (isFromNewToProcessingStatus || isFromApprovedToClosedStatus)) {
      Modal.confirm({
        cancelText: "Отмена",
        okText: "Подтвердить",
        title: "Подтвердите изменение статуса заказа",
        onOk() {
          changeStatus(selectedStatusIndex);
        },
      });
    }
  };

  const changeStatus = (selectedStatusIndex: number): void => {
    updateStatus();

    setActiveStatuses(
      statuses?.slice(1, selectedStatusIndex + 1)?.map((status: OrderStatusesType) => {
        return {
          name: status?.name,
          status: status?.status,
          isActive: true,
        };
      })
    );

    setNotActiveStatuses((prev: OrderStatusesType[]) => prev
      ?.filter((s: OrderStatusesType) => statuses[selectedStatusIndex].status !== s.status)
    );
  };

  const showCount = (name: string): boolean => namesForShowCount.includes(name);

  const renderStatusProvider = useMemo(() =>
    statuses.map(({ name, isActive, status }, i) => {
      isActive &&
        statuses[i + 1] &&
        !statuses[i + 1].isActive &&
        setLastActive(i);

      return (
        <div
          key={i}
          className={`${css.statusWrapper} flex mobile:flex-col w-full relative`}
        >
          <div
            className={cn(`h-5 ${css.line}`, {
              [`${css.isActive}`]: isActive,
              [`${css.notActive}`]: !isActive,
              [`${css.lastActive}`]: isActive && lastActive === i,
              [`${css.lineFirstNoActive}`]: !isActive && lastActive + 1 === i,
              [`${css.lineNoActive}`]: !isActive && lastActive + 1 !== i,
            })}
          ></div>
          <div
            className={cn(`flex flex-col `, {
              [`${css.tabProvider}`]: isMobile && i > 0,
            })}
          >
            <div
              className={`flex cursor-pointer ${css.tabActive}`}
              onMouseEnter={() => setActiveTab(i)}
              onMouseLeave={() => setActiveTab(null)}
            >
              <Checkbox checked={isActive} onChange={() => openConfirmModal(i)} />
              <div className={`ml-1 ${css.tabText}`}>
                {name}
                {!!count && showCount(name as string) && (
                  <span> ({count.current} / {count.total})</span>
                )}
              </div>
            </div>
          </div>
          {/*  Стили для тултипов по всем статусам прогресс бара  */}
          {!isMobile &&
            activeTab === i &&
            name !== "Выполнен" &&
            !isPurchaser && (
              <div className={css.prompt}>
                <div className="relative">
                  <div className={css.square}></div>
                  <div className={css.textWhite}>
                    Следите за актуальностью статусов заказа, чтобы закупщик мог отслеживать процесс
                  </div>
                </div>
              </div>
            )}
          {/*  Стили для тултипа последнего статуса прогресс бара  */}
          {!isMobile &&
            activeTab === i &&
            activeTab === statuses?.length - 1 &&
            name === "Выполнен" &&
            !isPurchaser && (
              <div className={css.promptLast}>
                <div className="relative">
                  <div className={css.squareLast}></div>
                  <div className={css.textWhite}>
                    Следите за актуальностью статусов заказа, чтобы закупщик мог отслеживать процесс
                  </div>
                </div>
              </div>
            )}
        </div>
      );
    }), [statuses, lastActive, activeTab]);

  return (
    <div
      id="scroll"
      ref={progressBarRef}
      className="mt-6 flex justify-between px-6 -mx-6 overflow-x-auto"
    >
      <div className="text-n2 flex px-6 tablet:px-8 -mx-6 tablet:-mx-8">
        <div className={`${css.statusProvider} flex tablet:justify-between`}>
          {renderStatusProvider}
        </div>
      </div>
    </div>
  );
};

export default TabsBlock;
