import { useEffect, useState } from "react";
import { useLocation, useWindowScroll } from "react-use";

const usePageScroll = () => {
  const { y } = useWindowScroll();
  const [winY, setWinY] = useState(y);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setWinY((prev) => {
      if (prev > y) {
        setIsScrolled(false);
      }
      if (prev < y && y > 200) {
        setIsScrolled(true);
      }
      return y;
    });
  }, [y, winY, isScrolled]);

  useEffect(() => {
    setIsScrolled(false);
  }, [location.pathname]);

  return { isScrolled };
};

export default usePageScroll;
