import React, { FC } from "react";
import { Tooltip } from "antd";

interface IAdaptableBlockProps {
  itemName: string;
  title?: string;
  titleClassName?: string;
  containerClassName?: string;
  className?: string;
  stringLength?: number;
}

const AdaptableBlock: FC<IAdaptableBlockProps> = ({
  itemName,
  title,
  titleClassName = "",
  containerClassName = "mt-2 text-s1 font-medium text-black",
  className = "",
  stringLength = 70,
}): JSX.Element => {
  return (
    <>
      {title && <p className={titleClassName}>{title}</p>}
      <p className={containerClassName}>
        {itemName?.length > stringLength ? (
          <Tooltip title={itemName}>
            {itemName?.substring(0, stringLength).trim()}
            {itemName?.length > stringLength && <span>...</span>}
          </Tooltip>
        ) : (
          <span className={className}>{itemName}</span>
        )}
      </p>
    </>
  );
};

export default AdaptableBlock;
