import React, { FC } from "react";

import cn from "classnames/bind";

import css from "./logoIcon.modules.scss";

const cx = cn.bind(css);

interface StarredIconProps {
  Icon: string;
  className?: string;
  organizationFirstLetter?: string;
}

const LogoIcon: FC<StarredIconProps> = ({
  Icon,
  className,
  organizationFirstLetter,
}) => (
  <div className={cx("relative", "starredIcon", className)}>
    {
      Icon 
      ? <img src={Icon} className={cx("absolute inset-0", "starredIcon__main")} />
      : <div className={cx("absolute inset-0", "starredIcon__main", "starredIcon__defaltIcon")}>
          {organizationFirstLetter}
        </div>
    }
  </div>
);

export default LogoIcon;
