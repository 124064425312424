import React, { FC } from "react";

import DownloadIcon from "@/assets/icons/download-icon.inline.svg";

import css from "./titleDescription.modules.scss";

import { formatAmount } from "@/utils/formatPrice";

type TitleDescriptionPropsType = {
  orderId: number;
  name: string;
  price: number;
  currency?: string;
  isHiddenSupplierCard: boolean;
};

const TitleDescription: FC<TitleDescriptionPropsType> = ({
  orderId,
  name,
  price,
  currency,
  isHiddenSupplierCard,
}) => (
  <>
    <div className={css.orderTitle}>№{orderId}</div>
    <div className={`mt-5 tablet:mt-2 ${css.orderName}`}>{name}</div>
    {/*TODO: пока скрываем от всех стоимость */}
    {/*<div className="mt-4 tablet:mt-3">*/}
    {/*  <div className={css.priceTitle}>Итоговая стоимость</div>*/}
    {/*  <div className="mt-2 flex-col tablet:flex-row flex tablet:mt-1">*/}
    {/*    <div className={css.price}>{!!price ? formatAmount(price?.toString(), currency) : "На согласовании"}</div>*/}
    {/*    /!* скрываем пока не реализован функционал */}
    {/*     <div className={`flex mt-4 tablet:mt-0 ${css.protocol}`}>*/}
    {/*      <DownloadIcon />*/}
    {/*      <div className="ml-2 tablet:ml-1 uppercase text-n1">*/}
    {/*        выгрузить протокол цены*/}
    {/*      </div>*/}
    {/*    </div> *!/*/}
    {/*  </div>*/}
    {/*</div>*/}
  </>
);

export default TitleDescription;
