import React, { FC, useState, useCallback, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { SearchResultType } from "app/types";
import {
  getSearchPagination,
} from "@/actions/search.actions";
import css from "./client.modules.scss";
import NoResult from "../noresult";
import OrderCard from "@/components/orderCard";
import ProductCard from "@/components/productCard";
import BidCard from "@/components/bidCard";
import SearchItem from "@/components/searchItem";
import SuppliersCard from "@/components/suppliersCard";
import Description from "@/components/suppliersCard/description";
import ProductCardProvider from "@/components/ProductCardProvider";
import DocumentFlow from "@/components/documentFlow";
import TabButton from "@/components/ui-kit/tabButton";

const cx = cn.bind(css);

type ClientTypeProps = {
  results: SearchResultType[];
  countSections: number;
};

enum TAB_BUTTON_CAP {
  ALL = "",
  ORDERS = "v1/orders/",
  BIDS = "v2/bids/",
  PRODUCTS = "v2/products/search/",
  DOCUMENT = "v1/signdocs/search/",
  SUPPLIERS = "v1/suppliers/",
}

const Client: FC<ClientTypeProps> = ({
  results,
  countSections,
}) => {
  const dispatch = useDispatch();

  const [tabButtonCap, setTabButtonCap] = useState("");

  const [tabButtonCaps, setTabButtonCaps] = useState([]);

  const onTabButtonCap = (value: string) => setTabButtonCap(value);
  const filterPath = (path: string) =>
    results.filter((r) => r.path === path)[0];

  useEffect(() => {
    if (results.length === countSections) {
      setTabButtonCaps(results.map((r) => r.path));
    }
  }, [results, countSections]);

  const onPagination = (name: string) => dispatch(getSearchPagination(name));

  const renderOrders = useCallback(
    (o) => (
      <SearchItem
        title={o.name==='Торги'?'Запросы':o.name}
        count={o.results.length}
        maxCount={o.count}
        isFetching={o.isFetching}
        classNameIdent={css.indent}
        onClick={() => onPagination(o.name)}
      >
        {o.results.map((o) => {
          const item = {
            id: o.id,
            name: o.orderName,
            orderSum: o.orderSum,
            supplier: o.supplier.name,
            contractor: o.contractor ? o.contractor.name : null,
            deliveryDate: o.deliveryDate,
            statusName: o.statusName,
            bid: o.bid,
          };
          return <OrderCard key={o.id} item={null} />;
        })}
      </SearchItem>
    ),
    []
  );

  const renderCatalog = useCallback(
    (r) => (
      <SearchItem
        title={r.name==='Торги'?'Запросы':r.name}
        count={r.results.length}
        maxCount={r.count}
        classNameIdent={css.indent}
        isFetching={r.isFetching}
        onClick={() => onPagination(r.name)}
      >
        <div className={css.productCardContainer}>
          {r.results.length > 0 &&
            r.results.map((c) => {
              const item = {
                id: c.id,
                price: c.baseModification?.prices[0]?.currencyPrice || c.baseModification?.prices[0]?.currency_price,
                img: !!c.images.length ? c.images["0"].image : "",
                info: c.properties.map(
                  (p) => ({
                    value: p.value,
                    title: p.name,
                  })
                ),
                categoryId: c.category,
                category: c.category,
                code: c.code,
                name: c.name,
                priceComparisonColor: c.defaultPrice
                  ? c.defaultPrice.priceComparisonColor
                  : "grey",
                product: c.name,
                rating: c.rating,
                colors: [],
                description: c.description,
                images: c.images,
                organization: c.supplierCompany ?
                    c.supplierCompany.name :
                    c.supplier ?
                    c.supplier.name :
                    null,
                amount: 1,
                isMsp: c.supplierCompany ? c.supplierCompany.isMsp : null,
                supplierCompanyColor: c.supplierCompany ?
                    c.supplierCompany.rating.color :
                    c.supplier ?
                    c.supplier.rating.color :
                    null,
                baseModification: c.baseModification,
              };
              return (
                  <ProductCard
                    key={c.id}
                    view="list"
                    productItem={item}
                    className={`relative ${css.productCard}`}
                  />
              );
            })}
        </div>
      </SearchItem>
    ),
    []
  );

  const renderMyCatalog = useCallback(
    (c) => (
      <SearchItem
        title={c.name==='Торги'?'Запросы':c.name}
        count={c.results.length}
        maxCount={c.count}
        isFetching={c.isFetching}
        classNameIdent={css.indent}
        onClick={() => onPagination(c.name)}
      >
        <div className={css.productCardContainer}>
          {c.results.map((item) => (
            <ProductCardProvider
              key={item.id}
              className="mb-1"
              id={item.id}
              status="размещен"
              category={item.category}
              price={item.baseModification?.prices[0]?.currencyPrice || item.baseModification?.prices[0]?.currency_price}
              color={"green"}
              name={item.name}
              code={item.code}
              images={item.images}
              currency={item.currencyCharCode}
            />
          ))}
        </div>
      </SearchItem>
    ),
    []
  );

  const renderBids = useCallback(
    (bids) => (
      <SearchItem
        title={bids.name==='Торги'?'Запросы':bids.name}
        count={bids.results.length}
        maxCount={bids.count}
        isFetching={bids.isFetching}
        classNameIdent={css.indent}
        onClick={() => onPagination(bids.name)}
      >
        {bids.results.map((b, index) => {
          const item = {
            id: b.id,
            name: b.contractor.company.name,
            initialPrice: b.price,
            betsAmount: b.countBets,
            status: b.statusName,
            startDate: b.dateStart,
            expirationDate: b.expirationDate,
            cityName: b.cityName,
            msp: b.forMsp,
            purchaserName: b.contractor.company.name,
            cityId: b.city,
          };
          return <BidCard key={b.id} item={item} />;
        })}
      </SearchItem>
    ),
    []
  );

  const renderDocumentFlow = useCallback(
    (documents) => (
      <SearchItem
        title={documents.name==='Торги'?'Запросы':documents.name}
        count={documents.results.length}
        maxCount={documents.count}
        isFetching={documents.isFetching}
        classNameIdent={css.requests}
        onClick={() => onPagination(documents.name)}
      >
        {documents.results.map((d) => {
          const item = {
            contractNumber: d.id,
            docStatus: d.docStatus.name,
            contractName: d.name,
            productNumber: d.order,
            productName: d.originalFilename,
            organizationName: d.recipient.name,
            createdAt: String(d.createdAt),
          };
          return (
            <div key={d.id} className="mt-1">
              <DocumentFlow {...item} />
            </div>
          );
        })}
      </SearchItem>
    ),
    []
  );

  const renderProvider = useCallback(
    (provider) => (
      <SearchItem
        title={provider.name==='Торги'?'Запросы':provider.name}
        count={provider.results.length}
        maxCount={provider.count}
        isFetching={provider.isFetching}
        classNameIdent={css.indent}
        onClick={() => onPagination(provider.name)}
      >
        {provider.results.map((p) => (
          <SuppliersCard
            key={p.id}
            id={p.id}
            path={provider.path}
            className="mb-1"
            icon={p.profile.logo}
            organization={p.name}
            countProducts={p.countProducts}
            optionalCol1={
              <Description
                className={cx(
                  "mt-4 tablet:mt-0",
                  "suppliersPage__optionalCol1"
                )}
                title="Регион"
              >
                {p.region ? (
                  <div className="text-h4">{p.region}</div>
                ) : (
                  <div className="text-s1 whitespace-pre">Не указан</div>
                )}
              </Description>
            }
          />
        ))}
      </SearchItem>
    ),
    []
  );

  const renderSwitchResult = useCallback(
    (r: SearchResultType) => {
      const caseName = r ? r.path : "";
      switch (caseName) {
        case TAB_BUTTON_CAP.ORDERS:
          return !!r.count && renderOrders(r);
        case TAB_BUTTON_CAP.PRODUCTS:
          return !!r.count
            ? renderCatalog(r)
            : renderMyCatalog(r);
        case TAB_BUTTON_CAP.SUPPLIERS:
          return !!r.count && renderProvider(r);
        case TAB_BUTTON_CAP.BIDS:
          return !!r.count && renderBids(r);
        case TAB_BUTTON_CAP.DOCUMENT:
          return !!r.count && renderDocumentFlow(r);
        default:
          return null;
      }
    },
    []
  );

  const totalCount = useMemo(
    () => results.reduce((acc, res) => acc + res.count, 0),

    [results]
  );

  const renderTabs = useMemo(
    () =>
      results.map(
        (r) =>
          !!r.count && (
            <TabButton
              key={r.ordering}
              className={css.tabButtonCap}
              onClick={() => onTabButtonCap(r.path)}
              isActive={
                tabButtonCaps.find((t) => t === tabButtonCap) === r.path
              }
            >
              {r.name==='Торги'?`Запросы (${r.count})`:`${r.name} (${r.count})`}
            </TabButton>
          )
      ),
    [results, tabButtonCap, tabButtonCaps]
  );

  const renderSearchCase = useMemo(() => {
    switch (tabButtonCap) {
      case TAB_BUTTON_CAP.ORDERS:
        const order = filterPath(TAB_BUTTON_CAP.ORDERS);

        return renderSwitchResult(order);
      case TAB_BUTTON_CAP.BIDS:
        const bid = filterPath(TAB_BUTTON_CAP.BIDS);

        return renderSwitchResult(bid);
      case TAB_BUTTON_CAP.PRODUCTS:
        const product = filterPath(TAB_BUTTON_CAP.PRODUCTS);

        return renderSwitchResult(product);
      case TAB_BUTTON_CAP.DOCUMENT:
        const doc = filterPath(TAB_BUTTON_CAP.DOCUMENT);

        return renderSwitchResult(doc);
      case TAB_BUTTON_CAP.SUPPLIERS:
        const supplier = filterPath(TAB_BUTTON_CAP.SUPPLIERS);

        return renderSwitchResult(supplier);
      default:
        return results.map((r) => renderSwitchResult(r));
    }
  }, [results, tabButtonCap, tabButtonCaps, filterPath]);

  if (!!totalCount)
    return (
      <>
        <div>
          <div
            className={`flex justify-between mb-5 tablet:mb-8 overflow-auto px-6 tablet:px-8 -mx-6 tablet:-mx-8 ${css.tabsWrapper}`}
          >
            <div className="flex justify-start items-center px-6 tablet:px-8 -mx-6 tablet:-mx-8">
              <TabButton
                className={css.tabButtonCap}
                onClick={() => onTabButtonCap("")}
                isActive={tabButtonCap === TAB_BUTTON_CAP.ALL}
              >
                Все результаты ({totalCount})
              </TabButton>
              {renderTabs}
            </div>
          </div>
        </div>
        {renderSearchCase}
      </>
    );

  return <NoResult />;
};

export default Client;
