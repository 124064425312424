import React, { FC } from "react";

import useMobile from "@/hooks/useMobile";

import Button from "@/components/ui-kit/button";

import ArrowDownIcon from "@/assets/icons/arrow-down.inline.svg";
import TickDown from "@/assets/icons/tick-down.inline.svg";

type BottomButtonCatalogProps = {
  onUpdate?: () => void;
  title?: string;
  entity?: string;
  value?: number;
};

const BottomButtonCatalog: FC<BottomButtonCatalogProps> = ({
  onUpdate,
  title = "ПОКАЗАТЬ ЕЩЁ",
}) => {
  const { isMobile } = useMobile();
  return isMobile ? (
    <Button
      onClick={() => {}}
      className={`flex tablet:hidden w-full`}
      data-aos="fade-zoom-in"
      data-aos-once="true"
    >
      {title}
      <ArrowDownIcon className={`stroke-current ml-2`} />
    </Button>
  ) : (
    <div className="flex items-start pointer" onClick={onUpdate}>
      {title}
      <TickDown className="ml-1 stroke-current" />
    </div>
  );
};

export default BottomButtonCatalog;
