import {
  GET_USER_CART_SUCCESS,
  GET_USER_CART_FAILURE,
  ADD_PRODUCT_IN_CART_SUCCESS,
  ADD_PRODUCT_IN_CART_FAILURE,
  REMOVE_PRODUCT_FROM_CART_SUCCESS,
  REMOVE_PRODUCT_FROM_CART_FAILURE,
  UPDATE_PRODUCT_IN_CART_SUCCESS,
  UPDATE_PRODUCT_IN_CART_FAILURE,
} from "@/constants/actionTypes";
import { ThunkAction } from "redux-thunk";
import { AppStateType } from "@/reducers";
import * as CartAPI from "@/api/cart.api";
import { UserCartType } from "app/types";

export type CartActionsTypes =
  | GetUserCartSuccessType
  | GetUserCartFailureType
  | AddProductInCartSuccessType
  | AddProductInCartFailureType
  | RemoveProductFromCartSuccessType
  | RemoveProductFromCartFailureType
  | UpdateProductInCartSuccessType
  | UpdateProductInCartFailureType;

type GetUserCartSuccessType = {
  type: typeof GET_USER_CART_SUCCESS;
  payload: {
    userCart: UserCartType;
  };
};

export const getUserCartSuccess = (
  userCart: UserCartType
): GetUserCartSuccessType => ({
  type: GET_USER_CART_SUCCESS,
  payload: {
    userCart: userCart,
  },
});

type GetUserCartFailureType = {
  type: typeof GET_USER_CART_FAILURE;
  payload: {
    error: string;
  };
};

export const getUserCartFailure = (
  error?: string
): GetUserCartFailureType => ({
  type: GET_USER_CART_FAILURE,
  payload: {
    error,
  },
});

export const getUserCartProducts = ()
  :ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  CartActionsTypes
  > => async (dispatch) => {
  try {
    const response = await CartAPI.getUserCart();

    dispatch(getUserCartSuccess(response.data));
  } catch (err) {
    dispatch(getUserCartFailure(err.response?.data?.detail));
  }
};

type AddProductInCartSuccessType = {
  type: typeof ADD_PRODUCT_IN_CART_SUCCESS;
  payload: {
    addProductStatus: number;
  };
};

export const addProductInCartSuccess = (
  addProductStatus: number
): AddProductInCartSuccessType => ({
  type: ADD_PRODUCT_IN_CART_SUCCESS,
  payload: {
    addProductStatus: addProductStatus,
  },
});

type AddProductInCartFailureType = {
  type: typeof ADD_PRODUCT_IN_CART_FAILURE;
  payload: {
    error: string;
  };
};

export const addProductInCartFailure = (
  error?: string
): AddProductInCartFailureType => ({
  type: ADD_PRODUCT_IN_CART_FAILURE,
  payload: {
    error,
  },
});

export const addProduct = (
  productId: number,
  quantity: number,
) :ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  CartActionsTypes
  > => async (dispatch) => {
  try {
    const response = await CartAPI.addProductInCart(productId, quantity);

    dispatch(addProductInCartSuccess(response.status));
  } catch (err) {
    dispatch(addProductInCartFailure(err.response?.data?.detail));
  }
};

type RemoveProductFromCartSuccessType = {
  type: typeof REMOVE_PRODUCT_FROM_CART_SUCCESS;
  payload: {
    removeProductStatus: number;
  };
};

export const removeProductFromCartSuccess = (
  removeProductStatus: number
): RemoveProductFromCartSuccessType => ({
  type: REMOVE_PRODUCT_FROM_CART_SUCCESS,
  payload: {
    removeProductStatus: removeProductStatus,
  },
});

type RemoveProductFromCartFailureType = {
  type: typeof REMOVE_PRODUCT_FROM_CART_FAILURE;
  payload: {
    error: string;
  };
};

export const removeProductFromCartFailure = (
  error?: string
): RemoveProductFromCartFailureType => ({
  type: REMOVE_PRODUCT_FROM_CART_FAILURE,
  payload: {
    error,
  },
});

export const removeProduct = (productId: number, quantity?: number)
  :ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  CartActionsTypes
  > => async (dispatch) => {
  try {
    const response = await CartAPI.removeProductFromCart(productId, quantity);

    dispatch(removeProductFromCartSuccess(response.status));
  } catch (err) {
    dispatch(removeProductFromCartFailure(err.response.data.detail));
  }
};

type UpdateProductInCartSuccessType = {
  type: typeof UPDATE_PRODUCT_IN_CART_SUCCESS;
  payload: {
    updateProductStatus: number;
  };
};

export const updateProductInCartSuccess = (
  updateProductStatus: number
): UpdateProductInCartSuccessType => ({
  type: UPDATE_PRODUCT_IN_CART_SUCCESS,
  payload: {
    updateProductStatus: updateProductStatus,
  },
});

type UpdateProductInCartFailureType = {
  type: typeof UPDATE_PRODUCT_IN_CART_FAILURE;
  payload: {
    error: string;
  };
};

export const updateProductInCartFailure = (
  error?: string
): UpdateProductInCartFailureType => ({
  type: UPDATE_PRODUCT_IN_CART_FAILURE,
  payload: {
    error,
  },
});

export const updateProduct = (
  productId: number,
  quantity: number,
) :ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  CartActionsTypes
  > => async (dispatch) => {
  try {
    const response = await CartAPI.updateProductInCart(productId, quantity);

    dispatch(updateProductInCartSuccess(response.status));
  } catch (err) {
    dispatch(updateProductInCartFailure(err.response.data.detail));
  }
};
