import React, { useState, FC, CSSProperties, useCallback } from "react";
import cn from "classnames";
import moment from "moment";
import { isInclusivelyBeforeDay, SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import ruLocale from "moment/locale/ru";
import "react-dates/lib/css/_datepicker.css";
import css from "./datepicker.modules.scss";
import "./datepicker.scss";
import TickRight from "@/assets/icons/tick-right.inline.svg";
import TickLeft from "@/assets/icons/tick-left.inline.svg";
import DatepickerIcon from "@/assets/icons/date-picker.inline.svg";
import shortid from "shortid";
import Button from "../button";
import useMobile from "@/hooks/useMobile";

const CalendarActions = ({
  onTodayClick,
  onTomorrowClick,
}: {
  onTodayClick: () => void;
  onTomorrowClick: () => void;
}) => {
  return (
    <div className={`flex pl-5 pb-8`}>
      <Button className={`mr-2`} onClick={onTodayClick}>
        Сегодня
      </Button>
      <Button onClick={onTomorrowClick}>Завтра</Button>
    </div>
  );
};

type DatepickerPropsType = {
  containerClassName?: string;
  containerStyle?: CSSProperties;
  placeholder?: string;
  dateFormat?: string;
  value?: Date | string;
  isPrevDatesDisabled?: boolean;
  showCalendarActions?: boolean;
  onChange?: (value: Date | string) => void;
  readOnly?: boolean;
  isError?: boolean;
};

const Datepicker: FC<DatepickerPropsType> = ({
  containerClassName,
  containerStyle= {},
  placeholder,
  dateFormat = "YYYY/MM/DD",
  value,
  showCalendarActions = false,
  isPrevDatesDisabled = false,
  onChange,
  readOnly = true,
  isError = false,
}) => {
  const [date, setDate] = useState(value ? moment(value) : null);
  const [focused, setFocused] = useState<boolean>(false);

  moment.updateLocale("ru", ruLocale);

  const id = shortid();

  const onDateChange = useCallback((newDate) => {
    setDate(newDate);

    if (newDate && newDate.isValid()) {
      onChange(newDate.toDate());
    }
  }, [onChange]);

  const onMobileDateChange = useCallback((e) => {
    const newDate = moment(e.target.value);

    if (newDate && newDate.isValid()) {
      onChange(newDate.toDate());
    }
  }, [onChange]);

  const onTodayClick = (): void => {
    onChange(moment().toDate());

    setDate(moment());
    setFocused(false);
  };

  const onTomorrowClick = (): void => {
    const tomorrowDate: moment.Moment = moment().add(1, "days");

    onChange(tomorrowDate.toDate());

    setDate(tomorrowDate);
    setFocused(false);
  };

  const isOutsideRange = () => {
    if (isPrevDatesDisabled)
      return (day) => isInclusivelyBeforeDay(day, moment().subtract(1, "day"));

    return () => false;
  };

  const { isMobile } = useMobile();

  return (
    <div
      className={cn(`w-full ${css.container} ${containerClassName}`, {
        [css.focused]: focused,
        [`withPlaceholder`]: placeholder,
        [css.error]: isError,
      })}
      style={containerStyle}
    >
      <SingleDatePicker
        calendarInfoPosition="after"
        renderCalendarInfo={
          showCalendarActions
            ? () => (
                <CalendarActions
                  onTodayClick={onTodayClick}
                  onTomorrowClick={onTomorrowClick}
                />
              )
            : null
        }
        readOnly={readOnly}
        displayFormat={() => dateFormat}
        date={date} // momentPropTypes.momentObj or null
        onDateChange={onDateChange} // PropTypes.func.isRequired
        focused={focused} // PropTypes.bool
        onFocusChange={() => setFocused((focused) => !focused)} // PropTypes.func.isRequired
        id={id} // PropTypes.string.isRequired,
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        noBorder
        navPrev={
          <div className={`${css.tickLeft}`}>
            <TickLeft className={`stroke-current`} />
          </div>
        }
        navNext={
          <div className={`${css.tickRight}`}>
            <TickRight className={`stroke-current`} />
          </div>
        }
        customInputIcon={<DatepickerIcon className={css.datepickerIcon} />}
        isOutsideRange={isOutsideRange()}
        placeholder=""
        transitionDuration={0}
      />
      <label
        htmlFor={id}
        className={cn(`select-none text-t1`, css.label, {
          [css.labelActive]: focused || value,
        })}
      >
        {placeholder}
      </label>
      {isMobile && (
        <input
          value={date ? date.format("YYYY-MM-DD") : null}
          className={css.nativeInput}
          min={
            isPrevDatesDisabled &&
            moment().subtract(1, "day").format("YYYY-MM-DD")
          }
          type="date"
          onChange={onMobileDateChange}
        />
      )}
    </div>
  );
};

export default Datepicker;
