const priceRegExp = /\B(?=(\d{3})+(?!\d))/g;
const NO_PRICE = "нет цены";

export const convertPrice = (
  count: number,
  modPrice: number | null,
  basePrice: number
) => {
  const currentPrice = modPrice || basePrice;
  if (currentPrice) {
    let fullPrice = (currentPrice * count).toFixed(2).split(".");
    fullPrice[0] = fullPrice[0].replace(priceRegExp, " ");

    return `${fullPrice.join(",")} ₽`;
  }

  return NO_PRICE;
};
