import React, { FC, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import { useSelector } from "react-redux";
import { AppStateType } from "@/reducers";
import SEO from "@/components/seo";
import PlatformBlock1 from "@/components/pages/platform/pages/index/blocks/block1";
import PlatformBlock2 from "@/components/pages/platform/pages/index/blocks/block2";

interface IPlatformIndexProps extends RouteComponentProps {}

const PlatformIndex: FC<IPlatformIndexProps> = () => {

  const { portalSettingsData } = useSelector((state: AppStateType) => state.account);

  // В этом хуке обновляем заголовок сайта
  useEffect(() => {
    if (!!portalSettingsData) {
      document.title = portalSettingsData?.portalName;
    }
  }, [portalSettingsData]);


  return (
    <>
      <SEO />
      <PlatformBlock1 />
      <PlatformBlock2 />
    </>
  );
};

export default PlatformIndex;
