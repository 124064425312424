import React, { FC, useRef, useState, useEffect, useMemo } from "react";

import { Controller, useForm } from "react-hook-form";

import { caseNameDefault } from "@/utils/caseNameStatus";

import Modal from "@/components/ui-kit/modal";
import Input from "@/components/ui-kit/input";
import Counter from "@/components/ui-kit/counter";
import Button from "@/components/ui-kit/button";
import ModalPlug from "@/components/ui-kit/modalPlug";

import CompanyIcon from "@/assets/icons/company2-icon.inline.svg";
import CloseIcon from "@/assets/icons/close-middle.inline.svg";

import css from "./deliveryCalculation.modules.scss";

type UseFormType = {
  company: string;
  priceDelivery: number;
  unitPrice: number;
  deliveryTerms: number;
  actualDeliveryData: number;
};

type DeliveryCalculationPropsType = {};

const DeliveryCalculation: FC<DeliveryCalculationPropsType> = () => {
  const [caseName, setCaseName] = useState(caseNameDefault.DEFAULT);
  const ref = useRef();

  useEffect(() => {
    setCaseName(caseNameDefault.DEFAULT);

    return () => {
      setCaseName(caseNameDefault.DEFAULT);
    };
  }, []);

  const closeIsModal = () => {
    setCaseName(caseNameDefault.DEFAULT);
  };

  const { control} = useForm<
    UseFormType
  >({
    defaultValues: {
      company: "",
      priceDelivery: null,
      unitPrice: null,
      deliveryTerms: 1,
      actualDeliveryData: 1,
    },
    mode: "onChange",
    shouldUnregister: false,
  });

  const onSubmit = () => {
    setCaseName(caseNameDefault.SUCCESS);
  };

  const onOk = () => {
    closeIsModal();
  };

  const renderSwitch = useMemo(() => {
    switch (caseName) {
      case caseNameDefault.DEFAULT:
        return (
          <div className="pt-7 pl-6 tablet:pl-8 pr-6 tablet:pr-8 pb-8 h-full bg-white overflow-auto">
            <div className="flex justify-end">
              <CloseIcon className="cursor-pointer" onClick={closeIsModal} />
            </div>
            <div className="mt-9 tablet:mt-11 text-x2">
              Запрос на рассчет доставки
            </div>
            <div className="mt-3 pr-1 text-t1 text-blue-grey">
              Покупатель просит рассчитать вас стоимость доставки
            </div>
            <div className="mt-6">
              <div className="border-t-1 border-b-1 border-dark-grey-txt">
                <div className="mt-3 ml-6 tablet:ml-7 mb-4 tablet:mb-6 flex">
                  <CompanyIcon className={css.productIcon} />
                  <div className={css.productName}>
                    <div className="text-t1">
                      Дрель-шуруповерт аккумуляторная Makita DF347DWE
                    </div>
                    <div className="text-n2">
                      <span className="text-blue-grey">6 шт. по 0.5 л</span>
                      <span>{" / "} MVVJ2RU/A</span>
                    </div>
                    <div className="text-t1 mt-1 tablet:mt-3">12 штук</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8 tablet:mt-6 pb-8 border-b-1 border-dark-grey-txt">
              <div className="flex">
                <div className={css.productDescrimtion}>
                  <div className="text-n2 text-blue-grey">Адрес доставки</div>
                  <div className="mt-1 text-t1">
                    г. Москва, Лаврушинский переулок, 7
                  </div>
                </div>
                <div className={`ml-10 tablet:ml-9 ${css.productDescrimtion}`}>
                  <div className="text-n2 text-blue-grey">Способ доставки</div>
                  <div className="mt-1 text-t1">Самый дешевый</div>
                </div>
              </div>
            </div>
            <div className="mt-8">
              <Controller
                control={control}
                name="company"
                rules={{ required: true }}
                render={({ onChange, value }) => (
                  <Input
                    theme="grey"
                    placeholder="Транспортная компания"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <Controller
                control={control}
                name="priceDelivery"
                rules={{ required: true }}
                render={({ onChange, value }) => (
                  <Input
                    theme="grey"
                    className="mt-2 tablet:mt-4"
                    placeholder="Стоимость доставки"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <Controller
                control={control}
                name="unitPrice"
                rules={{ required: true }}
                render={({ onChange, value }) => (
                  <Input
                    theme="grey"
                    className="mt-2 tablet:mt-4"
                    placeholder="Итоговая стоимость еденицы"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <Controller
              control={control}
              name="deliveryTerms"
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <>
                  <div className={`mt-6 tablet:mt-8 ${css.counterTitle}`}>
                    Укажите срок доставки
                  </div>
                  <Counter
                    className="mt-3 tablet:mt-4"
                    min={1}
                    max={999}
                    value={value}
                    setValue={onChange}
                  />
                </>
              )}
            />
            <Controller
              control={control}
              name="actualDeliveryData"
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <>
                  <div className={`mt-6 tablet:mt-8 ${css.counterTitle}`}>
                    Сколько дней актуальны данные доставки
                  </div>
                  <Counter
                    className="mt-3 tablet:mt-4"
                    min={1}
                    max={999}
                    value={value}
                    setValue={onChange}
                  />
                </>
              )}
            />
            <Button
              onClick={onSubmit}
              className="mt-10 tablet:mt-8 mobile:w-full"
            >
              Отправить
            </Button>
          </div>
        );
      case caseNameDefault.SUCCESS:
        return (
          <ModalPlug
            title="Расчет отправлен покупателю"
            text="Если покупателя все устраивает, он свяжется с вами в течение трех дней."
            onClose={closeIsModal}
            onOk={onOk}
          />
        );
      default:
        return null;
    }
  }, [caseName, onSubmit, onOk]);

  return (
    <Modal
      className="flex justify-end h-full"
      isOpen={false}
      onClose={closeIsModal}
    >
      <div ref={ref} className={`bg-white ${css.deliveryCalculation}`}>
        {renderSwitch}
      </div>
    </Modal>
  );
};

export default DeliveryCalculation;
