import React, { FC, useMemo } from "react";
import TabButton from "@/components/ui-kit/tabButton";
import css from "./tabsBlock.modules.scss";
import { FacetsType } from "app/types";

import { getOrdersList } from "@/actions/orders.actions";

type TabsBlockPropsType = {
  facets?: FacetsType[];
  handleTabClick: (index: number, statusName?: string) => void
  activeIndex: number;
};

const TabsBlock: FC<TabsBlockPropsType> = ({
  facets,
  handleTabClick,
  activeIndex,
}) => {
  const renderTabs = useMemo(
    () =>
      facets
        .map(({ name, status }, index) => (
          <TabButton
            key={name}
            className={css.primaryTab}
            onClick={() => {
              getOrdersList({ page: 1, pageSize: 25, statuses: status })
              handleTabClick(index, status);
            }}
            isActive={activeIndex === index}
          >
            {name}
          </TabButton>
        )),
    [facets, handleTabClick, activeIndex]
  );

  return (
    <div
      className={`flex justify-between pb-2 overflow-x-scroll`}
      data-aos="fade-zoom-in"
      data-aos-once="true"
    >
      {renderTabs}
    </div>
  );
};

export default TabsBlock;
