import {
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
  READ_NOTIFICATION_SUCCESS,
  READ_NOTIFICATION_FAILURE,
} from "@/constants/actionTypes";
import { ThunkAction } from "redux-thunk";
import { AppStateType } from "@/reducers";
import * as NotificationsAPI from "@/api/notifications.api";
import {
  NotificationDataType
} from "app/types";


export type NotificationActionsTypes =
  | GetNotificationSuccessType
  | GetNotificationFailureType
  | ReadNotificationSuccessType
  | ReadNotificationFailureType;

type GetNotificationSuccessType = {
  type: typeof GET_NOTIFICATION_SUCCESS;
  payload: {
    notifications: NotificationDataType;
  };
};

const getNotificationSuccess = (
  notifications: NotificationDataType
): GetNotificationSuccessType => ({
  type: GET_NOTIFICATION_SUCCESS,
  payload: {
    notifications
  },
});

type GetNotificationFailureType = {
  type: typeof GET_NOTIFICATION_FAILURE;
  payload: {
    error: string;
  };
};

const getNotificationFailure = (error?: string): GetNotificationFailureType => ({
  type: GET_NOTIFICATION_FAILURE,
  payload: {
    error,
  },
});

export const getNotificationsList = (
  page?: number,
  pageSize?: number,
  isRead?: boolean,
): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  NotificationActionsTypes
> => async (dispatch) => {
  try {
    const response = await NotificationsAPI.getNotifications(page, pageSize, isRead);

    dispatch(getNotificationSuccess(response.data));
  } catch (err) {
    dispatch(getNotificationFailure(err.response?.data.detail));
  }
};

type ReadNotificationSuccessType = {
  type: typeof READ_NOTIFICATION_SUCCESS;
  payload: {
    notificationReadStatus: number;
  };
};

const readNotificationSuccess = (
  notificationReadStatus: number
): ReadNotificationSuccessType => ({
  type: READ_NOTIFICATION_SUCCESS,
  payload: {
    notificationReadStatus
  },
});

type ReadNotificationFailureType = {
  type: typeof READ_NOTIFICATION_FAILURE;
  payload: {
    error: string;
  };
};

const readNotificationFailure = (error?: string): ReadNotificationFailureType => ({
  type: READ_NOTIFICATION_FAILURE,
  payload: {
    error,
  },
});

export const readNotifications = (
notificationId: number,
isRead?: boolean,
): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  NotificationActionsTypes
> => async (dispatch) => {
  try {
    const response = await NotificationsAPI.updateNotification(notificationId, isRead);

    dispatch(readNotificationSuccess(response.status));
  } catch (err) {
    dispatch(readNotificationFailure(err.response?.data.detail));
  }
};
