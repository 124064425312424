import React, { FC, useRef, ReactNode } from "react";
import cn from "classnames";
import TooltipIcon from "@/assets/icons/tooltip.inline.svg";
import TooltipWhiteIcon from "@/assets/icons/tooltip-white.inline.svg";
import css from "./tooltip.modules.scss";

type TooltipPropsType = {
  text?: string | ReactNode;
  tooltipText: string;
  className?: string;
  isIconShow?: boolean;
  type?: "top-center" | "top-left" | "top-right";
  iconType?: "grey" | "white";
};

const Tooltip: FC<TooltipPropsType> = ({
  text,
  tooltipText,
  className,
  isIconShow = true,
  type = "top-center",
  iconType = "grey",
}) => {
  return (
    <div
      tabIndex={-1}
      className={cn(`inline-block outline-none`, css.container, className)}
    >
      {text}
      {isIconShow && iconType === "grey" && (
        <TooltipIcon className={`cursor-pointer ${css.icon}`} />
      )}
      {isIconShow && iconType === "white" && (
        <TooltipWhiteIcon className={`cursor-pointer ${css.icon}`} />
      )}
      <span
        className={cn(`text-n2`, css.tooltiptext, {
          [css.topCenter]: type === "top-center",
          [css.topLeft]: type === "top-left",
          [css.topRight]: type === "top-right",
        })}
        dangerouslySetInnerHTML={{ __html: tooltipText }}
      />
    </div>
  );
};

export default Tooltip;
