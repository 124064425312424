import React, { FC } from "react";

import useMobile from "@/hooks/useMobile";

import WhiteCircleIcon from "@/assets/icons/white-circle.inline.svg";

import css from "./deliveryCalculation.modules.scss";

type PropsDeliveryCalculationType = {
  message: string;
  isRead: boolean;
};

const DeliveryCalculation: FC<PropsDeliveryCalculationType> = ({
  message,
  isRead,
}) => {
  const onOpenModal = () => {

  };

  return (
    <div
      onClick={onOpenModal}
      className="pl-6 pb-2 pr-16 tablet:pt-2 tablet:pl-3 tablet:pr-3 tablet:pb-2"
    >
      <div className="relative">
        <div className="text-s1 text-black tablet:text-white">
          {message.substring(0, 26).trim()}
        </div>
        {!isRead && (
          <WhiteCircleIcon
            className={`absolute right-0 top-2 tablet:right-0 tablet:top-3 ${css.iconColor}`}
          />
        )}
      </div>
    </div>
  );
};

export default DeliveryCalculation;
