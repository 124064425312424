import React, {
  FC,
  Dispatch,
  useState,
  useEffect,
  SetStateAction,
  useMemo,
} from "react";
import { AppStateType } from "@/reducers";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "@reach/router";
import { getProductsCatalogList } from "@/actions/products.actions";
import { getTpCategoriesList } from "@/actions/categories.actions";
import { CatalogList } from "./CatalogList/CatalogList";
import { CatalogFilters } from "../CatalogFilters/CatalogFilters";
import { Button } from "antd";
import { ControlTwoTone } from "@ant-design/icons";
import EmptyResult from "@/components/ui-kit/emptyResult";
import * as Btn from "@/components/ui-kit/button";
import CatalogIcon from "@/assets/icons/catalog.inline.svg";
import CloseIcon from "@/assets/icons/close-catalog.inline.svg";
import CatalogPopup from "@/components/catalogMenu";
import { BreadcrumbsLink } from "@/components/ui-kit/breadCrumbs";
import Preloader from "@/components/ui-kit/preloader";
import _get from "lodash/get";
import css from "../Catalog.modules.scss";
import SearchInput from "@/components/ui-kit/searchInput";
import { debounce } from "lodash";

interface ICatalogPageProps extends RouteComponentProps {
  currentPage?: number;
  setCurrentPage?: Dispatch<SetStateAction<number>>;
  links?: BreadcrumbsLink[];
  currentActiveStatus?: string;
  supplierId?: number;
  setSupplierId?: Dispatch<SetStateAction<number>>;
  setProductName?: Dispatch<SetStateAction<string>>;
  productArticle?: string;
  setProductArticle?: Dispatch<SetStateAction<string>>;
  isProductsFiltersHovered?: boolean;
  setProductsFiltersHovered?: Dispatch<SetStateAction<boolean>>;
}

const CatalogPage: FC<ICatalogPageProps> = ({
  currentPage,
  setCurrentPage,
  supplierId,
  setSupplierId,
  setProductName,
  productArticle,
  setProductArticle,
  isProductsFiltersHovered,
  setProductsFiltersHovered,
}) => {
  const dispatch = useDispatch();

  const {
    isFetching,
    productsCatalogList,
  } = useSelector((state: AppStateType) => state.products);

  const { tpCategories } = useSelector((state: AppStateType) => state.categories);

  const [isCatalogActive, setIsCatalogActive] = useState(false);
  const [activeItem, setActiveItem] = useState<number>(null);

  const toggleCatalogActive = () => setIsCatalogActive(!isCatalogActive);

  useEffect(() => {
    tpCategories && setActiveItem(_get(tpCategories, "[0].id", 1));
  }, [tpCategories]);

  useEffect(() => {
    dispatch(getTpCategoriesList(10, activeItem));
  }, []);

  const fetchProductCatalogList = (page: number, search: string | null): void => {
    dispatch(
      getProductsCatalogList({
        page,
        pageSize: 25,
        supplierId: supplierId?.toString() || null,
        article: productArticle || null,
        search: search || null,
      })
    );
  };

  const onSearch = (value: string): void => {
    fetchProductCatalogList(1, value?.trim());
  };

  const debouncedSearchValue = useMemo(() => {
    return debounce((value: string) => {
      onSearch(value);
    }, 500);
  }, []);

  return (
    <div>
      <h2 data-aos="fade-zoom-in" data-aos-once="true" className={`mb-6 hidden tablet:block text-x1`}>
        Каталог товаров
      </h2>
      {!isFetching ? (
        <>
          {/*<div className="flex justify-between mb-2 items-end">*/}
          {/*  <Breadcrumbs links={links} className={css.breadcrumbs} />*/}
          {/*</div>*/}
          <div className="flex justify-between">
            <Btn.default
              className={`justify-between ${css.categoriesButton}`}
              theme="filled"
              onClick={toggleCatalogActive}
            >
              {!isCatalogActive ? <CatalogIcon className="mr-2" /> : <CloseIcon className="mr-2" />}
              <span className={css.buttonText}>Каталог</span>
            </Btn.default>
            <SearchInput changeValue={debouncedSearchValue} maxLength={200} />
          </div>
          {isCatalogActive && (
            <CatalogPopup
              onClose={() => setIsCatalogActive(false)}
              activeItem={activeItem}
              setActiveItem={setActiveItem}
            />
          )}
          <div className="mb-5 text-right">
            <Button
              className="relative"
              type="text"
              onClick={() => setProductsFiltersHovered(!isProductsFiltersHovered)}
              icon={<ControlTwoTone className={css.catalogBtnFilerIcon} rev={undefined} />}
            />
          </div>
          <div className={isProductsFiltersHovered ? css.catalogFilterHidden : css.catalogFilterShown}>
            <CatalogFilters
              isCatalog
              setCurrentPage={setCurrentPage}
              setSupplierId={setSupplierId}
              setProductName={setProductName}
              setProductArticle={setProductArticle}
            />
          </div>
          <div className={isProductsFiltersHovered ? css.catalogListHidden : css.catalogListShown}>
            {!productsCatalogList?.length && (
              <div className="mb-5">
                <EmptyResult text="Здесь будет ваш каталог" />
              </div>
            )}
            <CatalogList currentPage={currentPage} setCurrentPage={setCurrentPage} />
          </div>
        </>
      ) : (
        <div className="flex justify-center">
          <Preloader />
        </div>
      )}
    </div>
  );
};

export default CatalogPage;
