import { useState, useEffect, useRef } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export default function useBodyScrollLock(isOpen) {
  const [lock, setLock] = useState(false);
  const ref = useRef(null);
  const currentRef = ref.current;

  useEffect(() => {
    if (lock) {
      disableBodyScroll(currentRef, { reserveScrollBarGap: true });
    } else {
      enableBodyScroll(currentRef);
    }

    return function cleanup() {
      return enableBodyScroll(currentRef);
    };
  }, [lock, currentRef]);

  useEffect(() => {
    setLock(isOpen);

    return () => {
      setLock(false);
    };
  }, [setLock, isOpen]);

  return ref;
}
