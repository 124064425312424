import React, {
  FC,
  useState,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Input,
  Button,
} from "antd";
import { AppStateType } from "@/reducers";
import {
  getSupplierInfo,
  createNewSupplier,
  updateSupplierInfo,
  getSupplierFailure,
} from "@/actions/suppliers.actions";
import { RouteComponentProps } from "@reach/router";
import { navigate } from "gatsby";
import Loader from "@/components/ui-kit/loader";

interface ICreateNewUserFormProps extends RouteComponentProps {
  supplierId?: number;
  isCreateForm?: boolean;
}

export const SuppliersCreateOrUpdateForm: FC<ICreateNewUserFormProps> = ({
  supplierId,
}) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const {
    error,
    supplier,
    newSuppler,
    updateSupplerStatus,
  } = useSelector((state: AppStateType) => state.suppliers);

  const [currentErr, setCurrentErr] = useState<string>(null);
  const [isCreateForm, setCreateForm] = useState<boolean>(false);
  const [updateSupplierPerms, setUpdateSupplierPerms] = useState<boolean>(false);

  // TODO: удалить или актуализировать согласно требованиям
  // useEffect(() => {
  //   if (accountMe?.isSuperuser) setUpdateSupplierPerms(false);
  //   if (accountMe?.permissions?.includes("update_supplier")) setUpdateSupplierPerms(false);
  //   if (!accountMe?.isSuperuser && !accountMe?.permissions?.includes("update_supplier")) setUpdateSupplierPerms(true);
  // }, [accountMe]);

  useEffect(() => {
    if (supplierId) {
      setCreateForm(false);
      dispatch(getSupplierInfo(supplierId));
      navigate(`/platform/suppliers/${supplierId}/`);
    }
    if (!supplierId) {
      setCreateForm(true);
      navigate(`/platform/suppliers/createSupplier/`);
    }
  },[supplierId]);

  useEffect(() => {
    if (error) {
      setCurrentErr(error);
    }
    if (newSuppler) {
      setCurrentErr('');
    }
    if (updateSupplerStatus) {
      setCurrentErr('');
    }
  }, [error, newSuppler, updateSupplerStatus]);

  useEffect(() => {
    if (currentErr !== null && !currentErr) {
      navigate('/platform/suppliers/');
    }
  }, [currentErr]);

  const createOrUpdateSuppler = () => {
    const inn = form.getFieldValue("inn");
    const name = form.getFieldValue("name");
    const email = form.getFieldValue("email");
    const phone = form.getFieldValue("phone");

    dispatch(!supplierId
      ? createNewSupplier(name, inn, email, phone)
      : updateSupplierInfo(supplierId, name, inn, email, phone)
    );
  };

  const backToSupplerListPage = () => {
    navigate('/platform/suppliers/');
    dispatch(getSupplierFailure());
  };

  return (
    <div>
      {(supplier || isCreateForm) ?
        (
          <>
            <h3 className="font-bold mb-7">
              {supplierId ? `Редактирование поставщика ${supplier?.name}` : 'Создать поставщика'}
            </h3>
            <Form
              form={form}
              name="register"
              layout="vertical"
              onFinish={createOrUpdateSuppler}
            >
              <Form.Item
                name="name"
                label="Поставщик"
                rules={[
                  {
                    required: !supplierId,
                    message: "Пожалуйста, введите название поставщика!"
                  }
                ]}
                initialValue={supplier?.name || null}
              >
                <Input placeholder="Поставщик" />
              </Form.Item>
              <Form.Item
                name="inn"
                label="ИНН"
                rules={[
                  {
                    required: true,
                    message: 'Пожалуйста, введите ИНН!'
                  }
                ]}
                initialValue={supplierId ? supplier?.inn : null}
              >
                <Input placeholder="ИНН" />
              </Form.Item>
              <Form.Item
                name="email"
                label="Адрес электронной почты"
                rules={[
                  {
                    required: !supplierId,
                    message: 'Пожалуйста, введите адрес электронной почты!'
                  }
                ]}
                initialValue={supplierId ? supplier?.email : null}
              >
                <Input placeholder="Адрес электронной почты" />
              </Form.Item>
              <Form.Item
                name="phone"
                label="Номер телефона"
                rules={[
                  {
                    required: !supplierId,
                    message: 'Пожалуйста, введите номер телефона!'
                  }
                ]}
                initialValue={supplierId ? supplier?.phone : null}
              >
                <Input placeholder="Номер телефона" />
              </Form.Item>
              {!!supplierId && (
                <div className="mb-3">
                  {supplier?.tpId !== null ?
                    (<span className="text-asparagus">Поставщик синхронизирован с ТП</span>)
                    :
                    (<span className="text-error">Поставщик не синхронизирован с ТП</span>)
                  }
                  {!!supplier?.tpSyncError && !!supplierId && (
                    <span className="block mb-3 text-error">
                    {supplier?.tpSyncError}
                  </span>
                  )}
                </div>
              )}
              <div className="flex justify-between">
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={updateSupplierPerms}
                  >
                    {supplierId ? "Сохранить" : "Создать"}
                  </Button>
                </Form.Item>
                <Button
                  onClick={backToSupplerListPage}>
                  Отмена
                </Button>
              </div>
            </Form>
            {!!error && (
              <div className="text-error text-n1">
                {Array.isArray(error)
                  ? error.map((err, index) => {
                    return <span key={index} className="block mb-2">{err.msg}</span>
                  })
                  : error
                }
              </div>
            )}
          </>
        )
        :
        (
          <div className='flex justify-center'>
            <Loader className='' />
          </div>
        )
      }
    </div>
  );
};
