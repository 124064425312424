const pluralize = (num, declensions) => {
  let count = +num % 100;
  if (count >= 5 && count <= 20) {
    return declensions["2"];
  } else {
    count = count % 10;
    if (count === 1) {
      return declensions["0"];
    } else if (count >= 2 && count <= 4) {
      return declensions["1"];
    } else {
      return declensions["2"];
    }
  }
};

export default pluralize;
