import React, {
  FC,
  useMemo,
  useCallback,
} from "react";
import { useDispatch } from "react-redux";

import { RouteComponentProps } from "@reach/router";
import { Link } from "gatsby";
import cn from "classnames/bind";

import css from "./catalogSubMenu.modules.scss";
import { CategoriesTpType } from "app/types";
import { getProductsCatalogList } from "@/actions/products.actions";
import { getTpCurrentCategory } from "@/actions/categories.actions";

const cx = cn.bind(css);

interface CatalogPopupSublistProps extends RouteComponentProps {
  id: number;
  childrens: CategoriesTpType[];
  activeItem: number;
  onClose: () => void;
}

const CatalogSubMenu: FC<CatalogPopupSublistProps> = ({
  id,
  childrens,
  activeItem,
  onClose,
}) => {
  const dispatch = useDispatch();

  const onLink = useCallback((childrenItem, itemId: number) => {
    // TODO: уточнить itemId и к чему относится в старой версии
    dispatch(getProductsCatalogList({ page: 1, pageSize: 25, tpCategoryId: itemId})); 
    dispatch(getTpCurrentCategory(childrenItem));
    onClose();
  }, []);

  const renderSubCategories = useMemo(
    () =>
      childrens?.map(({
        id,
        name,
        children
      }) => (
        <li key={id} className={`w-1/2 ${cx("sublist__listItem")}`}>
          <p className={cx("sublist__listItemTitle")}>{name}</p>
          <div className="mt-4">
            {children?.map((childrenItem) => (
              <Link
                key={childrenItem?.id}
                className={cx("sublist__listItemText")}
                to={`/platform/catalog/${childrenItem?.id}`}
                onClick={() => onLink(childrenItem, childrenItem?.id)}
              >
                {childrenItem?.name}
              </Link>
            ))}
          </div>
        </li>
      )),
    [childrens, onLink]
  );

  return (
    <ul
      className={cx("sublist", "z-50 right-0 inset-y-0 flex-wrap", {
        sublist_active: activeItem === id,
      })}
    >
      {renderSubCategories}
    </ul>
  );
};

export default CatalogSubMenu;
