import React, { FC, useState } from "react";
import ArrowUp from "@/assets/icons/new-arrow-up.inline.svg";
import ArrowDown from "@/assets/icons/new-arrow-down.inline.svg";
import Button from "@/components/ui-kit/button";
import cn from "classnames/bind";
import css from "./CatalogProductInfoBlock.modules.scss";
import { ProductPropertyType } from "app/types";
import {Tooltip} from "antd";

const cx = cn.bind(css);

type CatalogInfoProductBlock = {
  info: ProductPropertyType[];
};

const CatalogInfoProductBlock: FC<CatalogInfoProductBlock> = ({ info }) => {
  const [isCharsHidden, setIsCharsHidden] = useState<boolean>(true);

  const toggleIsHidden = () => setIsCharsHidden((prev) => !prev);

  return (
    <div className={cx("bg-white mt-1 px-5 py-4 tablet:py-4 tablet:px-6")}>
      <div className="flex justify-between items-center">
        <p className="text-s1">Характеристики</p>
        <Button onClick={toggleIsHidden} theme="withoutBorder">
          {isCharsHidden ? <ArrowDown /> : <ArrowUp />}
        </Button>
      </div>
      {!isCharsHidden && !!info?.length ? (
        <div
          className={cx(
            "flex justify-between flex-wrap mt-2 tablet:mt-3",
            "chars__content"
          )}
        >
          {info?.map(({ name, value }, index) => (
            <div
              key={index}
              className={cx("flex mt-2 tablet:mt-1", "chars__item")}
            >
              <p
                className={cx(
                  "text-blue-grey mr-4 overflow-hidden tablet:mr-6",
                  "chars__itemTitle"
                )}
              >
                {name}
              </p>
              <p className="overflow-hidden">{value}</p>
            </div>
          ))}
        </div>
      ) : (
        !isCharsHidden && (
          <div className={`mt-5 tablet:mt-6 ${css.textGray}`}>
            Нет характеристик
          </div>
        )
      )}
    </div>
  );
};

export default CatalogInfoProductBlock;
