import axios from "@/utils/axios";

const GATSBY_MESSAGES_API = process.env.GATSBY_MESSAGES_API;

export const getNotifications = async (
  page?: number,
  pageSize?: number,
  isRead?: boolean,
  ) => {
  return axios.GET(GATSBY_MESSAGES_API, "messages/v1/notifications", {
    page,
    pageSize,
    isRead,
  });
};

export const updateNotification = async (
  notificationId: number,
  isRead?: boolean,
) => {
  return axios.POST(GATSBY_MESSAGES_API, `messages/v1/notifications/${notificationId}/read`, {
    isRead,
  });
};
