import React, {
  FC,
  useState,
  useEffect, Dispatch, SetStateAction,
} from "react";
import { AppStateType } from "@/reducers";
import { useDispatch, useSelector } from "react-redux";
import { getSuppliersList } from "@/actions/suppliers.actions";
import { getProductsCatalogList, getProductsList } from "@/actions/products.actions";
import {
  Form,
  theme,
  Input,
  Select,
  Button,
} from "antd";
import { roleResolver } from "@/utils/roleResolver";

const { Option } = Select;

interface ICatalogFiltersProps {
  isCatalog?: boolean;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setSupplierId?: Dispatch<SetStateAction<number>>;
  setProductName?: Dispatch<SetStateAction<string>>;
  setProductArticle?: Dispatch<SetStateAction<string>>;
}

export const CatalogFilters:FC<ICatalogFiltersProps> = ({
  isCatalog,
  setCurrentPage,
  setSupplierId,
  setProductName,
  setProductArticle,
}) => {
  const [suppliersSearchValue, setSuppliersSearchValue] = useState<string>('');

  const [form] = Form.useForm();

  const { token } = theme.useToken();

  const dispatch = useDispatch();

  const { suppliers } = useSelector((state: AppStateType) => state.suppliers);
  const { userInfo } = useSelector((state: AppStateType) => state.account);

  const { isDemo, isSupplier } = roleResolver(userInfo?.roles);

  useEffect(() => {
    if (!isSupplier && !isDemo && !suppliers.length) {
      dispatch(getSuppliersList({ page: 1, pageSize: 25 }));
    }
  }, []);

  const resetFields = () => {
    setProductName(null);
    setProductArticle(null);
    setSupplierId(null);
    setCurrentPage(1);

    form.resetFields();
  }

  const suppliersSearch = (value: string) => {
    setSuppliersSearchValue(value);
    dispatch(getSuppliersList({ page: 1, pageSize: 25, name: value || null}));
  };

  const handleSuppliersChange = (e: any): void => {
    if (!e) {
      setSupplierId(e);
      dispatch(getSuppliersList({ page: 1, pageSize: 25 }));
    }
  };

  const formStyle = {
    maxWidth: 'none',
    background: "#fff",
    borderRadius: token.borderRadiusLG,
    padding: 24,
    marginBottom: 30,
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "bottom"
  };

  return (
    <div className="relative z-10">
      <Form
        form={form}
        name="advanced_search"
        style={formStyle}
        layout="vertical"
      >
        <div className={`flex ${!isSupplier && !isDemo && !isCatalog ? "justify-between" : ""} flex-wrap`}>
          {!isCatalog && (
            <Form.Item
              style={{ width: '31%' }}
              className="mr-5 self-end"
              name="productName"
              label="Наименование товара"
            >
              <Input
                allowClear
                onChange={(e) => setProductName(e.target.value)}
                placeholder="Наименование товара"
              />
            </Form.Item>
          )}
          <Form.Item
            style={{ width: '31%' }}
            className="mr-5 self-end"
            name="productArticle"
            label="Артикул товара"
          >
            <Input
              allowClear
              onChange={(e) => setProductArticle(e.target.value)}
              placeholder="Артикул товара"
            />
          </Form.Item>
          {!isSupplier && !isDemo && (
            <Form.Item
              style={{width: "31%"}}
              className="mr-5 self-end"
              name="supplierId"
              label="Поставщик"
            >
              <Select
                value={suppliersSearchValue}
                onSearch={suppliersSearch}
                allowClear
                showSearch
                onChange={handleSuppliersChange}
                onSelect={(e: any) => setSupplierId(e)}
                filterOption={false}
                notFoundContent={null}
                autoClearSearchValue={false}
                defaultActiveFirstOption={false}
                placeholder="Поставщик"
              >
                {suppliers?.map(({id, name}) => {
                  return <Option key={id} value={id}>{name}</Option>;
                })}
              </Select>
            </Form.Item>
          )}
        </div>
        <div className="flex justify-between items-center mt-3">
          <Button
            className="mr-5"
            onClick={resetFields}
          >
            Очистить
          </Button>
        </div>
      </Form>
    </div>
  );
};
