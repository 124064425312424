import React, { FC, useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import Input from "@/components/ui-kit/input";
import Button from "@/components/ui-kit/button";
import { message, Upload, UploadFile, UploadProps } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { getSupplierInfo, updateSupplierInfo } from "@/actions/suppliers.actions";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "@/reducers";
import { roleResolver } from "@/utils/roleResolver";
import { RcFile } from "antd/es/upload";

import css from "./AboutCompany.modules.scss";

interface IAboutCompanyProps extends RouteComponentProps {}

const acceptedFileType: string[] = [".png", ".jpg", ".jpeg"];

export const AboutCompany: FC<IAboutCompanyProps> = ({}): JSX.Element => {
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state: AppStateType) => state.account);
  const { supplier } = useSelector((state: AppStateType) => state.suppliers);

  const { isSupplier, isAuditor } = roleResolver(userInfo?.roles);

  const [companyName, setCompanyName] = useState<string>("");
  const [inn, setInn] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [website, setWebsite] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [fileList, setFileList] = useState<UploadFile[]>(null);
  const [logoBase64, setLogoBase64] = useState<string>("");

  useEffect(() => {
    if ((userInfo?.supplier_id || userInfo?.contractor_id)) {
      dispatch(getSupplierInfo(+userInfo?.supplier_id || +userInfo?.contractor_id));
    }
  }, [userInfo]);

  useEffect(() => {
    setCompanyName(supplier?.name);
    setInn(supplier?.inn);
    setEmail(supplier?.email);
    setWebsite(supplier?.site);
    setPhone(supplier?.phone);
  }, [supplier]);

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }): void => {
    const ext: string = newFileList?.[0]?.name?.split(".")?.pop()?.toLowerCase();
    const fileType: string = acceptedFileType.find((fileExt) => fileExt.includes(ext));
    const accept: string = acceptedFileType.join(", ");
    const file: RcFile = newFileList?.[0]?.originFileObj;

    if (fileType || !file) {
      setFileList(newFileList);

      // преобразуем файл в base64
      if (file) {
        const reader: FileReader = new FileReader();

        reader.onload = () => {
          setLogoBase64(reader.result as string);
        };

        reader.onerror = (error) => {
          console.error('Error converting to Base64:', error);
        };

        reader.readAsDataURL(file);
      } else {
        setLogoBase64("");
      }
    } else {
      message.error(`Неверный формат файла. Допустимые форматы: ${accept}`, 300);
    }
  };

  const renderInput = (
    value: string,
    placeholder: string,
    onChangeHandler: React.Dispatch<React.SetStateAction<string>>,
    isDisabled: boolean = false,
    isError: boolean = false
  ): JSX.Element => {
    return (
      <Input
        value={value}
        placeholder={placeholder}
        onChange={onChangeHandler}
        disabled={isDisabled}
        isError={isError}
      />
    );
  };

  const renderUploadButton = (): JSX.Element => (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined rev={undefined} />
      <div style={{ marginTop: 8 }}>Загрузить логотип</div>
    </button>
  );

  const onSubmit = () => {
    dispatch(updateSupplierInfo(+userInfo?.supplier_id || +userInfo?.contractor_id,
      {
        email,
        site: website,
        phone,
        ...(fileList ? { logo: logoBase64 } : {})
      }
    ));
  };

  return (
    <div>
      <h2 className="text-x1 mb-6">Профиль компании</h2>
      <div className="flex">
        {supplier?.logo && (
          <div className={`mr-3 object-cover ${css.logoContainer}`}>
            <img
              className="object-cover inset-0 w-full h-full"
              src={`${supplier?.logo}.jpg`}
              alt="Логотип"
            />
          </div>
        )}
        <Upload
          listType="picture-card"
          fileList={fileList}
          maxCount={1}
          onChange={handleChange}
          accept={acceptedFileType.join(", ")}
          disabled={!isSupplier && !isAuditor}
        >
          {!!fileList?.length ? null : renderUploadButton()}
        </Upload>
      </div>
      <div className="grid grid-cols-2 gap-5 my-5">
        {renderInput(companyName, "Название компании", setCompanyName, true)}
        {renderInput(inn, "ИНН", setInn, true)}
        {renderInput(email, "Адрес электронной почты", setEmail)}
        {renderInput(website, "Сайт", setWebsite)}
        {renderInput(phone, "Телефон", setPhone, false)}
      </div>
      {(isSupplier || isAuditor) && (
        <Button theme="filled" onClick={onSubmit}>
          Сохранить
        </Button>
      )}
    </div>
  );
};
