import React, {
  FC,
  useState,
} from "react";
import * as ProductsAPI from "@/api/products.api";
import { useDispatch } from "react-redux";
import {
  ProductImageType,
  ProductCategoryType,
  ProductItemType,
} from "app/types";
import NoPictureIcon from "@/assets/icons/no-picture.inline.svg";
import useMobile from "@/hooks/useMobile";
import css from "./ProductCardProvider.modules.scss";
import { Tooltip, Button } from "antd";
import { formatAmount } from "@/utils/formatPrice";
import Checkbox from "@/components/ui-kit/checkox";
import {
  DeclineReasonModal
} from "@/components/pages/platform/pages/Catalog/CatalogVerificationPage/CatalogVerificationList/DeclineReasonModal/DeclineReasonModal";
import AdaptableBlock from "@/components/ui-kit/adaptableBlock";

interface IProductCardProviderProps {
  id: number;
  name: string;
  code?: string;
  price: number;
  color?: string;
  images: ProductImageType[];
  status: string;
  currency: string;
  article?: string;
  category?: ProductCategoryType;
  isChecked?: boolean;
  className?: string;
  aosDuration?: string;
  declineReason?: string;
  tpCategoryName?: string;
  resetCheckboxes?: () => void;
  fetchProductsList?: () => void;
  handleCheckboxClick?: (id: number) => void;
}

const ProductCardProvider: FC<IProductCardProviderProps> = ({
  id,
  name,
  price,
  images,
  status,
  article,
  currency,
  isChecked,
  aosDuration,
  declineReason,
  tpCategoryName,
  resetCheckboxes,
  fetchProductsList,
  handleCheckboxClick,
  className = "",
}) => {
  const { isMobile } = useMobile();

  const API_BASE: string = process.env.GATSBY_API_BASE?.trimEnd();

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [declineReasonText, setDeclineReasonText] = useState<string>("");

  const onDrawerClose = (): void => {
    setIsOpenDrawer(false);
    setDeclineReasonText("");
  };

  const renderStatusColor = (status: string): string => {
    switch (status) {
      case "placed":
        return "bg-asparagus"
      case "checking":
        return "bg-electric-blue"
      case "declined":
        return "bg-error"
      default:
        return "bg-dark-vivid-purple-crayola"
    }
  };

  const renderStatus = (status: string): string => {
    switch (status) {
      case "placed":
        return "Размещен";
      case "checking":
        return "На верификации";
      case "declined":
        return "Отклонено";
      default:
        return "На верификации";
    }
  };

  const updateProductStatus = (isVerified: boolean): void => {
    const currentProduct: ProductItemType = {
      id,
      isVerified,
      ...(!isVerified ? { declineReason: declineReasonText } : {})
    };

    ProductsAPI.updateProduct([currentProduct])
      .then(() => {
        !isVerified && setIsOpenDrawer(false);
        !isVerified && setDeclineReasonText("");
        resetCheckboxes();
        fetchProductsList();
      });
  };

  return (
    <div
      className={`bg-white flex justify-between ${css.productCardProvider} ${className}`}
      data-aos="fade-up"
      data-aos-duration={aosDuration}
      data-aos-once="true"
      data-aos-offset="0"
    >
      <div className="flex items-center">
        <div className="mt-3 tablet:mt-0 text-black">
          <Checkbox
            containerClassName="justify-center"
            checked={isChecked}
            onChange={() => handleCheckboxClick(id)}
            disabled={!id}
          />
        </div>
        <div className={css.status}>
          <div className={`${renderStatusColor(status)} ${css.strip}`}>
            &nbsp;
          </div>
          <div className={css.statusText}>
            {typeof status === "string" && renderStatus(status).toUpperCase()}
          </div>
        </div>
        <div className={css.blockContainer}>
          <div>
            {!!images?.length
              ? images?.map(({id, link}) => (
                <div key={id} className={css.icon}>
                  <img src={`${API_BASE}${link}`} />
                </div>
              )) : (
                <NoPictureIcon className={css.icon} />
              )}
          </div>
          <div className={css.blockContent}>
            <div className={css.description}>
              <div className={css.description__title}>
                <AdaptableBlock
                  itemName={name}
                  stringLength={45}
                  containerClassName=""
                />
                <div className={css.description__text}>
                  <span className="text-blue-grey mr-1">
                    Арт:
                  </span>
                    {article?.length > 25 ? (
                      <Tooltip title={article}>
                        {article?.substring(0, 25).trim()}
                        {article?.length > 25 && (
                          <span>...</span>
                        )}
                      </Tooltip>
                    ) : (
                      <span>{article}</span>
                    )}
                </div>
                <div className={css.description__text}>
                  {status === "declined" && (
                    <>
                    <span className="text-error">
                      Причина отклонения:
                    </span>
                      <span className="ml-1">
                      {declineReason?.length > 10 ? (
                        <Tooltip title={declineReason}>
                          {declineReason?.substring(0, 10).trim()}
                          {declineReason?.length > 10 && (
                            <span>...</span>
                          )}
                        </Tooltip>
                      ) : (
                        <span>{declineReason}</span>
                      )}
                    </span>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className={css.price}>
              {!isMobile && (
                <div className={css.price__title}>
                  Цена
                </div>
              )}
              <div className={css.price__text}>
                {formatAmount(price?.toString(), currency)}
              </div>
            </div>
            <div className={css.supplierCategory}>
              <div className={`${css.supplierCategory__title} ml-0`}>
                Категория поставщика
              </div>
              <div className={css.supplierCategory__text}>
                {tpCategoryName
                  ? `${tpCategoryName.substring(0, 25).trim()} ${tpCategoryName?.length > 25 && " ..." || ''}`
                  : "Не указана"
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`flex flex-col ${css.actionBtnsBlock}`}>
        {status !== "placed" && (
          <Button
            className="mb-2"
            type="primary"
            onClick={() => updateProductStatus(true)}
          >
            Подтвердить
          </Button>
        )}
        {status !== "declined" && (
          <Button onClick={() => setIsOpenDrawer(true)}>
            Отклонить
          </Button>
        )}
      </div>
      {isOpenDrawer && (
        <DeclineReasonModal
          handleCancel={onDrawerClose}
          declineReasonText={declineReasonText}
          handleApply={() => updateProductStatus(false)}
          setDeclineReasonText={setDeclineReasonText}
          isOpen={isOpenDrawer}
        />
      )}
    </div>
  );
};

export default ProductCardProvider;
