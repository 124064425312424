import React, { FC, useEffect, useMemo, useState } from "react";
import css from "./index.modules.scss";

import Checkbox from "@/components/ui-kit/checkox";
import { IDocument } from "app/types";
import TablePositionItem from "./TablePositionItem";
import { deleteOrderDocument } from "@/api/orders.api";

type TableDocuments = {
  positions: IDocument[];
  orderId: number;
  setPositions?: (positions: IDocument[]) => void;
  setSelectedDocuments?: (ids: number[]) => void;
};

const TableDocuments: FC<TableDocuments> = ({
  positions,
  orderId,
  setPositions,
  setSelectedDocuments,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [chosenDocuments, setChoosenDocuments] = useState<number[]>(positions?.map((doc: IDocument) => doc.id) || []);

  useEffect(() => {
    setIsChecked(positions?.every((pos: IDocument) => isDocumentCheckboxChecked(pos.id)));
  }, [positions, chosenDocuments])

  useEffect(() => {
    !!positions?.length && setChoosenDocuments([]);
  }, [positions])

  useEffect(() => {
    !!setSelectedDocuments && setSelectedDocuments(chosenDocuments);
  }, [chosenDocuments])

  const handleAllCheckboxClick = (): void => {
    if (chosenDocuments?.length !== positions?.length) {
      setChoosenDocuments(positions?.map((pos: IDocument) => pos.id));
    } else {
      setChoosenDocuments([]);
    }
  };

  const isDocumentCheckboxChecked = (id: number): boolean => chosenDocuments?.includes(id);

  const handleDocumentCheckboxClick = (id: number): void => {
    if (isDocumentCheckboxChecked(id)) {
      setChoosenDocuments(chosenDocuments?.filter((docId: number) => docId !== id));
    } else {
      setChoosenDocuments([...chosenDocuments, id]);
    }
  };

  const handleDeletePosition = (documentId: number): void => {
    deleteOrderDocument(orderId, documentId)
      .then(() => !!setPositions && setPositions(positions.filter((pos: IDocument) => pos.id !== documentId)))
      .catch(console.error);
  }

  return (
    <>
      {!!positions?.length && (
        <div className={`mt-3 bg-white border-none w-full p-2 ${css.tableWrapper}`}>
          <table className={`w-full ${css.table}`}>
            <colgroup className={css.tableColumns}>
              <col className={css.colCheckbox} />
              <col className={css.colType} />
              <col className={css.colName} />
              <col className={css.colDate} />
            </colgroup>

            <thead className={css.headerWrapper}>
              <th className={css.header}>
                <Checkbox
                  containerClassName="justify-center"
                  checked={isChecked}
                  onChange={handleAllCheckboxClick}
                />
              </th>
              <th className={`text-n2 text-blue-grey font-normal text-left ${css.header}`}>Тип документа</th>
              <th className={`text-n2 text-blue-grey font-normal text-left ${css.header}`}>Название</th>
              <th className={`text-n2 text-blue-grey font-normal text-left ${css.header}`}>Дата</th>
            </thead>

            <tbody className={css.bodyWrapper}>
              {positions.map((position: IDocument) => (
                <TablePositionItem
                  item={position}
                  isChecked={isDocumentCheckboxChecked(position.id)}
                  handleDeletePosition={handleDeletePosition}
                  handleCheckboxClick={handleDocumentCheckboxClick}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default TableDocuments;
