import React, { FC, useMemo } from "react";
import { ProductType } from "app/types";
import cn from "classnames/bind";
import Counter from "@/components/ui-kit/counter";
import Button from "@/components/ui-kit/button";
import { convertPrice } from "@/utils/convertPrice";
import PriceIndex from "@/assets/icons/price-index.inline.svg";
import css from "./headerBasket.modules.scss";
import { useSelector } from "react-redux";
import { AppStateType } from "@/reducers";

interface ProductHeadingBlockPropsType {
  className?: string;
  amount: number;
  basePrice: number;
  product_value?: ProductType[];
  itemCount: number;
  isCounterActive: boolean;
  hasModificationPrice?: boolean;
  setItemCount: (value: number) => void;
  setIsCounterActive: (value: boolean) => void;
  deleteBasket: (productId: number) => void;
  createBasket:(productId: number, quantity: number) => void;
  putBasket: (productId: number, quantity: number) => void;
  modificationRest?: number;
  modificationPrice?: number;
  productId?: number;
}

const cx = cn.bind(css);

const HeaderBasket: FC<ProductHeadingBlockPropsType> = ({
  basePrice,
  amount,
  product_value,
  hasModificationPrice = true,
  itemCount,
  isCounterActive,
  setItemCount,
  setIsCounterActive,
  deleteBasket,
  createBasket,
  putBasket,
  modificationPrice = 0,
  productId,
}) => {
  const activateCounter = () => {
    setIsCounterActive(true);
    createBasket(productId, itemCount);
  };

  const currentPrice = useMemo(
    () => convertPrice(itemCount, modificationPrice, basePrice),
    [modificationPrice, basePrice, itemCount]
  );

  return (
    <div className={cn(`bg-white w-full`)}>
      <div className={`${css.content}`}>
        <div className="flex justify-between	">
          <div className={css.desription}>
            <div className={`flex ${css.text}`}>
              <span className={css.price}>{currentPrice}</span>
              <PriceIndex className={cx("priceIndex", product_value)} />
            </div>
            <div className={`mt-1 ${css.remainderText}`}>
              Осталось {amount} шт.
            </div>
          </div>
          <div className={css.blockSize}>
            {isCounterActive ? (
              <Counter
                productId={productId}
                min={0}
                max={999999}
                className={cn(css.counter, {
                  [css.counterTop]: isCounterActive,
                })}
                value={itemCount}
                setValue={setItemCount}
                isCounterActive={isCounterActive}
                setIsCounterActive={setIsCounterActive}
                deleteBasket={deleteBasket}
                putBasket={putBasket}
              />
            ) : (
              <Button
                theme="filled"
                className={css.button}
                onClick={activateCounter}
                disabled={!hasModificationPrice}
              >
                Добавить в корзину
              </Button>
            )}
          </div>
        </div>
        <div className="flex justify-center">
          <span className={css.border}></span>
        </div>
      </div>
    </div>
  );
};

export default HeaderBasket;
