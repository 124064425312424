import React, { CSSProperties, FC } from "react";
import cn from "classnames";
import css from "./button.modules.scss";
import TickRightIcon from "@/assets/icons/tick-right.inline.svg";

type ButtonPropsType = {
  onClick?: (e: React.MouseEvent) => void;
  theme?:
    | "primary"
    | "primaryAccent"
    | "secondary"
    | "secondaryAccent"
    | "withoutBorder"
    | "filled"
    | "grey"
    | "greyOutline"
    | "black"
    | "white"
    | "formButton";
  className?: string;
  disabled?: boolean;
  type?: "button" | "reset" | "submit";
  style?: CSSProperties;
  isUppercase?: boolean;
};

const Button: FC<ButtonPropsType> = ({
  children,
  onClick,
  theme,
  className = "",
  disabled = false,
  type = "button",
  isUppercase = true,
  style,
}) => {
  const buttonTheme = theme || "primary";

  return (
    <button
      type={type}
      className={cn(
        `flex items-center justify-center text-n1 border-2 px-6 ${isUppercase ? "uppercase" : ""} ${css.container} ${className}`,
        {
          [css.primary]: buttonTheme === "primary",
          [css.primaryAccent]: buttonTheme === "primaryAccent",
          [css.secondary]: buttonTheme === "secondary",
          [css.secondaryAccent]: buttonTheme === "secondaryAccent",
          [css.withoutBorder]: buttonTheme === "withoutBorder",
          [css.filled]: buttonTheme === "filled",
          [css.grey]: buttonTheme === "grey",
          [css.greyOutline]: buttonTheme === "greyOutline",
          [css.black]: buttonTheme === "black",
          [css.white]: buttonTheme === "white",
          [css.formButton]: buttonTheme === "formButton",
        }
      )}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
      {buttonTheme === "formButton" && (
        <TickRightIcon className={`stroke-current`} />
      )}
    </button>
  );
};

export default Button;
