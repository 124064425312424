import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "@reach/router";
import { AppStateType } from "@/reducers";
import CatalogProductBlocks from "./CatalogProductBlocks/CatalogProductBlocks";
import css from "./CatalogProductCard.modules.scss";
import { getProductInfo } from "@/actions/products.actions";
import {Modal} from "antd";
import {navigate} from "gatsby";

interface ICatalogProductCardProps extends RouteComponentProps {
  links?: any;
  productId?: number;
  categoryId?: number | string;
}

const CatalogProductCard: FC<ICatalogProductCardProps> = ({ productId, categoryId, links }) => {
  const dispatch = useDispatch();

  const { product, getProductStatus } = useSelector((state: AppStateType) => state.products);

  useEffect(() => {
    dispatch(getProductInfo(productId));
  }, []);

  useEffect(() => {
    if (getProductStatus !== 200 && getProductStatus !== 0) {
      error();
    }
  }, [getProductStatus]);

  const error = () => {
    Modal.error({
      title: 'Ошибка соединения с сервером',
      content: 'Произошла непредвиденная ошибка. Пожалуйста, попробуйте позже',
      okText: "Вернуться в каталог",
      onOk() {navigate(`/platform/catalog/`);},
    });
  };

  return (
    <>
      {/*<div className="flex justify-between mb-2 items-end">*/}
      {/*  <Breadcrumbs links={links} className={css.breadcrumbs} />*/}
      {/*</div>*/}
      <h1 className={`text-x1 self-start tablet:self-center ${css.title}`}>
        {product && product?.name}
      </h1>
      <CatalogProductBlocks
        productItem={product}
        currentCategoryId={categoryId}
        productId={product?.id}
      />
    </>
  );
};

export default CatalogProductCard;
