import React, { FC } from "react";

import CloseIcon from "@/assets/icons/close-small.inline.svg";

import css from "./inputSearch.modules.scss";
import { navigate } from "gatsby";

type InputSearchPropsType = {
  value: string | number;
  maxLength?: number;
  className?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  clearValue: () => void;
};

const InputSearch: FC<InputSearchPropsType> = ({
  value,
  placeholder = "Введите запрос",
  onChange = () => {},
  className,
  clearValue,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className={`flex justify-center items-center`}>
      <div className={`flex justify-between w-full ${css.input} ${className}`}>
        <input
          type="text"
          onChange={handleChange}
          className="w-full"
          value={value}
          placeholder={placeholder}
        />
        {value && <button onClick={clearValue}>Очистить</button>}
      </div>
      <button className="ml-4">
        <CloseIcon className={css.removeIcon}
                   onClick={()=> {navigate("/platform/home/")}}
        />
      </button>
    </div>
  );
};

export default InputSearch;
