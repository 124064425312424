import React, { FC } from "react";
import cn from "classnames";
import css from "./textarea.modules.scss";
import shortid from "shortid";

type TextareaPropsType = {
  placeholder: string;
  textSize?: "small" | "large";
  className?: string;
  theme?: "white" | "grey";
  value?: string;
  onChange?: (value: string) => void;
  isError?: boolean;
  disabled?: boolean;
};

const Textarea: FC<TextareaPropsType> = ({
  placeholder,
  className,
  value,
  onChange,
  theme = "grey",
  textSize = "large",
  isError = false,
  disabled = false,
}) => {
  const id = shortid();

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };

  return (
    <div
      className={cn(`relative ${css.container} ${className}`, {
        [`bg-pale-grey-global-bg ${css.grey}`]: theme === "grey",
        [`bg-white ${css.white}`]: theme === "white",
        [css.error]: isError,
      })}
    >
      <textarea
        onChange={handleChange}
        className={cn(`w-full bg-transparent outline-none resize-none ${css.textarea}`, {
          ["text-t1"]: textSize === "large",
          ["text-n1"]: textSize === "small",
        })}
        placeholder={" "}
        id={`${id}-${name}`}
        value={value}
        disabled={disabled}
      />
      <label
        className={cn(`${css.label} select-none text-blue-grey`, {
          [`bg-pale-grey-global-bg`]: theme === "grey",
          [`bg-white`]: theme === "white",
          ["text-t1"]: textSize === "large",
          ["text-n1"]: textSize === "small",
          [`text-error`]: isError,
        })}
        htmlFor={`${id}-${name}`}
      >
        {placeholder}
      </label>
    </div>
  );
};

export default Textarea;
