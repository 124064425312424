import React, { FC, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "@reach/router";
import cn from "classnames";
import css from "./cart.modules.scss";

import useMobile from "@/hooks/useMobile";

import { AppStateType } from "@/reducers";

import Button from "@/components/ui-kit/button";
import BoxesIcon from "@/assets/icons/boxes.inline.svg";
import { getUserCartProducts, removeProduct, removeProductFromCartFailure } from "@/actions/cart.actions";
import CatalogPosition from "@/components/pages/platform/pages/cart/catalogPosition";
import { UserCartItemType } from "app/types";
import { groupUserCartItemsBySupplier } from "@/utils/groupUserCartItemsBySupplier";
import { navigate } from "gatsby";

interface ICartProps extends RouteComponentProps {}

const Cart: FC<ICartProps> = () => {
  const dispatch = useDispatch();

  const { isMobile } = useMobile();

  const { userCart, updateProductStatus, removeProductStatus, addProductStatus } = useSelector((state: AppStateType) => state.cart);

  useEffect(() => {
    dispatch(getUserCartProducts());
  }, [updateProductStatus, removeProductStatus, addProductStatus]);

  const deleteProduct = (productId: number) => {
    dispatch(removeProduct(productId));
    dispatch(removeProductFromCartFailure());
  }

  const isCartEmpty: boolean = useMemo(() => !userCart?.totalItems, [userCart]);
  const hasDeclineStatus: UserCartItemType[] = userCart?.items?.filter(position => position?.status !== "accepted");

  return (
    <div
      className={`mt-20 tablet:mt-15`}
      data-aos="fade-in"
      data-aos-once="true"
    >
      {!!userCart?.items?.length && (
        <>
          <h1
            className={cn(`text-x1`, {
              "tablet:mb-9": isCartEmpty,
              "mb-4": isCartEmpty,
              "mb-8": !isCartEmpty,
            })}
          >
            Корзина <span className={` text-blue-grey`}>({userCart?.totalItems})</span>
          </h1>
          <ul>
            {groupUserCartItemsBySupplier(userCart?.items)?.map((items: UserCartItemType[], index: number) => (
              <li key={index}>
                <CatalogPosition
                  id={items?.[0]?.supplier?.id}
                  seller={items?.[0]?.supplier?.name}
                  className="mb-1"
                  positions={items}
                  positionIndex={index}
                  supplierCompany={items?.[0]?.supplier}
                  deleteCartPosition={deleteProduct}
                />
              </li>
            ))}
          </ul>
          <div className="flex justify-center tablet:justify-end mt-4">
            <Button
              theme="filled"
              className={`self-end ${css.button}`}
              disabled={!!hasDeclineStatus?.length}
              onClick={() => {
                navigate(`${userCart?.items?.[0]?.supplier?.id}`);
              }}
            >
              создать запрос
            </Button>
          </div>
        </>
      )}
      {isCartEmpty && (
        <div
          className={`flex flex-col tablet:flex-row`}
          data-aos="fade-in"
          data-aos-once="true"
        >
          <p
            className={cn(`mb-10 tablet:mr-30 ${css.emptyCartText}`, {
              "text-x3": !isMobile,
              "text-s1": isMobile,
            })}
          >
            В корзине пока ничего нет. Тут будут собираться товары из каталога.
          </p>
          <BoxesIcon className={`hidden tablet:flex mt-15`} />
          <Button theme="primary" className="tablet:hidden mb-3">
            Перейти в каталог
          </Button>
        </div>
      )}
    </div>
  );
};

export default Cart;
