import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "@/reducers";
import { getOrdersList } from "@/actions/orders.actions";
import EmptyResult from "@/components/ui-kit/emptyResult";
import { RouteComponentProps } from "@reach/router";
import Orders from "@/components/pages/platform/pages/orders";
import { OrdersFilters } from "@/components/pages/platform/pages/index/blocks/OrderBlock/OrdersFilters/OrdersFilters";
import { Button } from "antd";
import { ControlTwoTone } from "@ant-design/icons";
import css from "../OrderBlock/OrdersPage.modules.scss";
import Preloader from "@/components/ui-kit/preloader";
import { getPortalSettingsData } from "@/actions/account.actions";
import SearchInput from "@/components/ui-kit/searchInput";
import { debounce } from "lodash";
import { IRequestOrderListFilters, OrderFacetType } from "app/types";

export const orderFacets = [
  {
    name: "Все",
    status: null,
    perms: ["Заказчик", "Аудитор", "Поставщик", "Согласовант", "Контролёр", "Демо"],
  },
  {
    name: "Новые",
    status: "new",
    perms: ["Заказчик", "Аудитор", "Поставщик", "Контролёр", "Демо"],
  },
  {
    name: "В обработке",
    status: "processing",
    perms: ["Заказчик", "Аудитор", "Поставщик", "Контролёр", "Демо"],
  },
  {
    name: "Согласование изменений",
    status: "updated",
    perms: ["Заказчик", "Аудитор", "Поставщик", "Контролёр", "Демо"],
  },
  {
    name: "Подготовка КП",
    status: "preparation",
    perms: ["Заказчик", "Аудитор", "Поставщик", "Контролёр", "Демо"],
  },
  {
    name: "На согласовании КП",
    status: "approval",
    perms: ["Заказчик", "Аудитор", "Поставщик", "Согласовант", "Контролёр", "Демо"],
  },
  {
    name: "Выполнен",
    status: "closed",
    perms: ["Заказчик", "Аудитор", "Поставщик", "Согласовант", "Контролёр", "Демо"],
  },
  {
    name: "Отменен",
    status: "cancelled",
    perms: ["Заказчик", "Аудитор", "Поставщик", "Согласовант", "Контролёр", "Демо"],
  },
];


interface IOrdersPageProps extends RouteComponentProps { }

const OrdersPage: FC<IOrdersPageProps> = () => {
  const dispatch = useDispatch();

  const { ordersData, isFetching } = useSelector((state: AppStateType) => state.orders);
  const { userInfo, portalSettingsData } = useSelector((state: AppStateType) => state.account);

  const [isOrdersFiltersHovered, setOrdersFiltersHovered] = useState<boolean>(false);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const filteredFacets: OrderFacetType[] = useMemo(() =>
    orderFacets.filter((facet: OrderFacetType) => userInfo?.roles.some((role: string) =>
      facet.perms.includes(role))), [userInfo]);

  const filteredStatuses: string = useMemo(() => (
    filteredFacets
      .map((facet: OrderFacetType) => facet.status ?? null)
      .filter((facet) => facet ?? false)
      .join(",")
  ), [filteredFacets]);

  const [filters, setFilters] = useState<IRequestOrderListFilters>(null);

  useEffect(() => {
    filteredStatuses && setFilters({
      page: 1,
      pageSize: 25,
      statuses: filteredStatuses || null,
      search: null,
    });
  }, [filteredStatuses]);

  useEffect(() => {
    filters && dispatch(getOrdersList(filters));
  }, [filters]);

  // TODO: выяснить, нужен ли этот запрос, если нет, то удалить
  // useEffect(() => {
  //   dispatch(getPortalSettingsData());
  // }, []);

  // В этом хуке обновляем заголовок сайта
  useEffect(() => {
    if (!!portalSettingsData) {
      document.title = portalSettingsData?.portalName;
    }
  }, [portalSettingsData]);

  const handleTabClick = useCallback((index: number, statusName?: string): void => {
    setActiveTabIndex(index);

    setFilters((prevState) => ({
      ...prevState,
      page: 1,
      statuses: statusName ?? filteredStatuses
    }))
  }, [filteredStatuses]);

  const setCurrentPage = (page: number): void => {
    setFilters((prevState) => ({
      ...prevState,
      page
    }))
  };

  const setSearchValue = (search: string): void => {
    setFilters((prevState) => ({
      ...prevState,
      search
    }))
  };

  const debouncedSearchValue = useMemo(() => {
    return debounce((value: string) => setSearchValue(value || null), 300);
  }, []);

  return (
    <div>
      <h2
        data-aos="fade-zoom-in"
        data-aos-once="true"
        className={`mb-6 hidden tablet:block text-x1`}
      >
        Заказы
      </h2>
      <div className="flex justify-end">
        <SearchInput changeValue={debouncedSearchValue} maxLength={200} />
      </div>
      <div className="mb-10 text-right">
        <Button
          className="relative"
          type="text"
          onClick={() => setOrdersFiltersHovered(!isOrdersFiltersHovered)}
          icon={<ControlTwoTone className={css.orderBtnFilerIcon} rev={undefined} />}
        />
      </div>
      {!isFetching ? (
        <>
          <div className={isOrdersFiltersHovered ? css.orderFilterHidden : css.orderFilterShown}>
            <OrdersFilters searchValue={filters?.search} setSearchValue={setSearchValue} />
          </div>
          <div className={isOrdersFiltersHovered ? css.orderListHidden : css.orderListShown}>
            <Orders
              facets={filteredFacets}
              ordersData={ordersData}
              currentPage={filters?.page}
              activeTabIndex={activeTabIndex}
              setCurrentPage={setCurrentPage}
              handleTabClick={handleTabClick}
              isOrdersFiltersHovered={isOrdersFiltersHovered}
            />
          </div>
          {!ordersData?.length && (
            <div className={isOrdersFiltersHovered ? css.orderListHidden : css.orderListShown}>
              <EmptyResult text="Здесь будут ваши заказы" />
            </div>
          )}
        </>
      ) : (
        <Preloader />
      )}
    </div>
  );
};

export default OrdersPage;
