import React, { FC } from "react";
import cn from "classnames";
import css from "./radioButton.modules.scss";

type RadioButtonPropsType = {
  className?: string;
  id: string;
  name?: string;
  value: string;
  checked: boolean;
  onChange: () => void;
  label?: string;
  sublabel?: string;
  notChosen?: boolean;
};

const RadioButton: FC<RadioButtonPropsType> = ({
  className,
  id,
  name,
  label,
  sublabel,
  value,
  checked,
  onChange,
  notChosen,
}) => {
  return (
    <label
      className={cn(`flex flex-col p-5 ${css.container}`, className, {
        [css.checked]: checked,
        [css.notChosen]: notChosen,
      })}
    >
      <input
        id={id}
        name={name}
        type="radio"
        value={value}
        checked={checked}
        onChange={onChange}
      />
      {label && <p className={`text-s1 mb-1`}>{label}</p>}
      {sublabel && <p className={`text-n2`}>{sublabel}</p>}
    </label>
  );
};

export default RadioButton;
