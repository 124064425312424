import humps from "humps";

import axios from "@/utils/axios";
// import { getSearchResultType } from "app/params";

type ParamsType = {
  page: number;
  page_size: number;
  [key: string]: string | number;
};

export const searchProductDetailed = async (
  page = 1,
  page_size = 10,
  search = null
) => {
  const params: ParamsType = {
    page,
    page_size,
  };

  if (search) {
    params.search = search;
  }

  const response = await axios.GET("v1/products_detailed/", params);

  return parserProductDetailed(response.data);
};

export const search = async (page = 1, page_size = 10, search = null) => {
  const params: ParamsType = {
    page,
    page_size,
  };

  if (search) {
    params.search = search;
  }

  const response = await searchProductDetailed(page, page_size, search);

  return response;
};

export const getSections = async () => {
  const response = await axios.GET("v1/search/sections/");

  return response.data;
};

export const getFAQ = async () => {
  const response = await axios.GET("v1/popular_requests/");

  return response.data;
};

export const getSearchResult = async (params: {
  path: string;
  page: number;
  page_size: number;
  search?: string;
}) => {
  const path = params.path;
  delete params.path;
  const response = await axios.GET(String(path), params);

  return humps.camelizeKeys(response.data);
};

const parserProductDetailed = (productsDetailed) => {
  return {
    ...productsDetailed,
    results: productsDetailed.results.map((item) => ({
      id: item.id,
      price: item.base_modification?.prices["0"]?.currencyPrice,
      img: !!item.images.length ? item.images["0"].image : null,
      // info: item.properties.map(p=> ({
      //   title: p.name,
      //   value: p.value
      // })),
      info: [],
      product: item.name,
      organization: item.supplier_company.name,
      amount: item.rests["0"].rest,
      category: item.category,
    })),
  };
};
