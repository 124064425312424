import {
  IMPORT_CATEGORIES_SUCCESS,
  IMPORT_CATEGORIES_FAILURE,
  GET_TP_CATEGORIES_SUCCESS,
  GET_TP_CATEGORIES_FAILURE,
  GET_TP_CURRENT_CATEGORY,
} from "@/constants/actionTypes";
import { ThunkAction } from "redux-thunk";
import { AppStateType } from "@/reducers";
import * as CategoriesAPI from "@/api/categories.api";
import { CategoriesTpType, UploadFileType } from "app/types";

export type CategoriesActionsTypes =
  | ImportCategoriesSuccessType
  | ImportCategoriesFailureType
  | GetTpCategoriesSuccessType
  | GetTpCategoriesFailureType
  | GetTpCurrentCategoryType;

type GetTpCategoriesSuccessType = {
  type: typeof GET_TP_CATEGORIES_SUCCESS;
  payload: {
    tpCategories: CategoriesTpType[];
  };
};

export const getTpCategoriesSuccess = (
  tpCategories: CategoriesTpType[]
): GetTpCategoriesSuccessType => ({
  type: GET_TP_CATEGORIES_SUCCESS,
  payload: {
    tpCategories: tpCategories,
  },
});

type GetTpCurrentCategoryType = {
  type: typeof GET_TP_CURRENT_CATEGORY;
  payload: {
    tpCurrentCategory: CategoriesTpType;
  };
};

export const getTpCurrentCategory = (
  tpCurrentCategory: CategoriesTpType
): GetTpCurrentCategoryType => ({
  type: GET_TP_CURRENT_CATEGORY,
  payload: {
    tpCurrentCategory: tpCurrentCategory,
  },
});

type GetTpCategoriesFailureType = {
  type: typeof GET_TP_CATEGORIES_FAILURE;
  payload: {
    error: string;
  };
};

export const getTpCategoriesFailure = (
  error?: string
  ): GetTpCategoriesFailureType => ({
  type: GET_TP_CATEGORIES_FAILURE,
  payload: {
    error,
  },
});

export const getTpCategoriesList = (maxLevel?: number, category_id?: number)
  :ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  CategoriesActionsTypes
> => async (dispatch) => {
  try {
    const response = await CategoriesAPI.getTpCategories(maxLevel, category_id);

    dispatch(getTpCategoriesSuccess(response.data.records));
  } catch (err) {
    dispatch(getTpCategoriesFailure(err.message));
  }
};

type ImportCategoriesSuccessType = {
  type: typeof IMPORT_CATEGORIES_SUCCESS;
  payload: {
    categoriesImportStatus: number;
  };
};

export const importCategoriesSuccess = (
  categoriesImportStatus: number
): ImportCategoriesSuccessType => ({
  type: IMPORT_CATEGORIES_SUCCESS,
  payload: {
    categoriesImportStatus: categoriesImportStatus,
  },
});

type ImportCategoriesFailureType = {
  type: typeof IMPORT_CATEGORIES_FAILURE;
  payload: {
    categoriesImportError: string;
  };
};

export const importCategoriesFailure = (categoriesImportError?: string): ImportCategoriesFailureType => ({
  type: IMPORT_CATEGORIES_FAILURE,
  payload: {
    categoriesImportError,
  },
});

export const importCategories = (file: File|UploadFileType)
  :ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  CategoriesActionsTypes
> => async (dispatch) => {
  try {
    const response = await CategoriesAPI.importCategoriesFile(file);

    dispatch(importCategoriesSuccess(response.status));
  } catch (err) {
    dispatch(importCategoriesFailure(err.response?.data?.detail));
  }
};
