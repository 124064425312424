import React, {
  FC,
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";

import SwiperCore, { SwiperOptions, Navigation } from "swiper";
import CarouselNuka from "nuka-carousel";
import { Swiper, SwiperSlide } from "swiper/react";
import cn from "classnames/bind";

import useMobile from "@/hooks/useMobile";

import NoPictureIcon from "@/assets/icons/no-picture.inline.svg";
import ArrowDown from "@/assets/icons/new-arrow-down.inline.svg";

import css from "./PictureSlider.modules.scss";
import {ProductImageType} from "app/types";

type PictureSliderPropsType = {
  images?: ProductImageType[];
};

const cx = cn.bind(css);
SwiperCore.use([Navigation]);

const PictureSlider: FC<PictureSliderPropsType> = ({ images }) => {
  const API_BASE: string = process.env.GATSBY_API_BASE?.trimEnd();
  
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [activeImg, setActiveImg] = useState(null);
  const [isRightArrowDisabled, setIsRightArrowDisabled] = useState<boolean>(
    false
  );
  const [isLeftArrowDisabled, setIsLeftArrowDisabled] = useState<boolean>(true);

  const { isMobile } = useMobile();

  useEffect(() => {
    if (images) {
      setActiveImg(images[0]);
    }
  }, [images]);

  const defaultControlsConfig = {
    prevButtonText: " ",
    nextButtonText: " ",
    nextButtonStyle: { display: "none" },
    prevButtonStyle: { display: "none" },
  };

  const prevButton = useRef<HTMLDivElement | null>(null);
  const nextButton = useRef<HTMLDivElement | null>(null);

  const settings: SwiperOptions = {
    direction: "vertical",
    grabCursor: true,
    slidesPerView: 5,
    spaceBetween: (window.innerWidth / 144) * 0.8,
    navigation: {
      nextEl: nextButton.current!,
      prevEl: prevButton.current!,
    },
  };

  const onActiveImage = useCallback((item, index) => {
    setActiveImg(item);
    setActiveIndex(index);
  }, []);

  const renderNoPicture = useMemo(
    () => (
      <div className="flex items-center	flex-col">
        <NoPictureIcon />
        <div className={`mt-4 ${css.text}`}>Нет изображения</div>
      </div>
    ),
    []
  );

  return isMobile ? (
    <div className={css.carouselWrapper}>
      <CarouselNuka
        defaultControlsConfig={defaultControlsConfig}
        slidesToShow={1}
        cellSpacing={4}
        wrapAround={false}
        frameOverflow="hidden"
      >
        {!!images.length
          ? images.map(({ id, link }) => (
            <div className={cx("carouselItem")} key={id}>
              <img src={`${API_BASE}${link}`} className={cx("carouselItemImg")} />
            </div>
          )) : renderNoPicture}
      </CarouselNuka>
    </div>
  ) : (
    <>
      <div className={cx("carouselWrapper")}>
        <div
          className={cx("carouselButton", "prev", {
            hide: isLeftArrowDisabled,
          })}
          ref={prevButton}
        >
          <ArrowDown />
        </div>
        <Swiper
          className={cx("caruselConteiner")}
          onReachBeginning={() => setIsLeftArrowDisabled(true)}
          onReachEnd={() => setIsRightArrowDisabled(true)}
          onFromEdge={(swiper) => {
            setIsLeftArrowDisabled(false);
            if (!swiper.isEnd) {
              setIsRightArrowDisabled(false);
            }
          }}
          {...settings}
        >
          {images.map((item, i) => (
            <SwiperSlide key={item.id} className={cx("sliderConteiner")}>
              <div
                onClick={() => onActiveImage(item, i)}
                className={cx("carouselItem", {
                  active: activeIndex === i,
                })}
              >
                <img
                  src={`${API_BASE}${item.link}`}
                  className={cx("carouselItemImg")}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className={cx("carouselButton", "next", {
            hide: isRightArrowDisabled,
          })}
          ref={nextButton}
        >
          <ArrowDown />
        </div>
      </div>
      <div className={cx("content")}>
        {!!activeImg ? (
          <img className="h-full" src={`${API_BASE}${activeImg.link}`}  />
        ) : (
          renderNoPicture
        )}
      </div>
    </>
  );
};

export default PictureSlider;
