import React, { FC } from "react";

import moment from "moment";

import useMobile from "@/hooks/useMobile";
import { DocumentType } from "app/types";

import css from "./documentFlow.modules.scss";

const DocumentFlow: FC<DocumentType> = ({
  contractNumber,
  docStatus,
  contractName,
  productNumber,
  productName,
  organizationName,
  createdAt,
  aosDuration,
  aosOffset = "0",
}) => {
  const { isMobile } = useMobile();

  return (
    <div
      className={`bg-white border-none ${css.documentFlow}`}
      data-aos="fade-up"
      data-aos-duration={aosDuration}
      data-aos-once="true"
      data-aos-offset={aosOffset}
    >
      <div className={`${css.text} ${css.contractNumber}`}>
        <div>№&nbsp;{contractNumber}</div>
        <div className={`${css.marginTopThree} ${css.textStatus}`}>
          {docStatus}
        </div>
      </div>

      <div className={css.contractName}>
        <div className={`${css.titleText} ${css.marginTopTwo}`}>
          {contractName.substr(0, 33).trim()}
          {contractName.length > 33 && " ..."}
        </div>

        <div className={`text-blue-grey ${css.text}`}>Кор. счёт-фактура</div>
      </div>
      {!isMobile && (
        <div className={`${css.text} ${css.productName}`}>
          <div>№&nbsp;{productNumber}</div>
          <div className={css.marginTopThree}>
            {productName.substr(0, 18).trim()}
            {productName.length > 18 && <>&nbsp;...</>}
          </div>
        </div>
      )}
      <div className={`${css.text} ${css.organizationName}`}>
        <div>{organizationName.toUpperCase()}</div>
        <div className={css.marginTopThree}>
          {moment(createdAt).format("DD.MM.YYYY")}
        </div>
      </div>
    </div>
  );
};

export default DocumentFlow;
