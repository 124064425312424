import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { getSuppliersList } from "@/actions/suppliers.actions";
import { AppStateType } from "@/reducers";
import { RouteComponentProps } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, PaginationProps } from "antd";
import EmptyResult from "@/components/ui-kit/emptyResult";
import Preloader from "@/components/ui-kit/preloader";
import CatalogBlock1 from "@/components/pages/platform/pages/suppliers/block1";
import Description from "@/components/suppliersCard/description";
import OptionalCol2 from "@/components/pages/platform/pages/suppliers/optionalCol2";
import SuppliersCard from "@/components/suppliersCard";
import { getImageFromBase64 } from "@/utils/getImageFromBase64";
import { roleResolver } from "@/utils/roleResolver";

import css from "./suppliers.modules.scss";
interface ISupplierListProps extends RouteComponentProps {}

export const Suppliers: FC<ISupplierListProps> = () => {
  const API_BASE: string = process.env.GATSBY_API_BASE?.trimEnd();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isFiltersActive, setIsFiltersActive] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>(null);

  const dispatch = useDispatch();
  const form = useRef<HTMLDivElement | null>(null);

  const changeCurrentPage = (n: number): void => setCurrentPage(n);
  const onSetSearchValue = (value: string): void => setSearchValue(value);

  const {
    suppliers,
    suppliersPages,
    isFetching,
  } = useSelector((state: AppStateType) => state.suppliers);
  const { userInfo } = useSelector((state: AppStateType) => state.account);

  const { isAuditor, isPurchaser } = roleResolver(userInfo?.roles);

  const canGetSuppliers: boolean = isPurchaser || isAuditor;

  useEffect(() => {
    if (canGetSuppliers) {
      dispatch(getSuppliersList({ page: currentPage, pageSize: 25 }));
    }
  },[]);

  const onSearch = useCallback(
    (value: string): void => {
      setSearchValue(value);
      dispatch(getSuppliersList({ page: currentPage, pageSize: 25, name: value }))
    },
    [currentPage]
  );

  const toggleFiltersActive = (): void => setIsFiltersActive((isPrev) => !isPrev);

  const paginationChange: PaginationProps['onChange'] = (pageNumber: number): void => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <CatalogBlock1
        isFiltersActive={isFiltersActive}
        eventOnClick={() => setIsFiltersActive((prev) => !prev)}
        onSearch={onSearch}
        form={form}
        toggleFiltersActive={toggleFiltersActive}
        closeFilter={() => setIsFiltersActive(false)}
        className="mt-5 tablet:mt-12"
        currentPage={currentPage}
        searchValue={searchValue}
        onSetSearchValue={onSetSearchValue}
        changeCurrentPage={changeCurrentPage}
      >
        <h1 className="text-x1 mb-7 tablet:mb-0">Поставщики</h1>
      </CatalogBlock1>

      {isFetching ? (
        <Preloader />
      ) : (
        !!suppliers.length && (
          <div className="mb-7">
            {suppliers?.map(({ id, logo, name, categories }) => (
              <SuppliersCard
                key={id}
                id={id}
                className="mb-1"
                icon={logo}
                organization={name}
                optionalCol2={<OptionalCol2 categories={categories} />}
              />
            ))}
          </div>
        )
      )}

      {!isFetching && suppliers?.length === 0 && (
        <EmptyResult text="Здесь будут все поставщики" />
      )}

      <div className="text-center">
        <Pagination
          total={suppliersPages * 10}
          onChange={paginationChange}
          defaultCurrent={currentPage}
          showSizeChanger={false}
          hideOnSinglePage
        />
      </div>
    </div>
  );
};
