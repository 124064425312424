import React, { FC } from "react";
import { useDispatch } from "react-redux";

import { tokenRevocation } from "@/actions/account.actions";

import Button from "@/components/ui-kit/button";

import css from "./sidebarProfile.modules.scss";

const LINKS = [
  {
    title: "Настройки",
    url: `/platform/settings`,
    // url: `/settings/company`,
    newTab: false
  },
];

type SidebarProfilePropsType = {
  closeMenu: () => void;
  handleProfile: (isOpen: boolean) => void;
};

const SidebarProfile: FC<SidebarProfilePropsType> = ({
  closeMenu,
}) => {
  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(tokenRevocation());
  };

  return (
    <div  className="relative">
      <div className="z-50">
        <ul className={`mt-6 mb-10`}>
          {LINKS.map(({ title, url , newTab}, i) => (
            <li key={i}>
              <a
                href={url}
                className={`border-b-0 pl-6 py-1 block border-none ${css.link}`}
                onClick={closeMenu}
                target={newTab ? "_blank" : ""}
              >
                {title}
              </a>
            </li>
          ))}
        </ul>
      </div>
      {/*<Button*/}
      {/*  data-aos="fade-right"*/}
      {/*  data-aos-once="true"*/}
      {/*  className={`ml-6 mt-auto ${css.button}`}*/}
      {/*  theme="primary"*/}
      {/*  onClick={onLogout}*/}
      {/*>*/}
      {/*  Выйти*/}
      {/*</Button>*/}
    </div>
  );
};

export default SidebarProfile;
