import React, { FC } from "react";
import { RouteComponentProps } from "@reach/router";
import { useSelector } from "react-redux";
import { AppStateType } from "@/reducers";
import Input from "@/components/ui-kit/input";

interface IUserProfileProps extends RouteComponentProps {}

const UserProfile: FC<IUserProfileProps> = ({}): JSX.Element => {
  const { userInfo } = useSelector((state: AppStateType) => state.account);

  const renderInput = (value: string, placeholder: string): JSX.Element => {
    return (
      <Input
        value={value ?? ""}
        disabled
        placeholder={placeholder}
      />
    );
  };

  return (
    <div className="grid grid-cols-2 gap-5">
      {renderInput(userInfo?.given_name, "Имя")}
      {renderInput(userInfo?.family_name, "Фамилия")}
      {renderInput(userInfo?.email, "Адрес электронной почты")}
      {renderInput("", "Телефон")}
    </div>
  );
};

export default UserProfile;
