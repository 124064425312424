import React, { FC } from "react";
import { useSelector } from "react-redux";
import { AppStateType } from "@/reducers";

import Button from "@/components/ui-kit/button";
import CatalogPositionType from "./catalogPositionType";

import ListIcon from "@/assets/icons/list.inline.svg";

import {
  SupplierType,
  UserCartItemType,
} from "app/types";

import css from "./catalogPosition.modules.scss";
import { Link } from "gatsby";
import { roleResolver } from "@/utils/roleResolver";


type CatalogPositionType = {
  id: number;
  admin?: boolean;
  seller: string;
  className: string;
  positions: UserCartItemType[];
  positionIndex?: number;
  supplierCompany:  SupplierType;
  deleteCartPosition: (productId: number) => void;
};

const CatalogPosition: FC<CatalogPositionType> = ({
  id,
  admin,
  seller,
  positions,
  className,
  deleteCartPosition,
}) => {
  const { userInfo } = useSelector((state: AppStateType) => state.account);

  const { isDemo, isSupplier } = roleResolver(userInfo?.roles);

  const isHiddenSupplierCard: boolean = isSupplier || isDemo;

  return (
    <div
      className={`flex justify-between flex-col bg-white border-none ${className} ${css.container}`}
    >
      <h3 className="text-s1 mb-3 flex">
        {
          !isHiddenSupplierCard ? (
            <Link
              to={`/platform/suppliers/${id}/`}
              className="border-none"
              target="_blank"
            >
              {seller}
            </Link>
          ) : (
            <div>{seller}</div>
          )
        }
      </h3>
      <div className={`flex items-center ${css.buttons}`}>
        <Button theme="withoutBorder">
          <a
            className="border-none flex items-center"
            href={`catalog`}
            target="_blank"
          >
            <ListIcon className={`mr-1 ${css.icon}`} />
            <p className={`text-n1`}>каталог</p>
          </a>
        </Button>
      </div>
      <CatalogPositionType
        positions={positions}
        admin={admin}
        deleteCartPosition={deleteCartPosition}
        seller={seller}
        id={id}
      />
    </div>
  );
};

export default CatalogPosition;
