import React, { FC } from "react";

import BottomButtonCatalog from "@/components/ui-kit/bottomButtonCatalog";
import Preloader from "@/components/ui-kit/preloader";

import css from "./searchItem.modules.scss";

type SearchItemProps = {
  title: string;
  count: number;
  isFetching: boolean;
  children: React.ReactNode;
  classNameIdent: string;
  maxCount: number;
  onClick: () => void;
};

const SearchItem: FC<SearchItemProps> = ({
  title = "",
  count,
  isFetching,
  children,
  classNameIdent,
  maxCount,
  onClick,
}) => (
  <div className={css.container}>
    <span className={css.title}>
      {title}
      <span className={css.titleCount}>({maxCount})</span>
    </span>
    <div className={classNameIdent}>
      {children}
      {isFetching ? (
        <Preloader />
      ) : (
        count < maxCount && (
          <div className={css.showMore} onClick={onClick}>
            <BottomButtonCatalog />
          </div>
        )
      )}
    </div>
  </div>
);

export default SearchItem;
