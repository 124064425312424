import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "@reach/router";

import Structure from "./structure";
import TabsBlock from "@/components/pages/platform/pages/CardOrder/tabsBlock";
import OrderDiscussion from "@/components/orderDiscussion";
import Preloader from "@/components/ui-kit/preloader";
import Description from "./description";
import TitleDescription from "./titleDescription";
import DocumentManagement from "../DocumentManagement";
import { Button, Modal, notification } from "antd";

import { AppStateType } from "@/reducers";
import {
  approveOrderStatusInfo,
  getOrderInfo,
  getOrderSuccess,
  updateOrder,
  updateOrderStatusFailure,
  updateOrderStatusInfo,
} from "@/actions/orders.actions";
import { IDocument, OrderItemsType, OrderStatusesType, OrderType } from "app/types";
import { currentOrderSelector } from "@/selectors/currentOrder.selector";

import css from "./cardOrder.modules.scss";
import { navigate } from "gatsby";
import { roleResolver } from "@/utils/roleResolver";

interface ICardOrderPropsType extends RouteComponentProps {
  orderId?: number;
}

enum ApprovalRole { CONCORDANT = 1, AUDITOR = 2 }

export enum ApprovalFactsResults { REQUIRED , APPROVED, REJECTED }

export type AdditionalProductType = {
  productId: number;
  count: number;
  itemPrice: number;
  status?: string;
  label?: string;
  images?: { id: number, link: string, isBase: boolean, image: string }[];
};

const statuses: OrderStatusesType[] = [
  {
    name: "Новый заказ",
    status: "new",
    isActive: true,
  },
  {
    name: "В обработке",
    status: "processing",
    isActive: false,
  },
  {
    name: "Согласование изменений",
    status: "updated",
    isActive: false,
  },
  {
    name: "Подготовка КП",
    status: "preparation",
    isActive: false,
  },
  {
    name: "На согласовании КП",
    status: "approval",
    isActive: false,
  },
  {
    name: "Выполнен",
    status: "closed",
    isActive: false,
  },
];

const canceledStatus: OrderStatusesType[] = [
  {
    name: "Отменен",
    status: "cancelled",
    isActive: true,
  },
];

const CardOrder: FC<ICardOrderPropsType> = ({ orderId }) => {
  const [currentOrder, setCurrentOrder] = useState<OrderType>(null);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [additionalProducts, setAdditionalProducts] = useState<AdditionalProductType[]>([]);
  const [activeStatuses, setActiveStatuses] = useState<OrderStatusesType[]>([]);
  const [notActiveStatuses, setNotActiveStatuses] = useState<OrderStatusesType[]>([]);
  const [uploadedDocuments, setUploadedDocuments] = useState<IDocument[]>([]);

  const dispatch = useDispatch();

  const {
    order,
    getOrderStatus,
    updatedOrderStatus,
  } = useSelector((state: AppStateType) => state.orders);

  const { userInfo } = useSelector((state: AppStateType) => state.account);

  const {
    isSupplier,
    isPurchaser,
    isAuditor,
    isConcordant,
    isDemo,
    isController
  } = roleResolver(userInfo?.roles);

  const {
    sum,
    prepay,
    discount,
    isProducts,
  } = useSelector(currentOrderSelector);

  useEffect(() => {
    if (orderId) dispatch(getOrderInfo(orderId));

    return () => dispatch(getOrderSuccess(null));
  }, [orderId, updatedOrderStatus]);

  useEffect(() => {
    setCurrentOrder(order);
  }, [order, updatedOrderStatus]);

  useEffect(() => {
    if (updatedOrderStatus) {
      updateDepartmentSuccessNotify();
      dispatch(updateOrderStatusFailure());
    }
  }, [updatedOrderStatus]);

  useEffect(() => {
    if (currentOrder) {
      setActiveStatuses(
        statuses?.slice(1, currentActiveStatusIndex + 1)?.map((status: OrderStatusesType) => {
          return {
            name: status?.name,
            status: status?.status,
            isActive: true,
          };
        }));

      setNotActiveStatuses(statuses?.slice(currentActiveStatusIndex + 1));
    }
  }, [
    currentOrder,
    updatedOrderStatus
  ]);

  useEffect(() => {
    if (getOrderStatus !== 200 && getOrderStatus !== 0) {
      error();
    }
  }, [getOrderStatus]);

  const currentActiveStatus: OrderStatusesType = statuses.filter((status: OrderStatusesType) => status?.status.includes(currentOrder?.status))[0];
  const currentActiveStatusIndex: number = statuses?.lastIndexOf(currentActiveStatus);

  const error = () => {
    Modal.error({
      title: 'Ошибка соединения с сервером',
      content: 'Произошла непредвиденная ошибка. Пожалуйста, попробуйте позже',
      okText: "Вернуться в заказы",
      onOk() { navigate(`/platform/orders/`); },
    });
  };

  const isHiddenSupplierCard: boolean = isSupplier || isDemo;

  const newOrderStatus: OrderStatusesType[] = [{
    name: "Новый заказ",
    status: "new",
    isActive: true,
  }];

  const currentStatuses: OrderStatusesType[] = currentOrder?.status === 'cancelled'
    ? canceledStatus
    : [...newOrderStatus, ...activeStatuses, ...notActiveStatuses];

  const isDisabledSubmitBtn: boolean = currentOrder?.items?.length === 0
    || additionalProducts?.some(({ productId }) => !productId);

  const updateDepartmentSuccessNotify = () => {
    notification["success"]({
      message: `Статус заказа: №${orderId} успешно обновлен.`,
    });
  };

  const editOrder = async (): Promise<void> => {
    const productItems: AdditionalProductType[] = currentOrder?.items?.map((item: OrderItemsType) => {
      return ({ productId: item.product.id, count: item.quantity, itemPrice: item.product.price })
    });
    const additionalItems: AdditionalProductType[] = additionalProducts
      ?.filter((p: AdditionalProductType) => !!p.productId && !!p.count && !!p.itemPrice)
      ?.map(({ productId, count, itemPrice }) => {
        return ({ productId, count, itemPrice })
      });

    await dispatch(updateOrder({
      id: orderId,
      deliveryDate: currentOrder.deliveryDate,
      items: [...productItems, ...additionalItems]
    })
    );

    setAdditionalProducts([]);
    setIsEditMode(false);
  };

  const cancelChanges = (): void => {
    setCurrentOrder(order);
    setAdditionalProducts([]);
    setIsEditMode(false);
  };

  const isShowEditOrderBtn: boolean = order?.status === "processing";

  // согласовать может поставщик в статусе "В обработке";
  // заказчик в статусе "Согласование изменений";
  // аудитор или согласовант в статусе "На согласовании КП"
  const isShowApproveBtn: boolean = (isSupplier && isShowEditOrderBtn)
    || isPurchaser && currentOrder?.status === "updated"
    || ((isAuditor || isConcordant) && currentOrder?.status === "approval");

  const openCancelModal = (): void => {
    Modal.confirm({
      cancelText: "Вернуться назад",
      okText: "Отменить заказ",
      title: "Вы уверены, что хотите отменить заказ?",
      onOk() {
        dispatch(approveOrderStatusInfo(orderId, false));
      },
    });
  };

  const updateStatus = (): void => {
    dispatch(updateOrderStatusInfo(currentOrder.id));
  };

  const renderButton = (
    title: string,
    action: () => void,
    isDisabled: boolean = false
  ): JSX.Element => {
    return (
      <Button
        type="primary"
        className="mr-2"
        onClick={action}
        disabled={isDisabled}
      >
        {title}
      </Button>
    );
  };

  const currentApprovalCount: number = currentOrder?.approvalFacts
    ?.filter(({result}) => result === ApprovalFactsResults.APPROVED)?.length;

  const cannotApproveOrder = (approvalRole: ApprovalRole.CONCORDANT | ApprovalRole.AUDITOR): boolean => currentOrder?.approvalFacts
    ?.find(({role}) => role === approvalRole)
    ?.result !== ApprovalFactsResults.REQUIRED;

  const isDisabledApproveBtn: boolean = (isAuditor && cannotApproveOrder(ApprovalRole.AUDITOR))
    || (isConcordant && cannotApproveOrder(ApprovalRole.CONCORDANT))
    || isEditMode;

  const isShowCancelBtn: boolean = order?.status !== "cancelled"
    && order?.status !== "closed"
    && !isController;

  return (
    <div className="mt-10">
      {currentOrder ? (
        <>
          <TitleDescription
            orderId={currentOrder?.id}
            name=""
            price={currentOrder?.value}
            currency={currentOrder?.currency}
            isHiddenSupplierCard={!isHiddenSupplierCard}
          />
          <TabsBlock
            count={!!currentOrder?.approvalFacts?.length && {
              current: currentApprovalCount ?? 0,
              total: currentOrder?.approvalFacts?.length ?? 0
            }}
            namesForShowCount={["На согласовании КП"]}
            statuses={currentStatuses}
            updateStatus={updateStatus}
            setActiveStatuses={setActiveStatuses}
            setNotActiveStatuses={setNotActiveStatuses}
            currentActiveStatusIndex={currentActiveStatusIndex}
          />
          <div className="mt-10 tablet:mt-15">
            <div className={css.orderDetailsText}>Детали заказа</div>
            <Description
              isShowWarning={isSupplier && order?.status === "preparation"}
              isEditMode={isEditMode}
              orderSum={currentOrder?.value}
              currency={currentOrder?.currency}
              setCurrentOrder={setCurrentOrder}
              supplierName={currentOrder?.supplier?.name}
              purchaserName={currentOrder?.contractor?.name}
              commercialOffer={currentOrder?.commercialOfferType}
              dateCreated={currentOrder?.createdAt}
              deliveryAddress={currentOrder?.deliveryAddress}
              payType={currentOrder?.paymentType}
              deliveryDate={currentOrder?.deliveryDate}
              deliveryType={currentOrder?.deliveryType}
              msg={currentOrder?.comment}
              prepay={prepay}
            />
            <Structure
              orderId={orderId}
              items={currentOrder?.items}
              setCurrentOrder={setCurrentOrder}
              currency={currentOrder?.currency}
              totalSum={sum}
              totalDiscount={discount}
              isProducts={isProducts}
              isEditMode={isEditMode}
              totalValue={currentOrder?.value}
              diffValue={currentOrder?.diffValue}
              valueByCategory={currentOrder?.valueByCategory}
              additionalProducts={additionalProducts}
              setAdditionalProducts={setAdditionalProducts}
            />
            <div className={`flex ${isSupplier && isShowEditOrderBtn ? "justify-between" : "justify-end"} mt-2`}>
              {isSupplier && isShowEditOrderBtn && (
                <>
                  {isEditMode ? (
                    <div className="flex">
                      {renderButton("Отмена", cancelChanges)}
                      {renderButton("Подтвердить", editOrder, isDisabledSubmitBtn)}
                    </div>
                  ) : (
                    renderButton("Редактировать заказ", () => setIsEditMode(true))
                  )}
                </>
              )}
              <div>
                {isShowApproveBtn
                  && renderButton(
                      "Согласовать",
                      () => dispatch(approveOrderStatusInfo(orderId, true)),
                      isDisabledApproveBtn
                )}
                {isSupplier
                  && currentOrder?.status === "preparation"
                  && renderButton("Загрузить КП", updateStatus, !uploadedDocuments?.length)
                }
                {isSupplier
                  && currentOrder?.status === "new"
                  && renderButton("Взять в обработку", updateStatus)
                }
                {isShowCancelBtn && (
                  <Button
                    danger
                    disabled={isEditMode}
                    onClick={openCancelModal}
                  >
                    Отменить заказ
                  </Button>
                )}
              </div>
            </div>
          </div>
          <DocumentManagement
            orderId={currentOrder?.id}
            setUploadedDocuments={(uploadedDocuments) => setUploadedDocuments(uploadedDocuments)}
          />
          <OrderDiscussion order={currentOrder} />
        </>
      ) : (
        <Preloader />
      )}
    </div>
  );
};

export default CardOrder;
