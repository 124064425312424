import React, { FC, useRef, useEffect, CSSProperties } from "react";

import cn from "classnames";
import shortid from "shortid";

import Tooltip from "@/components/ui-kit/tooltip";

import SearchSmallIcon from "@/assets/icons/search-small.inline.svg";
import CloseIcon from "@/assets/icons/close-small.inline.svg";

import css from "./input.modules.scss";

type InputPropsType = {
  placeholder?: string;
  name?: string;
  theme?: "white" | "grey" | "search" | "clearable";
  className?: string;
  inputStyle?: CSSProperties;
  value?: string;
  onChange?: (value: string) => void;
  isCode?: boolean;
  isError?: boolean;
  maxLength?: number;
  showTooltip?: boolean;
  tooltipText?: string;
  clearValue?: () => void;
  type?: string;
  isFocused?: boolean;
  setIsFocused?: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
};

const Input: FC<InputPropsType> = ({
  placeholder,
  name,
  theme = "white",
  className = "",
  inputStyle= {},
  value,
  onChange = () => {},
  isCode = false,
  isError = false,
  maxLength,
  showTooltip,
  tooltipText,
  clearValue = () => {},
  type = "text",
  isFocused,
  setIsFocused,
  disabled = false
}) => {
  const id = shortid();
  const ref = useRef();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    onChange(e.target.value);
  };

  useEffect(() => {
    isFocused && ref.current && ref.current;
  }, [isFocused]);

  return (
    <div
      className={cn(`relative ${css.container} ${className}`, {
        [`bg-white`]: theme === "white",
        [`bg-pale-grey-global-bg`]: theme === "grey",
        [css.error]: isError,
      })}
    >
      <input
        ref={ref}
        type={type}
        className={cn(`text-t1 flex bg-transparent ${css.input}`, {
          [css.code]: isCode,
          [css.search]: theme === "search",
          [css.clearable]: theme === "clearable",
          [css.noArrows]: type === "number",
        })}
        style={inputStyle}
        placeholder="&#183;&#183;&#183;&#183;"
        id={`${id}-${name}`}
        value={value}
        onChange={handleChange}
        maxLength={maxLength}
        onBlur={() => {
          setIsFocused && setIsFocused(false);
        }}
        disabled={disabled}
      />
      <label
        className={cn(
          `${css.label} text-t1 text-blue-grey select-none flex items-center`,
          {
            [`text-error`]: isError,
          }
        )}
        htmlFor={`${id}-${name}`}
      >
        {placeholder}
        {showTooltip && (
          <Tooltip tooltipText={tooltipText} className={`ml-1`} />
        )}
      </label>
      {!value && theme === "search" && (
        <SearchSmallIcon className={css.inputIcon} />
      )}
      {value && (theme === "search" || theme === "clearable") && (
        <button onClick={clearValue} className={css.removeButton}>
          <CloseIcon className={css.removeIcon} />
        </button>
      )}
    </div>
  );
};

export default Input;
