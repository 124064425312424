import { UserCartItemType } from "app/types";

// Делаем массив массивов, где каждый массив это позиции товаров одного поставщика
export const groupUserCartItemsBySupplier = (cartItems: UserCartItemType[]): UserCartItemType[][] => {
  const groupedItems = cartItems?.reduce((acc, item) => {
    const id: number = item?.supplier?.id;

    if (!acc[id]) {
      acc[id] = [];
    }

    acc[id]?.push(item);

    return acc;
  }, {});

  return groupedItems ? Object.values(groupedItems) : [];
};
