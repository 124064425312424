import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import cn from "classnames";

import useMobile from "@/hooks/useMobile";
import { ImportantBannersType } from "app/types";

import Carousel from "@/components/ui-kit/carousel";
import ImportantCard from "@/components/importantCard";
import Preloader from "@/components/ui-kit/preloader";

import css from "./block1.modules.scss";

const PlatformBlock1 = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState<number>(1);
  const [importantState, setImportantState] = useState<ImportantBannersType[]>([]);

  const { isMobile } = useMobile();

  useEffect(() => {
    setImportantState([]);
    setPage(1);
  }, []);

  // TODO: когда узнаем структуру данных для уведомлений, переделать на актуальные данные + сделать необходимые запросы
  const renderImportantCard: JSX.Element[] = useMemo(
    () =>
      importantState.map((item: ImportantBannersType, idx: number) => (
        <a
          key={idx}
          href={item?.buttonUrl}
          target={item?.openInNewWindow && '_blank'}
          className={cn("border-none border-0", {
            ["mr-1"]: importantState.length <= 3,
            ["w-full flex-1"]: isMobile || importantState.length > 3,
          })}
        >
          <ImportantCard
            data={item}
            containerClassName={`w-full ${css.card}`}
          />
        </a>
      )),
    [importantState, isMobile]
  );

  const getSlidesToShow = (): number => {
    if (isMobile || importantState.length < 3) {
      if (importantState.length < 2 || isMobile) {
        return 1;
      }

      return 2;
    }

    return 3;
  };

  return (
    <div className={`overflow-hidden px-6 tablet:px-8 -mx-6 tablet:-mx-8 ${css.container}`}>
      <h1 data-aos="fade-zoom-in" data-aos-once="true" className="text-x1">
        Важное
      </h1>
      {(importantState.length <= 3 && !isMobile) ||
      (importantState.length < 2 && isMobile) ? (
        <div
          data-aos="fade-up"
          data-aos-once="true"
          className="flex mt-4 tablet:mt-8 relative"
        >
          {renderImportantCard}
        </div>
      ) : (
        <div
          data-aos="fade-up"
          data-aos-once="true"
          className="mt-4 tablet:mt-8"
        >
          <Carousel
            slidesToShow={getSlidesToShow()}
            hiddenDots
            cellSpacing={4}
            showControls={isMobile || importantState.length <= 3}
            disableWrapAround
            frameOverflow="visible"
            setPage={setPage}
            page={page}
            count={importantState.length}
          >
            {renderImportantCard}
          </Carousel>
        </div>
      )}
      {!importantState.length && (
        <div className={css.indent}>
          <div className={`${css.indent} ${css.indent__text}`}>
            Тут будет собираться всё самое важное
          </div>
        </div>
      )}
    </div>
  );
};

export default PlatformBlock1;
