import React, { useEffect, useMemo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "@/reducers";

import ShowAllLink from "@/components/ui-kit/showAllLink";
import OrderCard from "@/components/orderCard";
import Preloader from "@/components/ui-kit/preloader";
import EmptyResult from "@/components/ui-kit/emptyResult";

import css from "./block2.modules.scss";
import TabsBlock from "@/components/pages/platform/pages/orders/tabsBlock";
import { orderFacets } from "@/components/pages/platform/pages/index/blocks/OrderBlock/OrdersPage";
import { getOrdersList } from "@/actions/orders.actions";
import { OrderType, OrderFacetType, IRequestOrderListFilters } from "app/types";

const PlatformBlock2 = () => {
  const dispatch = useDispatch();

  const { ordersData, isFetching } = useSelector((state: AppStateType) => state.orders);
  const { userInfo } = useSelector((state: AppStateType) => state.account);

  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const filteredFacets: OrderFacetType[] = useMemo(() =>
    orderFacets.filter((facet: OrderFacetType) => userInfo?.roles.some((role: string) =>
      facet.perms.includes(role))), [userInfo]);

  const filteredStatuses: string = useMemo(() => (
    filteredFacets
      .map((facet: OrderFacetType) => facet.status ?? null)
      .filter((facet) => facet ?? false)
      .join(",")
  ), [filteredFacets]);

  const [filters, setFilters] = useState<IRequestOrderListFilters>(null);

  useEffect(() => {
    filteredStatuses && setFilters({
      page: 1,
      pageSize: 10,
      statuses: filteredStatuses || null,
      search: null,
    });
  }, [filteredStatuses]);

  useEffect(() => {
    filters && dispatch(getOrdersList(filters));
  }, [filters]);

  const handleTabClick = useCallback((index: number, statusName?: string): void => {
    setActiveTabIndex(index);

    setFilters((prevState) => ({
      ...prevState,
      page: 1,
      statuses: statusName ?? filteredStatuses
    }))
  }, [filteredStatuses]);

  const renderOrdersCard: JSX.Element[] = useMemo(
    () =>
      ordersData.map((order: OrderType, i) => (
        <OrderCard key={order.id} item={order} aosDuration={`${i + 5}00`} />
      )),
    [ordersData]
  );

  return (
    <div className="mt-10 tablet:mt-15">
      <h2
        data-aos="fade-zoom-in"
        data-aos-once="true"
        className="mb-6 hidden tablet:block text-x1"
      >
        Заказы
      </h2>
      <div className={`flex items-start tablet:justify-between ${css.tabBlock}`}>
        <TabsBlock
          facets={filteredFacets}
          handleTabClick={handleTabClick}
          activeIndex={activeTabIndex}
        />
        <ShowAllLink
          to="/platform/orders"
          className="hidden mt-1 ml-10 tablet:flex"
          tickClassName="text-electric-blue"
        >
          Смотреть{"\xa0"}все
        </ShowAllLink>
      </div>
      {!!ordersData.length ? (
        <>
          <div
            className={`flex justify-between mb-4 overflow-auto px-6 -mx-6 ${css.wrapper}`}
            data-aos="fade-zoom-in"
            data-aos-once="true"
          >
            {isFetching ? <Preloader /> : <div className="mt-2 w-full">{renderOrdersCard}</div>}
          </div>
        </>
      ) : (
        <EmptyResult text="Здесь будут ваши заказы" />
      )}
    </div>
  );
};

export default PlatformBlock2;
