import { useMedia } from "react-use";
import { theme } from "@/../tailwind.config";

const useMobile = () => {
  const {
    screens: { tablet },
  } = theme;
  const isMobile = !useMedia(`(min-width: ${tablet})`);
  return { isMobile };
};

export default useMobile;
