import React, {
  FC,
  LegacyRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useSelector, useDispatch } from "react-redux";

import { motion, AnimatePresence } from "framer-motion";
import { Controller, useForm } from "react-hook-form";
import cn from "classnames/bind";

import { AppStateType } from "@/reducers";
import { CategoriesTpType } from "app/types";
import { getTpCategoriesList } from "@/actions/categories.actions";
import { getSuppliersList } from "@/actions/suppliers.actions";
import { cancelRequest } from "@/api/cancel.api";
import { debounce } from "lodash";
import { roleResolver } from "@/utils/roleResolver";
import useMobile from "@/hooks/useMobile";
import Button from "@/components/ui-kit/button";
import { TreeSelect } from "antd";
import SearchIcon from "@/assets/icons/search-middle.inline.svg";
import FiltersIcon from "@/assets/icons/filters.inline.svg";

import css from "./block1.modules.scss";

const cx = cn.bind(css);

type ParamsType = {
  page: number;
  pageSize: number;
  [key: string]: boolean | number | string;
  inn?: string;
  name?: string;
  search?: string;
  category?: number;
};

type FiltersType = {
  supplierCategory: number;
};

type CatalogBlock1PropsType = {
  isFiltersActive: boolean;
  eventOnClick: () => void;
  form: LegacyRef<HTMLDivElement>;
  closeFilter: () => void;
  onSearch: (value: string) => void;
  currentPage: number;
  searchValue: string;
  toggleFiltersActive: () => void;
  onSetSearchValue: (value: string) => void;
  changeCurrentPage: (n: number) => void;
  className?: string;
};

const CatalogBlock1: FC<CatalogBlock1PropsType> = ({
  isFiltersActive,
  eventOnClick,
  form,
  closeFilter,
  children,
  className,
  currentPage,
  searchValue,
  toggleFiltersActive = () => {},
  onSearch = () => {},
  onSetSearchValue,
  changeCurrentPage,
}) => {
  const dispatch = useDispatch();
  const [isFetchingSupplier, setIsFetchingSupplier] = useState<boolean>(false);
  const [isFetchingMobile, setIsFetchingMobile] = useState<boolean>(false);
  const [searchCategoryValue, setSearchCategoryValue] = useState<string>("");

  const { isMobile } = useMobile();

  const { userInfo } = useSelector((state: AppStateType) => state.account);
  const { tpCategories } = useSelector((state: AppStateType) => state.categories);

  const { isPurchaser } = roleResolver(userInfo?.roles);

  const { control, watch, setValue } = useForm<FiltersType>({
    defaultValues: {
      supplierCategory: null,
    },
    mode: "onChange",
    shouldUnregister: false,
  });
  const { supplierCategory } = watch();

  const formatCategoriesList = (categories: CategoriesTpType[]) => categories?.map((category: CategoriesTpType) => (
    {
      id: category.id,
      value: category.id,
      label: category.name,
      parent: category.parent_id,
      isLeaf: category.level,
      children: formatCategoriesList(category.children),
    }
  ));

  const debouncedSearchValue = useMemo(() => {
    return debounce((value: string) => {
      onSearch(value);
    }, 400);
  }, []);

  useEffect(() => {
    dispatch(getTpCategoriesList(10));

    return cancelRequest;
  }, [searchCategoryValue]);

  useEffect(() => {
    if (searchValue !== null) debouncedSearchValue(searchValue.trim());

    return cancelRequest;
  }, [searchValue]);

  useEffect(() => {
    if (isFetchingSupplier) {
      setIsFetchingSupplier(false);

      fetchSuppliersList();
    }
  }, [
    isFetchingSupplier,
    supplierCategory,
    searchValue,
    currentPage
  ]);

  useEffect(() => {
    if (isMobile && isFetchingMobile) {
      fetchSuppliersList();
    }

    isFetchingMobile && setIsFetchingMobile(false);
  }, [
    isFetchingMobile,
    isMobile,
    isPurchaser,
    supplierCategory,
    searchValue,
  ]);

  const onSubmit = (): void => {
    changeCurrentPage(1);

    fetchSuppliersList();
  };

  const fetchSuppliersList = (): void => {
    const params: ParamsType = {
      page: 1,
      pageSize: 25,
    };

    if (searchValue) params.name = searchValue;
    if (supplierCategory) params.category = supplierCategory;

    dispatch(getSuppliersList(params))
  };

  const resetUseForm = useCallback((): void => {
    const defaultValue = null;

    setValue("supplierCategory", defaultValue, { shouldDirty: true });

    changeCurrentPage(1);

    !isMobile && setIsFetchingSupplier(true);
    isMobile && setIsFetchingMobile(true);
  }, []);

  const isDisableButton: boolean = !supplierCategory;

  return (
    <>
      <div
        data-aos="fade-zoom-in"
        data-aos-once="true"
        className={cx(
          "flex flex-col tablet:flex-row justify-between",
          {
            titleBlock: !className,
          },
          className
        )}
      >
        {children}
        <div className={`relative flex ${css.searchWrapper}`}>
          <input
            className={`text-s1 ${css.searchInput}`}
            type="text"
            placeholder="Найти в разделе"
            onChange={(e) => onSetSearchValue(e.target.value)}
          />
          <SearchIcon className={css.serchIcon} />
        </div>
      </div>
      <div
        data-aos="fade-zoom-in"
        data-aos-once="true"
        className={`mt-3 mb-5 tablet:mb-3 select-none ${css.blockFilterIcon}`}
      >
        <FiltersIcon
          className={cn(`cursor-pointer ml-auto ${css.filtersIcon}`, {
            [css.activeFilterIcon]: isFiltersActive,
          })}
          onClick={toggleFiltersActive}
        />
      </div>
      <form
        className={cn(css.formWrapeer, {
          [`overflow-hidden ${css.formWrapeerTransparent}`]: !isFiltersActive,
        })}
      >
        <AnimatePresence>
          {isFiltersActive && (
            <motion.div
              initial={{ height: 0 }}
              animate={{
                height: "100%",
                transition: { duration: 0.3 },
              }}
              exit={{
                height: 0,
                transition: { duration: 0.3 },
              }}
            >
              <div className="pb-15" ref={form}>
                <div className={`${css.formLine}`}>
                  <div className={css.flexItem33}>
                    <Controller
                      control={control}
                      name="supplierCategory"
                      rules={{ required: true }}
                      render={({ onChange, value }) => (
                        <TreeSelect
                          className={`${css.select} w-full`}
                          value={value || null}
                          placeholder="Выберите категорию"
                          notFoundContent="Категория не найдена"
                          treeData={formatCategoriesList(tpCategories)}
                          onChange={onChange}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="flex mt-8">
                  <Button
                    theme="withoutBorder"
                    className="mr-5"
                    onClick={onSubmit}
                    disabled={isDisableButton}
                  >
                    применить
                  </Button>
                  <Button
                    theme="withoutBorder"
                    className="mr-10"
                    onClick={() => resetUseForm()}
                    disabled={isDisableButton}
                  >
                    очистить
                  </Button>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </form>
    </>
  );
};

export default CatalogBlock1;
