import React, { FC } from "react";

import WhiteCircleIcon from "@/assets/icons/white-circle.inline.svg";

import css from "./common.modules.scss";
import {Link, navigate as reach_navigate} from "@reach/router";

type CommonPropsType = {
  id: number;
  message: string;
  redirectUrl: string;
  createdAt: string;
  isRead: boolean;
  onReadNotification: (id: number, url: string, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  getCurrentDate: (defaultValue: string) => string;
};

const Common: FC<CommonPropsType> = ({
  id,
  message,
  redirectUrl,
  createdAt,
  isRead,
  onReadNotification,
  getCurrentDate,
}) => (
  <div className="pl-0 pr-0 pt-0 pb-0 tablet:pl-3 tablet:pr-3 tablet:pt-2 tablet:pb-2">
    <a
      className="flex pl-6 border-none cursor-pointer tablet:pl-0"
      href={redirectUrl}
      onClick={(e) => {
        !isRead && onReadNotification(id, redirectUrl, e);
      }}
    >
      <div>
        <div className={css.textTitle}>
          {message}&nbsp;
        </div>
        {!isRead && (
          <div className={css.circleIcon}>
            <WhiteCircleIcon className={css.iconColor} />
          </div>
        )}
      </div>
    </a>
    <div className={`mt-2 pl-6 tablet:pl-0 ${css.textDate}`}>
      {getCurrentDate(createdAt)}
    </div>
  </div>
);

export default Common;
