import React, { FC, useMemo } from "react";
import { OrderType } from "app/types";
import { formatAmount } from "@/utils/formatPrice";
import { statusNameOrder } from "@/utils/statusNames";
import { colorNameOrder, STATUS_COLOR } from "@/utils/statusColor";
import AdaptableBlock from "@/components/ui-kit/adaptableBlock";
import cn from "classnames";
import css from "./orderCard.modules.scss";
import dayjs from "dayjs";

type OrderCardPropsType = {
  item: OrderType;
  containerClassName?: string;
  aosDuration?: string;
};

const OrderCard: FC<OrderCardPropsType> = ({
  item,
  containerClassName,
  aosDuration,
}) => {
  const {
    id,
    value,
    status,
    currency,
    supplier,
    contractor,
    createdAt,
    itemName,
    deliveryDate,
  } = item;

  const actualColorNameRequest = useMemo(() => colorNameOrder(status), [
    status,
  ]);

  const actualStatusName = useMemo(() => statusNameOrder(status), [
    status,
  ]);

  const cost: string = formatAmount(value?.toString(), currency);

  const renderDate = (title: string, date: string): JSX.Element => {
    return (
      <div className="mb-3 hidden tablet:block">
        <p className="text-n2 text-blue-grey mb-1">{title}</p>
        <p className="text-s1 font-medium mb-1">{dayjs(date).format("DD.MM.YYYY")}</p>
      </div>
    );
  };

  return (
    <a
      href={`/platform/order/${id}`}
      className={`flex justify-between flex-col tablet:flex-row bg-white pt-4 pb-5 tablet:py-8 border-none ${css.item} ${containerClassName}`}
      data-aos="fade-up"
      data-aos-duration={aosDuration}
      data-aos-once="true"
      data-aos-offset="0"
      target="_blank"
      rel="noopener"
    >
      <div
        className={cn(`absolute top-0 left-0 bottom-0 bg-electric-blue ${css.line}`, {
          "bg-electric-blue": actualColorNameRequest === STATUS_COLOR.BLUE,
          "bg-cyan": actualColorNameRequest === STATUS_COLOR.CYAN,
          "bg-light-blue": actualColorNameRequest === STATUS_COLOR.LIGHT_BLUE,
          "bg-coral": actualColorNameRequest === STATUS_COLOR.CORAL,
          "bg-olive-yellow": actualColorNameRequest === STATUS_COLOR.OLIVE_YELLOW,
          "bg-green": actualColorNameRequest === STATUS_COLOR.GREEN,
          "bg-error": actualColorNameRequest === STATUS_COLOR.ERROR,
        })}
      ></div>
      <div className="tablet:pr-4 tablet:w-1/7 mb-3 tablet:mb-0 flex justify-between tablet:block">
        <div className="w-1/2 mr-3 tablet:w-full tablet:mr-0 mb-0 tablet:mb-1">
          <p className="text-n2 mb-0 tablet:mb-1">№ {id}</p>
          <p className={`uppercase text-n1 font-medium ${css.status}`}>{actualStatusName}</p>
        </div>
      </div>
      <div className="tablet:w-2/6 tablet:mr-8 mb-auto">
        <AdaptableBlock
          title="Наименование товара"
          itemName={itemName}
          titleClassName="text-n2 text-blue-grey mb-1"
        />
      </div>
      <div className="w-1/2 flex justify-between">
        <div className="hidden tablet:flex flex-col justify-between mr-8 w-2/3">
          {/*TODO: пока скрываем от всех стоимость*/}
          {/*{!!value && (*/}
          {/*  <div className="mb-2">*/}
          {/*    <p className="text-n2 text-blue-grey mb-1">Стоимость</p>*/}
          {/*    <p className="text-s1 font-medium">{cost}</p>*/}
          {/*  </div>*/}
          {/*)}*/}
          <div className="mb-1">
            <AdaptableBlock
              title="Поставщик"
              itemName={supplier?.name ?? ""}
              className="text-s1 font-medium"
              titleClassName="text-n2 text-blue-grey mb-1"
            />
            <AdaptableBlock
              title="Заказчик"
              itemName={contractor?.name ?? ""}
              className="text-s1 font-medium"
              titleClassName="text-n2 text-blue-grey mb-1 mt-2"
            />
          </div>
        </div>
        <div className="w-1/3 pr-4 pl-6">
          {renderDate("Дата создания", createdAt)}
          {renderDate("Дата доставки", deliveryDate)}
        </div>
      </div>

      <div className="flex tablet:hidden justify-between">
        {!!value && (
          <div className="w-1/2 mr-3">
            <p className="text-n2 text-blue-grey mb-1">Стоимость</p>
            <p className="text-s1 font-medium">{value} руб</p>
          </div>
        )}
        <div className="w-1/2">
          <p className="text-n2 text-blue-grey mb-1">Дата создания</p>
          <p className="text-s1 font-medium mb-1">{dayjs(createdAt).format("DD.MM.YYYY")}</p>
        </div>
        <div className="w-1/2">
          <p className="text-n2 text-blue-grey mb-1">Дата доставки</p>
          <p className="text-s1 font-medium mb-1">{dayjs(deliveryDate).format("DD.MM.YYYY")}</p>
        </div>
      </div>
    </a>
  );
};

export default OrderCard;
