import React, { FC, useEffect, useMemo } from "react";
import Button from "@/components/ui-kit/button";
import Counter from "@/components/ui-kit/counter";
import { ProductType, UserCartItemType } from "app/types";
import { convertPrice } from "@/utils/convertPrice";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "@/reducers";

import cn from "classnames/bind";
import css from "./cart.modules.scss";
import { getUserCartProducts } from "@/actions/cart.actions";
import { formatAmount } from "@/utils/formatPrice";
import { roleResolver } from "@/utils/roleResolver";

interface CartProps {
  price?: any;
  currency?: string;
  isList?: boolean;
  className?: string;
  amount?: number;
  basePrice?: ProductType["price"];
  modificationRest?: number;
  modificationPrice?: number;
  itemCount?: number;
  isCounterActive?: boolean;
  hasModificationPrice?: boolean;
  setItemCount?: (value: number) => void;
  setIsCounterActive?: (value: boolean) => void;
  deleteBasket?: (productId: number) => void;
  createBasket?: (productId: number, quantity: number) => void;
  putBasket?: (productId: number, quantity: number) => void;
  hasModifications?: boolean;
  countInUsercart?: number;
  productId?: number;
}

const cx = cn.bind(css);

const Cart: FC<CartProps> = ({
  basePrice,
  currency,
  hasModificationPrice = true,
  itemCount,
  isCounterActive,
  setItemCount,
  setIsCounterActive,
  deleteBasket,
  putBasket,
  createBasket,
  productId,
}) => {
  const dispatch = useDispatch();

  const { product } = useSelector((state: AppStateType) => state.products);
  const { userCart } = useSelector((state: AppStateType) => state.cart);
  const { userInfo } = useSelector((state: AppStateType) => state.account);

  const { isSupplier, isAuditor, isConcordant, isController } = roleResolver(userInfo?.roles);

  const productInCart: UserCartItemType = userCart?.items?.find(item => item?.product?.id === productId);
  const currentProductId: number = userCart?.items?.filter(item => item?.product?.name === product?.name)[0]?.id;
  const canPutBasket: boolean = !isSupplier && !isAuditor && !isConcordant && !isController;

  useEffect(() => {
    setIsCounterActive(!!productInCart)
  }, [productInCart]);

  useEffect(() => {
    if (!!productInCart?.quantity && !isCounterActive) {
      setItemCount(productInCart?.quantity)
    }
  }, [productInCart?.quantity]);

  useEffect(() => {
    dispatch(getUserCartProducts());
  }, []);

  const activateCounter = () => {
    setIsCounterActive(true);
    createBasket(product?.id, itemCount);
  };

  return (
    <div className='flex flex-col'>
      <div className={css.priceBlock}>
        <div className={cx("flex ", "totalCount")}>
          <span className={css.price}>{formatAmount(basePrice, currency)}</span>
          {/* Скрыть кнопки пока не будет реализован функционал */}
          {/* <Tooltip
            text={<PriceIndex className={cx("priceIndex", product_value)} />}
            tooltipText={"Индекс цены"}
            isIconShow={false}
            type="top-center"
          />
          <span className={css.priceSeparator}></span>
          <Tooltip
            className={css.compare}
            text={<Compare />}
            tooltipText={"Сравнить цены"}
            isIconShow={false}
            type="top-center"
          /> */}
        </div>
      </div>
      {canPutBasket && (
        <>
          {isCounterActive && productInCart ? (
            <Counter
              productId={currentProductId}
              min={0}
              max={999999}
              className={css.counter}
              value={+itemCount}
              setValue={setItemCount}
              isCounterActive={isCounterActive}
              setIsCounterActive={setIsCounterActive}
              deleteBasket={deleteBasket}
              putBasket={putBasket}
            />
          ) : (
            <Button
              theme="filled"
              disabled={!hasModificationPrice}
              className={css.button}
              onClick={activateCounter}
            >
              в корзину
            </Button>
          )}
        </>
      )}

      <a href={`mailto:${product.supplier?.email}?subject=Вопрос по каталогу товаров`} className="mt-3 border-none">
        <Button
          theme="primary"
          className={css.button}
        >
          <p className="text-n1">Связаться с поставщиком</p>
        </Button>
      </a>
    </div>
  );
};

export default Cart;
