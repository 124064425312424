import React, { FC, useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RouteComponentProps } from "@reach/router";
import {
  update,
  getSearchSections,
  getSearchResult,
  getSearchFAQ,
} from "@/actions/search.actions";
import { searchSelector } from "@/selectors/search.selector";

import Client from "./client";
import Guest from "./guest";
import FAQ from "./faq";
import InputSearch from "@/components/ui-kit/inputSearch";
import Preloader from "@/components/ui-kit/preloader";

import css from "./search.modules.scss";

interface ISearch extends RouteComponentProps {}

const Search: FC<ISearch> = () => {
  const dispatch = useDispatch();

  const {
    isClient,
    results,
    searchValue,
    sections,
    isFetching,
    products,
    isFAQFetching,
    requests,
  } = useSelector(searchSelector);

  const [value, setValue] = useState<string>(searchValue);
  const [isFAQVisible, setIsFAQVisible] = useState<boolean>(true);

  const onChange = useCallback((value: string) => {
    setValue(value);
    dispatch(update<string>("searchValue", value));
  }, []);

  const clearValue = useCallback(() => {
    setValue("");
    dispatch(update<string>("searchValue", ""));
  }, []);

  const handleClick = useCallback((e) => {
    e.preventDefault();

    onChange(e.target.text);
  }, []);

  useEffect(() => {
    dispatch(getSearchSections());
    dispatch(getSearchFAQ());

    return () => {
      dispatch(update<[]>("sections", []));
      dispatch(update<string>("searchValue", ""));
      dispatch(update<[]>("results", []));
      dispatch(update<boolean>("isFAQFetching", true));
    };
  }, []);

  useEffect(() => {
    if (!searchValue) {
      dispatch(update<boolean>("isFetching", false));
      setIsFAQVisible(true);
      return;
    } else {
      setIsFAQVisible(false);
    }

    let timer;

    if (!!sections.length) {
      dispatch(update<boolean>("isFetching", true));

      timer = setTimeout(() => {
        sections.map(({ path, name, ordering }) =>
          dispatch(
            getSearchResult({ page: 1, page_size: 3, path, name, ordering })
          )
        );
      }, 400);
    }

    return () => {
      clearTimeout(timer);
      dispatch(update<[]>("results", []));
    };
  }, [sections, searchValue]);

  return (
    <div className={css.searchWrapper}>
      <InputSearch
        value={value}
        onChange={onChange}
        clearValue={clearValue}
        className={css.search}
      />

      {isFetching || isFAQFetching ? (
        <Preloader />
      ) : isFAQVisible ? (
        <FAQ requests={requests} handleClick={handleClick} />
      ) : isClient ? (
        <Client
          results={results}
          countSections={sections.length}
        />
      ) : (
        <Guest products={products} />
      )}
    </div>
  );
};

export default Search;
