import React, { FC } from "react";
import MinusIcon from "@/assets/icons/minus.inline.svg";
import PlusIcon from "@/assets/icons/plus.inline.svg";
import css from "./counter.modules.scss";

type CounterType = {
  min: number;
  max: number;
  value: number;
  status?: string;
  setValue: (value: number) => void;
  step?: number;
  productId?: number;
  isCounterActive?: boolean;
  setIsCounterActive?: (value: boolean) => void;
  className?: string;
  deleteBasket?: (productId: number, quantity?: number) => void
  putBasket?: (productId: number, quantity: number) => void;
  isOrderCard?: boolean;
  isDisabled?: boolean;
};

const Counter: FC<CounterType> = ({
  min,
  max,
  step = 1,
  value,
  setValue,
  productId,
  isCounterActive,
  setIsCounterActive,
  className,
  deleteBasket,
  putBasket,
  status,
  isOrderCard,
  isDisabled = false,
}) => {
  const validateValue = (value) => {
    if (value < min) setValue(min);
    else if (value > max) setValue(max);
  };

  const decreaseValue = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    const currentValue = value - step;

    if (isCounterActive && value === min + 1) {
      setIsCounterActive(false);
    } else {
      setValue(currentValue);
    }

    if (currentValue > 0 && !!putBasket) {
      putBasket(productId, currentValue);
    }

    if (currentValue === 0) {
      deleteBasket(productId);
    }
  };

  const increaseValue = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    const currentValue = value + step;
    setValue(currentValue);
    if (currentValue > 1 && !!putBasket) {
      putBasket(productId, currentValue);
    }
  };

  const disableAddBtn = (status: string) => {
    switch (status) {
      case "accepted":
        return false;
      case "product_deleted":
        return true;
      case "blocked":
        return true;
      case "placed":
        return true;
    }
  }

  return (
    !isOrderCard ? (
      <div className={`flex ${className}`}>
        <button
          onClick={decreaseValue}
          className={`mr-2 ${css.button}`}
          disabled={value === min || disableAddBtn(status) || isDisabled}
          type="button"
        >
          <MinusIcon />
        </button>
        <input
          className={`mr-2 p-1 ${css.input}`}
          type="text"
          onChange={(e) => {
            const value = +e.target.value;
            if (!Number.isInteger(value)) return;

            if(value >= min && value <= max) {
              setValue(value);
              !!putBasket && putBasket(productId, Number(value));
            }
          }}
          value={value}
          onClick={(e) => e.stopPropagation()}
          onBlur={(e) => validateValue(+e.target.value)}
          disabled={disableAddBtn(status) || isDisabled}
        />
        <button
          onClick={increaseValue}
          disabled={value === max || disableAddBtn(status) || isDisabled}
          className={css.button}
          type="button"
        >
          <PlusIcon />
        </button>
      </div>
    ) : (
      <div className={`flex ${className}`}>
        <button
          onClick={decreaseValue}
          className={`mr-2 ${css.button}`}
          disabled={value === min || isDisabled}
          type="button"
        >
          <MinusIcon />
        </button>
        <input
          className={`mr-2 p-1 ${css.input}`}
          type="text"
          onChange={(e) => {
            const value = +e.target.value;
            if (!Number.isInteger(value)) return;

            if(value >= min && value <= max) {
              setValue(value);
              !!putBasket && putBasket(productId, Number(value));
            }
          }}
          value={value}
          onClick={(e) => e.stopPropagation()}
          onBlur={(e) => validateValue(+e.target.value)}
          disabled={isDisabled}
        />
        <button
          onClick={increaseValue}
          disabled={value === max || isDisabled}
          className={css.button}
          type="button"
        >
          <PlusIcon />
        </button>
      </div>
    )
  );
};

export default Counter;
