export const paymentMethodsNames = (paymentMethod: string) => {
  switch (paymentMethod) {
    case "partial_prepay":
      return "Частичная оплата";
    case "prepay":
      return "Предоплата";
    case "postpay":
      return "Постоплата";
  }
};
