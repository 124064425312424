import React, { FC } from "react";

import cn from "classnames/bind";

import { ProductInfoType } from "app/types";

import css from "./info.modules.scss";

interface InfoProps {
  info: ProductInfoType[];
  className?: string;
}

const cx = cn.bind(css);

const Info: FC<InfoProps> = ({ info, className }) => {
  return (
    <div className={cx("info", className)}>
      <div className={cx("left")}>
        {info.map((item, i) => (
          <p key={i}>{item.title || item.name || item[0]}</p>
        ))}
      </div>
      <div className={cx("right")}>
        {info.map((item, i) => (
          <p key={i}>{item.value || item[1]}</p>
        ))}
      </div>
    </div>
  );
};

export default Info;
