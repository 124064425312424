enum STATUS_NAME_REQUEST {
  PUBLIC = "ПУБЛИЧНЫЕ",
  COMPANIES = "КОМПАНИИ",
  MODERATION = "МОДЕРАЦИЯ",
  PUBLISHED = "ОПУБЛИКОВАН",
  POSTED = "РАЗМЕЩЕН",
  CHOOSING_WINNER = "ВЫБОР ЛУЧШЕГО ПРЕДЛОЖЕНИЯ",
  DRAFT = "ЧЕРНОВИК",
  TEMPLATE = "ШАБЛОН",
  THERE_WINNER = "ЕСТЬ ЛУЧШЕЕ ПРЕДЛОЖЕНИЕ",
  NO_WINNER = "БЕЗ ЛУЧШЕГО ПРЕДЛОЖЕНИЯ",
  WINNER_AGREEMENT = "СОГЛАСОВАНИЕ ЛУЧШЕГО ПРЕДЛОЖЕНИЯ",
  COMPLETED = "ЗАВЕРШЕНО",
  NO_PUBLISHED = "НЕ ОПУБЛИКОВАН",
  I_AM_WINNER = "МОЕ ПРЕДЛОЖЕНИЕ ЛУЧШЕЕ",
  WITH_MY_PARTICIPATION = "С МОИМ УЧАСТИЕМ",
  MY_COMPLITED = "МОИ ЗАВЕРШЁННЫЕ",
  PARTNER_PLATFORMS = "ПАРТНЕРСКИЕ ПЛОЩАДКИ",
}

export enum STATUS_COLOR {
  LIGHT_BLUE = "light-blue",
  CYAN = "cyan",
  BLUE = "electric-blue",
  SEA_WAVE = "sea​-wave",
  CORAL = "coral",
  SILVER = "silver",
  DARK_VIVID_PURPLE_CRAYOLA = "dark-vivid-purple-crayola",
  ASPARAGUS = "asparagus",
  LIGHT_CARMINE_PINK = "light-carmine-pink",
  LIGHT_BLUE_GRAY = "light-blue-gray",
  BLUE_FROSTY_SKY = "blue-frosty-sky",
  PEARLESCENT_LIGHT_GRAY = "pearlescent-light-gray",
  BLUE_CRAYOLA = "blue-crayola",
  OLIVE_YELLOW = "olive-yellow",
  DARK_ASPARAGUS = "dark-asparagus",
  VIVID_PURPLE_CRAYOLA = "vivid-purple-crayola",
  BLUE_GRAY = "blue-grey",
  GREEN = "green",

  ERROR = "error",
}

export const colorNameRequest = (statusName: string): string => {
  switch (statusName.toUpperCase()) {
    case STATUS_NAME_REQUEST.PUBLIC:
      return STATUS_COLOR.LIGHT_BLUE;
    case STATUS_NAME_REQUEST.COMPANIES:
      return STATUS_COLOR.LIGHT_BLUE;
    case STATUS_NAME_REQUEST.MODERATION:
      return STATUS_COLOR.BLUE;
    case STATUS_NAME_REQUEST.PUBLISHED:
      return STATUS_COLOR.SEA_WAVE;
    case STATUS_NAME_REQUEST.POSTED:
      return STATUS_COLOR.LIGHT_BLUE;
    case STATUS_NAME_REQUEST.CHOOSING_WINNER:
      return STATUS_COLOR.CORAL;
    case STATUS_NAME_REQUEST.DRAFT:
      return STATUS_COLOR.SILVER;
    case STATUS_NAME_REQUEST.TEMPLATE:
      return STATUS_COLOR.BLUE_GRAY;
    case STATUS_NAME_REQUEST.THERE_WINNER:
      return STATUS_COLOR.GREEN;
    case STATUS_NAME_REQUEST.NO_WINNER:
      return STATUS_COLOR.DARK_VIVID_PURPLE_CRAYOLA;
    case STATUS_NAME_REQUEST.WINNER_AGREEMENT:
      return STATUS_COLOR.ASPARAGUS;
    case STATUS_NAME_REQUEST.COMPLETED:
      return STATUS_COLOR.ERROR;
    case STATUS_NAME_REQUEST.NO_PUBLISHED:
      return STATUS_COLOR.ERROR;
    case STATUS_NAME_REQUEST.I_AM_WINNER:
      return STATUS_COLOR.CORAL;
    case STATUS_NAME_REQUEST.WITH_MY_PARTICIPATION:
      return STATUS_COLOR.GREEN;
    case STATUS_NAME_REQUEST.MY_COMPLITED:
      return STATUS_COLOR.SILVER;
    case STATUS_NAME_REQUEST.PARTNER_PLATFORMS:
      return STATUS_COLOR.LIGHT_BLUE;
    default:
      return "";
  }
};

enum STATUS_NAME_ORDER {
  NEW = "NEW",
  UPDATED = "UPDATED",
  PROCESSING = "PROCESSING",
  CANCELLED = "CANCELLED",
  CLOSED = "CLOSED",
  PREPARATION = "PREPARATION",
  APPROVAL = "APPROVAL",
}

export const colorNameOrder = (statusName: string): string => {
  switch (statusName.toUpperCase()) {
    case STATUS_NAME_ORDER.NEW:
      return STATUS_COLOR.BLUE;
    case STATUS_NAME_ORDER.PROCESSING:
      return STATUS_COLOR.CYAN;
    case STATUS_NAME_ORDER.CLOSED:
      return STATUS_COLOR.GREEN;
    case STATUS_NAME_ORDER.PREPARATION:
    case STATUS_NAME_ORDER.APPROVAL:
    case STATUS_NAME_ORDER.UPDATED:
      return STATUS_COLOR.OLIVE_YELLOW;
    case STATUS_NAME_ORDER.CANCELLED:
      return STATUS_COLOR.ERROR;
    default:
      return "";
  }
};

export enum COLOR_RATING_STATUS {
  BLUE_GRAY = "BLUE_GRAY",
  ERROR = "ERROR",
  DARK_YELLOW = "DARK_YELLOW",
  GREEN = "GREEN",
}

export const colorNameRating = (rating: string): string | null => {
  switch (rating) {
    case "yellow":
      return COLOR_RATING_STATUS.DARK_YELLOW;
    case "green":
      return COLOR_RATING_STATUS.GREEN;
    case "red":
      return COLOR_RATING_STATUS.ERROR;
    case "grey":
      return COLOR_RATING_STATUS.BLUE_GRAY;

    default:
      return null;
  }
};
