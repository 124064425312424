import React, {
  FC,
  useMemo,
  useState,
  useEffect,
} from "react";
import { useDispatch } from "react-redux";

import { Link } from "gatsby";

import {
  OrderItemsType,
  ProductImageType,
  ContractItemsType,
  OrderItemsProductType,
  OrderValueByCategoryType,
  UserCartItemType,
  OrderType,
} from "app/types";

import { formatAmount } from "@/utils/formatPrice";
import Counter from "@/components/ui-kit/counter";
import ButtonDelete from "@/components/ui-kit/buttonDelete";

import { editOrderPositionFailure, editOrderQuantityInfo } from "@/actions/orders.actions";
import { updateProduct, updateProductInCartFailure } from "@/actions/cart.actions";

import {
  Tag,
  Badge,
  Tooltip,
  Modal,
} from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import NoPictureIcon from "@/assets/icons/no-picture.inline.svg";
import css from "./catalogPositionItem.modules.scss";
import PriceInput from "@/components/pages/platform/pages/CardOrder/structure/NewOrderPosition/PriceInput/PriceInput";

type CatalogPositionItemType = {
  isCart?: boolean;
  item: (
    ContractItemsType |
    OrderItemsProductType |
    UserCartItemType
    );
  items?: OrderItemsType[];
  images?:  ProductImageType[];
  admin?: boolean;
  status?: string;
  orderId?: number;
  currency?: string;
  isOrderCard?: boolean;
  productId?: number;
  isEditMode?: boolean;
  productName?: string;
  productPrice?: number; // цена за единицу товара в карточке Заказа
  productValue?: number;
  productArticle?: string;
  cartPositionId?: number;
  setCurrentOrder?: React.Dispatch<React.SetStateAction<OrderType>>;
  deleteCartPosition?: (productId: number) => void;
  valueByCategory?: OrderValueByCategoryType[];
};

const CatalogPositionItem: FC<CatalogPositionItemType> = ({
  isCart,
  item,
  items,
  images,
  status,
  orderId,
  currency,
  isEditMode,
  productId,
  isOrderCard,
  productName,
  productPrice,
  productValue,
  setCurrentOrder,
  productArticle,
  cartPositionId,
  deleteCartPosition,
}) => {
  const { quantity} = item;

  const dispatch = useDispatch();

  const API_BASE: string = process.env.GATSBY_API_BASE?.trimEnd();

  const filterProduct = (): OrderItemsType => items?.filter((item: OrderItemsType) => item?.product?.id === productId)[0];

  const isNewItem: boolean = filterProduct()?.isNew;
  const isDeletedItem: boolean = filterProduct()?.deleted;
  const itemOldPrice: string = filterProduct()?.oldPrice;
  const itemOldQuantity: number = filterProduct()?.oldQuantity;
  const itemOldValue: string = filterProduct()?.oldValue;
  const itemPriceDiff: string = filterProduct()?.priceDiff;
  const itemQuantityDiff: string = filterProduct()?.quantityDiff;

  const [itemStatusColor, setItemStatusColor] = useState<string>("");
  const [itemStatusDescr, setItemStatusDescr] = useState<string>("");
  const [itemCount, setItemCount] = useState<number>(
    !isOrderCard ? Number(quantity) : filterProduct()?.quantity
  );
  const [itemPrice, setItemPrice] = useState<number>(productPrice);
  const [itemCost, setItemCost] = useState<number>(filterProduct()?.value);

  useEffect(() => {
    switch (status) {
      case "accepted":
        setItemStatusColor("#52c41a");
        setItemStatusDescr("Позиция готова к оформлению");
        break;
      case "product_deleted":
        setItemStatusColor("#f5222d");
        setItemStatusDescr("Товар позиции был удален");
        break;
      case "blocked":
        setItemStatusColor("#faad14");
        setItemStatusDescr("Товар в позиции не доступен к оформлению ");
        break;
      default:
        setItemStatusColor("");
        setItemStatusDescr("");
        break;
    }
  }, [status]);

  useEffect(() => {
    setItemCost(itemPrice * itemCount);
  }, [itemPrice, itemCount]);

  useEffect(() => {
    setItemPrice(productPrice);
  }, [productPrice]);

  useEffect(() => {
    setItemCost(filterProduct()?.value);
  }, [filterProduct()?.value]);

  useEffect(() => {
    isOrderCard && setItemCount(filterProduct()?.quantity);
  }, [filterProduct()?.quantity]);

  const setBadgeStatusIcon = () => {
    switch (status) {
      case "accepted":
        return <CheckCircleOutlined style={{color: itemStatusColor}} rev={undefined} />;
      case "product_deleted":
        return <CloseCircleOutlined style={{color: itemStatusColor}} rev={undefined} />;
      case "blocked":
        return <ExclamationCircleOutlined style={{color: itemStatusColor}} rev={undefined} />;
      default:
        return <CheckCircleOutlined style={{color: itemStatusColor}} rev={undefined} />;
    }
  };

  const putBasket = (productId: number, quantity: number) => {
    if (isOrderCard) {
      dispatch(editOrderQuantityInfo(orderId, productId, quantity));
      dispatch(editOrderPositionFailure());
    } else {
      dispatch(updateProduct(productId, quantity));
      dispatch(updateProductInCartFailure());
    }
  };

  const updatePrice = (value: number): void => {
    setItemPrice(value);

    setCurrentOrder((prev: OrderType) => {
      return ({
        ...prev,
        items: prev.items.map((item: OrderItemsType) => {
          if (item.product.id === productId) {
            return ({
              ...item,
              product: { ...item.product, price: value },
              value: value * itemCount
            });
          }

          return item;
        })
      });
    });
  };

  const updateQuantity = (value: number): void => {
    setItemCount(value);

    setCurrentOrder((prev: OrderType) => {
      return ({
        ...prev,
        items: prev.items.map((item: OrderItemsType) => {
          if (item.product.id === productId) {
            return ({
              ...item,
              quantity: value,
              value: value * itemPrice
            });
          }

          return item;
        })
      });
    });
  };

  const openConfirmModal = (): void => {
    Modal.confirm({
      cancelText: "Отмена",
      okText: "Удалить",
      title: "Вы уверены, что хотите удалить позицию заказа?",
      content: (
        <p>
          После удаления товар можно добавить в заказ новой позицией
        </p>
      ),
      onOk() {
        deleteOrderPosition();
      },
    });
  };

  const deleteOrderPosition = (): void => {
    setCurrentOrder((prev: OrderType) => {
      return ({
        ...prev,
        items: prev.items.filter((item: OrderItemsType) => item.product.id !== productId)
      });
    });
  };

  const renderNoPicture = useMemo(
    () => (
      <div className={`flex flex-col ${isCart ? "items-center" : "items-start"} tablet:mb-0 ${css.image}`}>
        <NoPictureIcon />
      </div>
    ), []);

  const isInitialItem: boolean = !isDeletedItem && !isNewItem && !itemOldPrice && !itemOldQuantity;
  const isUpdatedItem: boolean = !!itemOldPrice || !!itemOldQuantity;
  {/*TODO: пока скрываем от всех стоимость */}
  // const price: string = !!itemPrice ? formatAmount(itemPrice?.toString(), currency) : "На согласовании";
  // const cost: string = !!itemCost ? formatAmount(itemCost?.toString(), currency) : "На согласовании";

  return (
    <li className={`flex flex-col tablet:flex-row dir ${css.row}`}>
      <div className={`flex ${isCart ? "items-center" : "items-start"} justify-between mb-6 tablet:mb-0`}>
        <div className={`flex ${isCart ? "items-center" : "items-start"}`}>
          <div className={`text-t1 ${css.columnImage} ${isOrderCard ? css.columnOrderItemImage : css.columnCartItemImage}`}>
            <div className="text-n1">
              {!!images?.length
                ? (
                  <div key={images[0].id} className={isCart ? css.cartImage : css.orderImage}>
                    <img src={`${API_BASE}${images[0].link}`}/>
                  </div>
                ) : renderNoPicture}
            </div>
          </div>
          <div className={`text-t1 ${css.columnName} ${isCart ? css.columnCartName : css.columnOrderName}`}>
            {!isOrderCard && (
              <Tooltip title={itemStatusDescr}>
                <Badge className="mr-1" size="default" count={setBadgeStatusIcon()} />
              </Tooltip>
            )}
            <Link to={`/platform/catalog/products/${productId}`} className={`${css.nameWrap}`}>
              {!isDeletedItem ? (
                <>
                  <span className="inline-block w-full">
                    {productName?.length > 80 ? (
                      <Tooltip title={productName}>
                        {productName?.substring(0, 80).trim()}
                        {productName?.length > 80 && <span>...</span>}
                      </Tooltip>
                    ) : (
                      <span>{productName}</span>
                    )}
                  </span>
                  {productArticle && <span className="inline-block text-n1">Арт: {productArticle}</span>}
                </>
              ) : (
                <>
                  <span className="inline-block w-full line-through">
                    {productName?.length > 80 ? (
                      <Tooltip title={productName}>
                        {productName?.substring(0, 80).trim()}
                        {productName?.length > 80 && <span>...</span>}
                      </Tooltip>
                    ) : (
                      <span>{productName}</span>
                    )}
                  </span>
                  {productArticle && <span className="inline-block text-n1 line-through">Арт: {productArticle}</span>}
                </>
              )}
            </Link>
          </div>
        </div>
      </div>
      <div className={`flex flex-1 ${isCart || (isEditMode && !isDeletedItem) ? "items-center" : "items-start"}`}>
        {/*TODO: пока скрываем от всех стоимость */}
        {/*{isOrderCard && (*/}
        {/*  <>*/}
        {/*    {isEditMode && !isDeletedItem ? (*/}
        {/*      <PriceInput price={itemPrice} onChangeHandler={updatePrice} />*/}
        {/*    ) : (*/}
        {/*      <div className={`hidden tablet:block ${isCart ? css.columnCartPrice : css.columnOrderPrice} ${css.price}`}>*/}
        {/*        {(isInitialItem || isNewItem) && <span>{price}</span>}*/}
        {/*        {isDeletedItem && <span className="inline-block line-through">{price}</span>}*/}
        {/*        {isUpdatedItem && (*/}
        {/*          <div className={`flex flex-col ${isCart ? "items-center" : "items-start"}`}>*/}
        {/*            <span className="inline-block w-full">{price}</span>*/}
        {/*            <span className="inline-block line-through text-n1 text-blue-grey">*/}
        {/*              {formatAmount(itemOldPrice, currency)}*/}
        {/*             </span>*/}
        {/*            {itemPriceDiff && itemPriceDiff?.toString()?.includes("-") && (*/}
        {/*              <span className="inline-block text-n1 text-dark-asparagus">{formatAmount(itemPriceDiff, currency)}</span>*/}
        {/*            )}*/}
        {/*            {itemPriceDiff && itemPriceDiff?.toString()?.includes("+") && (*/}
        {/*              <span className="inline-block text-n1 text-error">{formatAmount(itemPriceDiff, currency)}</span>*/}
        {/*            )}*/}
        {/*          </div>*/}
        {/*        )}*/}
        {/*      </div>*/}
        {/*    )}*/}
        {/*  </>*/}
        {/*)}*/}
        <div className={isCart ? css.columnCartCount : css.columnOrderCount}>
          {isOrderCard && (!isEditMode || isDeletedItem) ? (
            <div className="text-n1 ">
              {(isInitialItem || isNewItem) && <span>{itemCount}</span>}
              {isDeletedItem && <span className="inline-block line-through">{itemCount}</span>}
              {isUpdatedItem && (
                <div className={`flex flex-col ${isCart ? "items-center" : "items-start"}`}>
                  <span className="inline-block w-full">
                    {itemCount}
                  </span>
                  <span className="inline-block line-through text-n1 text-blue-grey">
                    {itemOldQuantity}
                  </span>
                  {itemQuantityDiff && itemQuantityDiff?.toString()?.includes("-") && (
                    <span className="inline-block text-n1 text-error">
                      {itemQuantityDiff}
                    </span>
                  )}
                  {itemQuantityDiff && itemQuantityDiff?.toString()?.includes("+") && (
                    <span className="inline-block text-n1 text-dark-asparagus">
                      {itemQuantityDiff}
                    </span>
                  )}
                </div>
              )}
            </div>
          ) : (
            <Counter
              productId={!isOrderCard ? cartPositionId : productId}
              min={1}
              max={999999}
              value={+itemCount}
              status={status}
              setValue={!isOrderCard ? setItemCount : updateQuantity}
              putBasket={!isOrderCard && putBasket}
              isOrderCard={isOrderCard}
            />
          )}
        </div>
        <div
          className={`flex ${isCart ? "items-center" : "items-start"} justify-between ${css.columnCost} ${css.price}`}
        >
          {isOrderCard && (
            <p className={isCart ? "text-s1 mr-5" : "text-s1"}>
              {/*TODO: пока скрываем от всех стоимость */}
              {/*{isInitialItem && cost}*/}
              {isDeletedItem && (
                <>
                {/*TODO: пока скрываем от всех стоимость */}
                {/*<span className="line-through">*/}
                {/*  {!isOrderCard ? formatAmount(productValue?.toString(), currency) : cost}*/}
                {/*</span>*/}
                  <div className="mt-3">
                    <Tag color="error">Удалена</Tag>
                  </div>
                </>
              )}
              {isNewItem && (
                <>
                  {/*TODO: пока скрываем от всех стоимость */}
                  {/*<span>{!isOrderCard ? formatAmount(productValue.toString(), currency) : cost}</span>*/}
                  <div className="mt-3">
                    <Tag color="success">Новая позиция</Tag>
                  </div>
                </>
              )}
              {isUpdatedItem && (
                <>
                {/*TODO: пока скрываем от всех стоимость */}
                {/*<span className="inline-block w-full">*/}
                {/*  {!isOrderCard ? formatAmount(productValue?.toString(), currency) : cost}*/}
                {/*</span>*/}
                {/*  <span className="inline-block line-through text-n1 text-blue-grey">*/}
                {/*  {formatAmount(!isOrderCard ? productValue?.toString() : itemOldValue, currency)}*/}
                {/*</span>*/}
                  <div className="mt-3">
                    <Tag color="warning">Изменена</Tag>
                  </div>
                </>
              )}
            </p>
          )}
          {(!isOrderCard || (isEditMode && !isDeletedItem)) && (
            <ButtonDelete
              theme="secondary"
              className="ml-4"
              onClick={() => {
                !isOrderCard
                  ? deleteCartPosition(cartPositionId)
                  : openConfirmModal();
              }}
            />
          )}
        </div>
      </div>
    </li>
  );
};

export default CatalogPositionItem;
