import React, { FC } from "react";
import css from "./guest.modules.scss";
import { useDispatch } from "react-redux";
import { Link } from "gatsby";
import { getSearchPagination } from "@/actions/search.actions";
import { SearchProductsType } from "app/types";
import BottomButtonCatalog from "@/components/ui-kit/bottomButtonCatalog";
import Preloader from "@/components/ui-kit/preloader";
import NoResult from "../noresult";

type GuestTypeProps = {
  products: false | SearchProductsType;
};

const Guest: FC<GuestTypeProps> = ({ products }) => {
  const dispatch = useDispatch();
  const onPagination = (name: string) => dispatch(getSearchPagination(name));

  if (!products || !products.totalCount) return <NoResult />;

  const { name, items, isFetching, currentCount, totalCount } = products;

  return (
    <div className={`${css.guest}`}>
      <section className={css.result}>
        <h5 className={`text-h5 ${css.result__header}`}>Каталог</h5>
        <ul className={css.result__list}>
          {items.map(({ id, name }) => (
            <li className={`text-h4 ${css.result__item}`} key={id}>
              <Link to={`/platform/catalog/category/${id}`}>{name}</Link>
            </li>
          ))}
        </ul>
      </section>
      {isFetching ? (
        <Preloader />
      ) : (
        currentCount < totalCount && (
          <div onClick={() => onPagination(name)} className={css.showMore}>
            <BottomButtonCatalog />
          </div>
        )
      )}
    </div>
  );
};

export default Guest;
