import React, { FC } from "react";

import Button from "@/components/ui-kit/button";

import CloseIcon from "@/assets/icons/close-middle.inline.svg";

import css from "./modalPlug.modules.scss";

type ModalPlugPropsType = {
  title: string;
  text: string;
  onClose: () => void;
  onOk: () => void;
  textButton?: string;
  className?: string;
};

const ModalPlug: FC<ModalPlugPropsType> = ({
  title,
  text,
  onClose,
  onOk,
  className = "",
  textButton = "Хорошо",
}) => (
  <div
    className={`relative pt-6 pl-6 pr-6 tablet:pl-8 tablet:pr-8 mobile:h-full ${css.ModalPlug} ${className}`}
  >
    <div className="flex justify-end">
      <CloseIcon className="cursor-pointer" onClick={onClose} />
    </div>
    <div className="mt-9 tablet:mt-11 text-x2">{title}</div>
    <div className="mt-3 text-t1 text-blue-grey">{text}</div>
    <Button
      onClick={onOk}
      className={`tablet:mt-14 mobile:w-full ${css.button}`}
    >
      {textButton}
    </Button>
  </div>
);

export default ModalPlug;
