import React, { FC, useEffect } from "react";
import { Link, navigate } from "gatsby";
import humps from "humps";

import usePageScroll from "@/hooks/usePageScroll";
import useMobile from "@/hooks/useMobile";
import { ProductType, DeliveryRulesType } from "app/types";

import Button from "@/components/ui-kit/button";
import HeaderBasket from "@/components/headerBasket";
import QuestionInCircleIcon from "@/components/ui-kit/questionInCircleIcon";
import DeliveryTermsIcon from "@/components/ui-kit/deliveryTermsIcon";
import CatalogIcon from "@/assets/icons/catalog.inline.svg";

import cn from "classnames/bind";
import css from "./CatalogProductCartBlock.modules.scss";
import Cart from "@/components/productCard/cart";
import { useSelector } from "react-redux";
import { AppStateType } from "@/reducers";
import { Tooltip } from "antd";
import { roleResolver } from "@/utils/roleResolver";

interface DescriptionProductPropsType {
  isMsp?: boolean;
  amount: number;
  currency: string;
  itemCount: number;
  name: ProductType["name"];
  modificationRest?: number;
  modificationPrice?: number;
  basePrice?: ProductType["price"];
  deliveryRules?: DeliveryRulesType;
  product_value?: string;
  organization?: string;
  supplier: ProductType["supplier"];
  supplierCompanyColor?: string;
  hasModificationPrice?: boolean;
  modificationCountInUsercart?: number;
  setItemCount: (q: number) => void;
  setIsCounterActive: (state: boolean) => void;
  isCounterActive: boolean;
  createBasket?: (productId: number, quantity: number) => void;
  deleteBasket?: (productId: number) => void;
  currentCategoryId?: string | number;
  putBasket?: (productId: number, quantity: number) => void;
  productId?: number;
}

const CatalogProductCartBlock: FC<DescriptionProductPropsType> = ({
  amount,
  supplier,
  currency,
  basePrice,
  itemCount,
  setItemCount,
  deliveryRules,
  isCounterActive,
  setIsCounterActive,
  putBasket ,
  hasModificationPrice,
  modificationPrice = 0,
  createBasket,
  deleteBasket,
  modificationCountInUsercart,
  productId,
}) => {
  const { isMobile } = useMobile();
  const { isScrolled } = usePageScroll();

  const { userInfo } = useSelector((state: AppStateType) => state.account);

  const { isDemo, isSupplier } = roleResolver(userInfo?.roles);

  useEffect(() => {
    if (!!modificationCountInUsercart) {
      setIsCounterActive(true);
      setItemCount(modificationCountInUsercart);
    }
  }, [modificationCountInUsercart]);

  const createCamelizeKeysForDeliveryRules = () =>
    humps.camelizeKeys(deliveryRules);

  const backToCatalog = () => {
    history.back();
    navigate('/platform/catalog/');
  };

  const isHiddenSupplierCard: boolean = isSupplier || isDemo;

  const renderSupplierName =  supplier?.name?.length > 80 ? (
    <Tooltip title={supplier?.name}>
      {supplier?.name?.substring(0, 80).trim()}
      {supplier?.name?.length > 80 && (
        <span>...</span>
      )}
    </Tooltip>
  ) : (
    <span>{supplier?.name}</span>
  );

  return (
    <div className={`flex ${css.block}`}>
      <Cart
        productId={productId}
        amount={amount}
        currency={currency}
        itemCount={itemCount}
        basePrice={basePrice}
        putBasket={putBasket}
        setItemCount={setItemCount}
        createBasket={createBasket}
        deleteBasket={deleteBasket}
        isCounterActive={isCounterActive}
        modificationPrice={modificationPrice}
        setIsCounterActive={setIsCounterActive}
        hasModificationPrice={hasModificationPrice}
      />
      <div className={css.companyBlock}>
        {/*<div className={`${isMobile && css.iconsWrapper}`}>*/}
        {/*  <Labels*/}
        {/*    isMsp={isMsp}*/}
        {/*    className={`${isMobile && css.providersOffers}`}*/}
        {/*    supplierCompanyColor={supplierCompanyColor}*/}
        {/*  />*/}
        {/*</div>*/}
        <div className={css.productName}>
          {!isHiddenSupplierCard ? (
            <Link to={`/platform/suppliers/${supplier?.id}/`} className="border-none" target="_blank">
              {renderSupplierName}
            </Link>
          ) : (
            <div>{renderSupplierName}</div>
          )}
        </div>
        <div className="flex">
          {/* Скрыть кнопку до тех пор, пока не будет реализован функционал */}
          {/* <Button theme="withoutBorder" className={css.writeButton}>
            <Question className={css.iconQuestion} />
            написать
          </Button> */}
          <Button
            onClick={backToCatalog}
            theme="withoutBorder"
            className={css.writeButton}
          >
            <CatalogIcon className={css.iconCatalog} />
            каталог
          </Button>
        </div>
        <div className={`flex items-center mt-2 invisible ${css.deliveryTermsHover}`}>
          <DeliveryTermsIcon className="cursor-pointer" />
          <Button className="tablet:ml-2" theme="withoutBorder">
            <span>рассчитать доставку</span>
          </Button>
          <QuestionInCircleIcon className="ml-2 cursor-pointer" />
        </div>
      </div>
      {isMobile && (
        <div
          data-aos="fade-down"
          data-aos-once="true"
          className={cn(`bg-white ${css.headerBasket}`, {
            [css.headerBasketTransform]: isScrolled,
          })}
        >
          <HeaderBasket
            productId={productId}
            basePrice={+basePrice}
            amount={amount}
            hasModificationPrice={hasModificationPrice}
            //product_value={product_value}
            itemCount={itemCount}
            isCounterActive={isCounterActive}
            setItemCount={setItemCount}
            setIsCounterActive={setIsCounterActive}
            deleteBasket={deleteBasket}
            createBasket={createBasket}
            putBasket={putBasket}
            modificationPrice={modificationPrice}
          />
        </div>
      )}
    </div>
  );
};

export default CatalogProductCartBlock;
