export const formatPrice = (
  price: number | string,
  currency?: string
): string => {
  if (typeof price === "string") price = parseFloat(price);

  if (isNaN(price) || price === null) return "";

  return price.toLocaleString("ru-RU", {
    ...(currency && { style: "currency", currency }),
    minimumFractionDigits: 2,
  });
};

export function formatAmount(amountText: string, currency: string = ""): string {
  const amount: number = Number.parseFloat(amountText);
  const amountString: string = amount.toString();

  if (!amount) return "";

  const parts: string[] = amountString?.includes(".")
    ? amountString?.split(".")
    : amountString?.split(",");

  // разделяет неразрывными пробелами число на разряды
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "\xa0");

  if (parts.length == 1) {
    parts.push("00");
  }

  parts[1] = parts[1].padEnd(2, "0");

  return `${parts.join(",")}\xa0${currency === "RUB" ? "₽" : currency}`;
}
