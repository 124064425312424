import React, { FC, useState } from "react";
import cn from "classnames/bind";
import useMobile from "@/hooks/useMobile";
import { AnimatePresence, motion } from "framer-motion";
import { ProductCategoryType, ProductType } from "app/types";
import css from "./CatalogProductDescriptionBlock.modules.scss";

interface ICatalogProductDescriptionBlockProps {
  modifications?: ProductType[];
  description: ProductType["description"];
  baseCode: string;
  modificationCode?: string;
  changeModification?: (payload: {
    article: string;
    rest: number;
    price: number;
    id: number;
  }) => void;
  category: string;
}

const cx = cn.bind(css);

const CatalogProductDescriptionBlock: FC<ICatalogProductDescriptionBlockProps> = ({
  baseCode,
  modificationCode,
  changeModification,
  modifications,
  description,
  category,
}) => {
  const [activeBtn, setActiveBtn] = useState<number>(0);
  const [isTextShow, setIsTextShow] = useState<boolean>(false);

  const onActiveBtn = (value: number) => setActiveBtn(value);
  const { isMobile } = useMobile();

  return (
    <div className={cx("descriptionBlock", { border: !isMobile })}>
      {/*{!!modifications.length && (*/}
      {/*  <>*/}
      {/*    <span className={css.colorText}>Модификация товара</span>*/}
      {/*    <div className={css.buttonBlock}>*/}
      {/*      {modifications.map(*/}
      {/*        ({ article, rest, price, name, id }, index) => (*/}
      {/*          <TabButton*/}
      {/*            key={id}*/}
      {/*            isActive={activeBtn === index}*/}
      {/*            className={cx("colorButton", { active: activeBtn === index })}*/}
      {/*            onClick={() => {*/}
      {/*              onActiveBtn(index);*/}
      {/*              const payload = {*/}
      {/*                id,*/}
      {/*                article,*/}
      {/*                rest,*/}
      {/*                price,*/}
      {/*              };*/}
      {/*              changeModification(payload);*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            {name}*/}
      {/*          </TabButton>*/}
      {/*        )*/}
      {/*      )}*/}
      {/*    </div>*/}
      {/*  </>*/}
      {/*)}*/}
      <span className={css.text}>
        Артикул: {modificationCode ? modificationCode : baseCode}
      </span>
      <span className={css.text}>
        Категория: {category}
      </span>
      <div className={css.productDescription}>
        <span className={css.title}>Описание</span>
        <motion.div className={css.relative}>
          <AnimatePresence>
            {description && (
              <div className={css.text}>
                {description.substr(0, 130).trim()}
                {description.length > 130 && !isTextShow ? (
                  <>
                    <button
                      className={`text-electric-blue underline`}
                      onClick={() => setIsTextShow(true)}
                    >
                      + читать
                    </button>
                  </>
                ) : (
                  <>
                    {description.substr(130).trim()}
                    {description.length > 130 && (
                      <button
                        className={`text-electric-blue underline`}
                        onClick={() => setIsTextShow(false)}
                      >
                        - скрыть
                      </button>
                    )}
                  </>
                )}
              </div>
            )}
          </AnimatePresence>
        </motion.div>
      </div>
    </div>
  );
};

export default CatalogProductDescriptionBlock;
