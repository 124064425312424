import React, { FC } from "react";
import { ProductType } from "app/types";
import CatalogProductHeadingBlock from "./CatalogProductBlock/CatalogProductHeadingBlock";
import CatalogProductInfoBlock from "./CatalogProductBlock/CatalogProductInfoBlock";

type ICatalogProductBlocksProps = {
  productItem: ProductType;
  currentCategoryId: number | string;
  productId: number;
};

const CatalogProductBlocks: FC<ICatalogProductBlocksProps> = ({
  productItem,
  currentCategoryId,
  productId,
}) => {
  return (
    productItem && (
      <>
        <CatalogProductHeadingBlock
          productData={productItem}
          currentCategoryId={currentCategoryId}
          productId={productId}
        />
        <CatalogProductInfoBlock info={productItem.properties} />
      </>
    )
  );
};

export default CatalogProductBlocks;
