import React, {
  FC,
  useState,
  Dispatch,
  SetStateAction,
} from "react";

import cn from "classnames";

import NewArrowDownIcon from "@/assets/icons/new-arrow-down.inline.svg";
import NewArrowUpIcon from "@/assets/icons/new-arrow-up.inline.svg";

import css from "./card.modules.scss";

type CardPropsType = {
  children: React.ReactNode;
  title: string;
  className?: string;
  containerClassName?: string;
  descriptionCardShown?: boolean;
};

const Card: FC<CardPropsType> = ({
  children,
  title,
  className = "",
  containerClassName = "",
  descriptionCardShown,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(!!descriptionCardShown);

  const changeIsVisible = (): void => setIsVisible((isPrev) => !isPrev);

  return (
    <div className={`bg-white ${className}`}>
      <div
        className={cn(css.container, {
          [css.container__notVisible]: !isVisible,
        })}
      >
        <div
          className="flex justify-between cursor-pointer"
          onClick={changeIsVisible}
        >
          <div className={css.titleText}>{title}</div>
          <div className="flex justify-center items-center">
            {isVisible ? <NewArrowUpIcon /> : <NewArrowDownIcon />}
          </div>
        </div>
        <div className={`mt-6 tablet:mt-10 ${containerClassName} ${isVisible ? "block" : "hidden"}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Card;
