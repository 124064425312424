import React, { FC, ReactNode, useMemo } from "react";

import cn from "classnames/bind";
import { Link } from "@reach/router"

import useMobile from "@/hooks/useMobile";

import Button from "@/components/ui-kit/button";
import { Tooltip } from "antd";
import LogoIcon from "./logoIcon";
import CatalogIcon from "@/assets/icons/catalog-icon.inline.svg";
import Company1Icon from "@/assets/icons/company1-icon.inline.svg";
import Company2Icon from "@/assets/icons/company2-icon.inline.svg";

import css from "./suppliersCard.modules.scss";

const cx = cn.bind(css);

export const icons = {
  icon1: Company1Icon,
  icon2: Company2Icon,
};

type SuppliersCardProps = {
  id: number;
  icon: string;
  organization: string;
  optionalCol2: ReactNode;
  optionalCol1?: ReactNode;
  className?: string;
  path?: string;
  aosDuration?: string;
  aosOffset?: string;
  countProducts?: number;
};

const SuppliersCard: FC<SuppliersCardProps> = ({
  id,
  icon,
  organization,
  optionalCol1,
  optionalCol2,
  className,
  path,
  aosDuration,
  aosOffset = "0",
  countProducts,
}) => {
  const { isMobile } = useMobile();

  const organizationFirstLetter = useMemo(() => {
    const strWithoutSpecialSymbols = organization && organization.replace(/['"/\/|\^@!#$%&*()\-\+?{}[\]\\.,;:\<\>\=`~_№]/g, "");
    const strWithoutLLC = strWithoutSpecialSymbols && strWithoutSpecialSymbols.replace(/ООО|ИП|АО|ПАО|ОАО|АНО|OOO/, ""
    );

    return strWithoutLLC && strWithoutLLC.trim()[0];
  }, [organization]);

  return (
    <div
      className={cx(
        "flex flex-col bg-white p-5 tablet:flex-row tablet:justify-between tablet:p-8",
        "suppliersCard",
        className
      )}
      data-aos="fade-up"
      data-aos-duration={aosDuration}
      data-aos-once="true"
      data-aos-offset={aosOffset}
    >
      <div className={isMobile ? "flex items-center" : ""}>
        <LogoIcon
          Icon={icon}
          organizationFirstLetter={organizationFirstLetter}
        />
      </div>

      <div className={cx("flex flex-col")}>
        <Tooltip title={organization} color="#808188">
          <p className={cx("text-h4", "suppliersCard__organizationText")}>
            {/*TODO: скрываем переход в карточку поставщика, пока она не реализована*/}
            {/*<Link to={`${id}`} className="border-none">{organization}</Link>*/}
            <span className="border-none">{organization}</span>
          </p>
        </Tooltip>
        <div className="flex text-n1 mt-1 tablet:mt-0">
          {!!countProducts && (
            <Button
              theme="withoutBorder"
              className={cx("flex", "suppliersCard__button")}
            >
              <Link
                className="border-none flex items-center"
                to={`${id}#catalog`}
              >
                <CatalogIcon className={`mr-2 ${css.iconСatalog}`} />
                <span>{`каталог(${ countProducts })`}</span>
              </Link>
            </Button>
          )}
        </div>
      </div>
      {optionalCol1}
      {optionalCol2}
    </div>
  );
};

export default SuppliersCard;
