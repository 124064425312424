import React, { FC } from "react";

import cn from "classnames/bind";

import { colorNameRating, COLOR_RATING_STATUS } from "@/utils/statusColor";

import Tooltip from "@/components/ui-kit/tooltip";

import VtbIcon from "@/assets/icons/vtb-icon.inline.svg";

import css from "./labels.modules.scss";

const cx = cn.bind(css);

interface LabelProps {
  className?: string;
  supplierCompanyColor: string;
  isMsp: boolean;
}

const Labels: FC<LabelProps> = ({
  className,
  children,
  supplierCompanyColor,
  isMsp,
}) => {
  return (
    <div className={cx("flex flex-col tablet:flex-row", "labels", className)}>
      <div className="flex">
        {supplierCompanyColor && (
          <span
            className={cx(" mr-1", "ellipse", {
              ["bg-blue-grey"]:
                colorNameRating(supplierCompanyColor) ===
                COLOR_RATING_STATUS.BLUE_GRAY,
              ["bg-green"]:
                colorNameRating(supplierCompanyColor) ===
                COLOR_RATING_STATUS.GREEN,
              ["bg-error"]:
                colorNameRating(supplierCompanyColor) ===
                COLOR_RATING_STATUS.ERROR,
              ["bg-dark-yellow"]:
                colorNameRating(supplierCompanyColor) ===
                COLOR_RATING_STATUS.DARK_YELLOW,
            })}
          ></span>
        )}
        <Tooltip
          className={"contents"}
          text={<VtbIcon className={cx("mr-1", "vtbIcon")} />}
          tooltipText="Клиент ВТБ"
          isIconShow={false}
        />
        {isMsp && (
          <Tooltip
            className={"contents"}
            text={
              <span
                className={cx(
                  "mr-2 flex justify-center items-center bg-blue-grey text-white",
                  "msp"
                )}
              >
                мсп
              </span>
            }
            tooltipText="МСП"
            isIconShow={false}
          />
        )}
      </div>
      {children}
    </div>
  );
};

export default Labels;
