import React, { FC } from "react";

import Description from "@/components/suppliersCard/description";
import { Tooltip } from "antd";

import css from "./optionalCol2.modules.scss";

import cn from "classnames/bind";
const cx = cn.bind(css);

interface OptionalCol2Props {
  categories: { id: number; name: string }[];
  className?: string;
}

const OptionalCol2: FC<OptionalCol2Props> = ({ categories, className }) => (
  <Description className={cx("mt-2 tablet:mt-0", className)} title="Категория">
    {categories?.length ? (
      <div className={cx("flex flex-wrap flex-col text-n2", "categories")}>
        {categories.slice(0, 2).map(({ id, name }, index) => (
          <div key={id} className="flex">
            <Tooltip title={name} color="#808188">
              <div className={cx("mb-1 text-white", "category")}>{name}</div>
            </Tooltip>
            {categories.length > 2 && index === 1 && (
              <span className={cx("mb-1 ml-1 text-white", "category")}>
                ...
              </span>
            )}
          </div>
        ))}
      </div>
    ) : (
      <div className={cx("text-h4", "categories")}>Не указана</div>
    )}
  </Description>
);

export default OptionalCol2;
