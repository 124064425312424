import { useSpring } from "react-spring";

const useScrollTo = (to = 0) => {
  let isStopped = false;
  const onWheel = () => {
    isStopped = true;
    window.removeEventListener("wheel", onWheel);
    window.removeEventListener("touchstart", onWheel);
  };

  const [, setY] = useSpring(() => ({
    y: 0,
  }));

  const scrollTo = () => {
    window.addEventListener("wheel", onWheel);
    window.addEventListener("touchstart", onWheel);
    setY({
      y: to,
      reset: true,
      from: { y: window.scrollY },
      onRest: () => {
        isStopped = false;
        window.removeEventListener("wheel", onWheel);
        window.removeEventListener("wheel", onWheel);
      },
      onFrame: (props) => {
        if (!isStopped) {
          window.scroll(0, props.y);
        }
      },
    });
  };

  return { scrollTo };
};

export default useScrollTo;
