import React, { FC } from "react";

import Button from "@/components/ui-kit/button";
import ExclamationIcon from "@/assets/icons/exclamation.inline.svg";

import css from "./warningInfoCard.modules.scss";

interface IWarningInfoCardProps {

}

const WarningInfoCard: FC<IWarningInfoCardProps> = (): JSX.Element => {
  const handleOnClick = () => {
    const url: string = "https://www.smbconnect.ru/media/other/%D0%A4%D0%BE%D1%80%D0%BC%D0%B0_%D0%9A%D0%9F_%D0%B4%D0%BB%D1%8F_%D0%BA%D0%BE%D1%80%D0%BF_%D0%BC%D0%B0%D0%B3%D0%B0%D0%B7%D0%B8%D0%BD%D0%B0_%D0%98%D0%A2.XLSX";

    window.open(url, "_blank");
  };

  return (
    <div className={`text-white ${css.card}`}>
      <div className="flex justify-between flex-col tablet:flex-row">
        <div className={`flex items-center ${css.title}`}>
          <div className={css.icon}>
            <ExclamationIcon />
          </div>
          <p>В рамках заказа вам необходимо загрузить 2 документа в блоке Документооборот ниже</p>
        </div>
        <Button theme="white" onClick={handleOnClick}>СКАЧАТЬ ШАБЛОН РАСКРЫТИЯ КП</Button>
      </div>
      <div className={css.description}>
        <p>1. Скан коммерческого предложения с печатью</p>
        <p>2. Скачать и заполнить приложенный "Шаблон раскрытия КП». Документ будет виден только согласующим</p>
      </div>
    </div>
  );
};

export default WarningInfoCard;
