import React, {
  FC,
  Dispatch,
  SetStateAction,
} from "react";
import {
  Button,
  Drawer
} from "antd";
import TextArea from "antd/es/input/TextArea";

interface IDeclineReasonModalProps {
  handleCancel: () => void;
  declineReasonText: string;
  handleApply: () => void;
  setDeclineReasonText: Dispatch<SetStateAction<string>>;
  isOpen: boolean;
}

export const DeclineReasonModal: FC<IDeclineReasonModalProps> = ({
  handleCancel,
  declineReasonText,
  handleApply,
  setDeclineReasonText,
  isOpen,
}) => {
  return (
    <Drawer
      title="Причина отклонения"
      placement="right"
      onClose={handleCancel}
      open={isOpen}
    >
      <>
        <TextArea
          rows={4}
          onChange={(e) => setDeclineReasonText(e?.target?.value)}
          placeholder="Введите причину отклонения"
          required
        />
        <div className="flex mt-5">
          <Button
            type="primary"
            className="mr-3"
            onClick={handleApply}
            disabled={!declineReasonText}
          >
            Принять
          </Button>
          <Button
            type="default"
            onClick={handleCancel}
          >
            Отмена
          </Button>
        </div>
      </>
    </Drawer>
  );
};
