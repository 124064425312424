import React, { FC, useEffect, useMemo, useState } from "react";

import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "@/reducers";
import { OrderItemsType } from "app/types";
import * as ProductsAPI from "@/api/products.api";

import { Select } from "antd";
import Counter from "@/components/ui-kit/counter";
import ButtonDelete from "@/components/ui-kit/buttonDelete";
import PriceInput from "@/components/pages/platform/pages/CardOrder/structure/NewOrderPosition/PriceInput/PriceInput";
import NoPictureIcon from "@/assets/icons/no-picture.inline.svg";

import { formatAmount } from "@/utils/formatPrice";
import { AdditionalProductType } from "@/components/pages/platform/pages/CardOrder";

import css from "./newOrderPosition.modules.scss";

type OptionType = {
  key: number;
  value: number;
  label: string;
  price: number;
  status: string;
  images: { id: number, link: string, isBase: boolean, image: string }[];
};

interface INewOrderPositionProps {
  items: OrderItemsType[];
  product: AdditionalProductType;
  currency: string;
  additionalProducts: AdditionalProductType[];
  setAdditionalProducts: React.Dispatch<React.SetStateAction<AdditionalProductType[]>>;
  isDisabledFields: boolean;
}

const NewOrderPosition: FC<INewOrderPositionProps> = ({
  items,
  product,
  currency,
  additionalProducts,
  setAdditionalProducts,
  isDisabledFields,
}): JSX.Element => {
  const { userInfo } = useSelector((state: AppStateType) => state.account);

  const API_BASE: string = process.env.GATSBY_API_BASE?.trimEnd();

  {/*TODO: пока скрываем от всех стоимость */}
  // const [cost, setCost] = useState<number>(1);
  const [selectedProduct, setSelectedProduct] = useState<OptionType>(null);
  const [searchValue, setSearchValue] = useState<string>(null);
  const [supplierProducts, setSupplierProducts] = useState<OptionType[]>([]);

  useEffect(() => {
    searchValue !== null && onSearchHandler(searchValue);
  }, [searchValue]);

  useEffect(() => {
    !!selectedProduct && changeProduct(selectedProduct);
  }, [selectedProduct]);

  {/*TODO: пока скрываем от всех стоимость */}
  // useEffect(() => {
  //   setCost(product.count * product.itemPrice);
  // }, [product.count, product.itemPrice]);

  // меняем товар, при выборе в селекторе
  const changeProduct = (option: OptionType): void => {
    setAdditionalProducts((prev: AdditionalProductType[]) => {
      return (
        prev.map((item: AdditionalProductType) => {
          if (item.productId === product.productId) {
            return ({
              ...item,
              productId: option?.value,
              // itemPrice: option?.price ?? 1, TODO: пока скрываем от всех стоимость
              images: option?.images,
              status: option?.status,
              label: option?.label
            });
          }

          return item;
        })
      );
    });

    setSelectedProduct(option);
  };

  // меняем цену или количество
  const updateProduct = (field: {[key: string]: number}): void => {
    setAdditionalProducts((prev: AdditionalProductType[]) => {
      return (
        prev.map((item: AdditionalProductType) => {
          if (item.productId === product.productId) {
            return ({ ...item, ...field});
          }

          return item;
        })
      );
    });
  };

  {/*TODO: пока скрываем от всех стоимость */}
  // const updatePrice = (price: number): void => {
  //   updateProduct({ itemPrice: price });
  // };

  const deleteProduct = (): void => {
    setAdditionalProducts((prev: AdditionalProductType[]) =>
      prev.filter((item: AdditionalProductType) => item.productId !== product.productId)
    );
  };

  const onSearchHandler = useMemo(() => {
    return debounce((value: string): void => {
      getProducts(value);
    }, 200);
  }, []);

  const getProducts = async (value: string = ""): Promise<void> => {
    const currentItemsIds: number[] = items
      ?.filter((item: OrderItemsType) => !item.deleted)
      ?.map((item: OrderItemsType) => item.product.id);

    const additionalItemsIds: number[] = additionalProducts?.map((i: AdditionalProductType) => i.productId);

    await ProductsAPI.getSupplierProducts({
      supplierId: userInfo?.supplier_id,
      name: value,
      isVerified: true,
      currency
    })
      .then((response) => {
        // Отфильтровываем товары, которые уже добавлены в заказ, в т.ч. только что добавленные
        // и преобразовываем под нужный формат
        const filteredProducts = response?.data?.records
          ?.filter((p) => !currentItemsIds.includes(p.id) && !additionalItemsIds.includes(p.id))
          ?.map(({ id, name, article, price, images, status }) => (
            { key: id, value: id, label: `${name} / Арт: ${article}`, price, images, status }
          ));

        setSupplierProducts(filteredProducts);
      })
      .catch(console.error);
  };

  const renderNoPicture: JSX.Element = useMemo(() => (
    <div className={`flex flex-col items-start tablet:mb-0 ${css.noPicture}`}>
      <NoPictureIcon />
    </div>
  ), []);

  return (
    <div className={css.wrap}>
      <p className="my-3 text-n1 ml-5">Новая позиция</p>
      <div className="flex justify-between items-center mb-4">
        <div className="flex">
          <div className="flex items-start">
            <div className={`text-t1 ${css.imageWrap}`}>
              {!!product?.images?.length ? (
                <div className={`flex flex-col items-center tablet:mb-0 ${css.image}`}>
                  <img src={`${API_BASE}${product.images?.[0]?.link}`} />
                </div>
                ) : renderNoPicture}
            </div>
          </div>
          <div className={css.productSelectWrap}>
            <Select
              style={{ width: "35rem" }}
              showSearch
              filterOption={false}
              notFoundContent={null}
              autoClearSearchValue={false}
              defaultActiveFirstOption={false}
              value={selectedProduct?.value ?? product?.label}
              placeholder="Выберите продукт"
              onChange={(value, option) => setSelectedProduct(option as OptionType)}
              onSearch={setSearchValue}
              options={supplierProducts}
            />
          </div>
        </div>
        <div className="flex flex-1 items-center">
          {/*TODO: пока скрываем от всех стоимость */}
          {/*<PriceInput*/}
          {/*  price={product?.itemPrice}*/}
          {/*  onChangeHandler={updatePrice}*/}
          {/*  isDisabled={isDisabledFields}*/}
          {/*/>*/}
          <div className={css.countWrap}>
            <Counter
              productId={1}
              min={1}
              max={999999}
              value={product.count}
              status={product.status}
              setValue={(value: number) => updateProduct({ count: value })}
              isOrderCard
              isDisabled={isDisabledFields}
            />
          </div>
          {/*TODO: пока скрываем от всех стоимость */}
          {/*<div className={css.cost}>{formatAmount(cost?.toString(), currency)}</div>*/}
          <ButtonDelete
            theme="secondary"
            className="ml-4"
            onClick={deleteProduct}
          />
        </div>
      </div>
    </div>
  );
};

export default NewOrderPosition;
