import React, { FC } from "react";
import { useDispatch } from "react-redux";

import Info from "./info";
import Labels from "./labels";
import Cart from "./cart";

import NoPictureIcon from "@/assets/icons/no-picture.inline.svg";

import cn from "classnames/bind";
import css from "./productCard.modules.scss";
import {
  addProduct,
  addProductInCartFailure,
  removeProduct,
  removeProductFromCartFailure,
  updateProduct, updateProductInCartFailure
} from "@/actions/cart.actions";
import {ProductImageType} from "app/types";

const cx = cn.bind(css);

interface ProductCardProps {
  productItem?: any;
  view?: "list" | "grid";
  className?: string;
  aosDuration?: string;
  aosOffset?: string;
  images?: ProductImageType[];
}

const ProductCard: FC<ProductCardProps> = ({
  view = "grid",
  productItem,
  className = "",
  aosDuration,
  aosOffset = "0",
  images,
}) => {
  const dispatch = useDispatch();

  const {
    id,
    info,
    isMsp,
    price,
    amount,
    product,
    category,
    organization,
    modifications,
    baseModification,
    supplierCompanyColor,
  } = productItem;
  const isList = view === "list";
  const hasModifications = modifications !== undefined;
  const hasModificationPrice = !!baseModification.prices.length;
  const createBasket = (productId: number, quantity: number) => {
    dispatch(addProduct(productId, quantity));
    dispatch(addProductInCartFailure());
  }

  const deleteBasket = (productId: number) => {
    dispatch(removeProduct(productId));
    dispatch(removeProductFromCartFailure());
  }
  const putBasket = (productId: number, quantity: number) => {
    dispatch(updateProduct(productId, quantity));
    dispatch(updateProductInCartFailure());
  }

  const getLinkUrl = () => {
    if (category) {
      return `/platform/catalog/${category}/${id}/`;
    }

    return `/platform/catalog/category/${id}/`;
  };

  const getDeliveryRules = () =>
    modifications[0].rests
      .filter(({ warehouse }) => warehouse.deliveryRules)
      .map(({ warehouse }) => warehouse.deliveryRules[0].rules[0]);

  return (
    <div
      data-aos="fade-up"
      data-aos-once="true"
      data-aos-offset={aosOffset}
      data-aos-duration={aosDuration}
      className={cx("bg-white flex border-none", "card", className, {
        card_list: isList,
      })}
    >
      <div
        className={cx("flex justify-center", "image", {
          image_list: isList,
          ["items-center"]: !images,
        })}
      >
        <a
          style={{ borderBottom: "none" }}
          href={`${getLinkUrl()}`}
          target="_blank"
          rel="noopener"
        >
          {!!images?.length
            ? images?.map(({ id, link }) => (
              <div key={id} className={css.icon}>
                <img src={link} />
              </div>
            )) :(
              <NoPictureIcon className={css.icon} />
            )}
        </a>
      </div>
      <div
        className={cx("description", {
          description_list: isList,
        })}
      >
        <div className={cx("top")}>
          <Labels isMsp={isMsp} supplierCompanyColor={supplierCompanyColor}>
            <p className={cx("text")}>{organization}</p>
          </Labels>
          <a href={`${getLinkUrl()}`} target="_blank" rel="noopener">
            <p className={cx("product", { product_list: isList })}>
              {product.substring(0, 48).trim()}
              {product.length > 48 && " ... "}
            </p>
          </a>
          {isList && <Info className="mt-4" info={info.slice(0, 4)} />}
        </div>
        <Cart
          price={price}
          amount={amount}
          isList={isList}
          putBasket={putBasket}
          createBasket={createBasket}
          deleteBasket={deleteBasket}
          hasModifications={hasModifications}
          hasModificationPrice={hasModificationPrice}
          className={cx("cart", { cart_list: isList })}
          countInUsercart={baseModification.countInUsercart}
          deliveryRules={hasModifications && getDeliveryRules()[0]}
        />
      </div>
    </div>
  );
};

export default ProductCard;
