
import React, { FC, useMemo, useState, Dispatch, useEffect, } from "react";
import { useSelector } from "react-redux";

import CatalogSubMenu from "./catalogSubMenu";
import TickRightIcon from "@/assets/icons/tick-right.inline.svg";

import cn from "classnames/bind";
import css from "./index.modules.scss";

import { AppStateType } from "@/reducers";
import { CategoriesTpType } from "app/types";

type CatalogPopupPropsType = {
  onClose?: () => void;
  activeItem?: any;
  setActiveItem?: Dispatch<any>;
}

export const CatalogPopup: FC<CatalogPopupPropsType> = ({
  onClose,
  activeItem,
  setActiveItem,
}) => {
  const { tpCategories } = useSelector((state: AppStateType) => state.categories);

  const [currentChildren, setCurrentChildren] = useState<CategoriesTpType[]>(null);

  useEffect(() => {
    setCurrentChildren(
      tpCategories?.find((category: CategoriesTpType) => category.id === activeItem)?.children
    );
  }, [activeItem]);

  const renderCategories = useMemo(() => {
    return (
      <ul className={`flex flex-col ${css.popup__list}`}>
        {tpCategories?.map(({ id, name }: CategoriesTpType, index: number) => (
          <li
            key={`category_${index}`}
            className={`flex justify-between items-center cursor-pointer pr-3 ${css.list__element}`}
            onClick={() => setActiveItem(id)}
          >
            <p
              className={cn(`relative ${css.popup__listItemText}`, {
                [css.popup__listItemText_active]: activeItem === id,
              })}
            >{name}</p>
            {activeItem === id && (
              <div className="flex justify-center items-center">
                <TickRightIcon className={`${css.popup__arrow}`} />
              </div>
            )}
          </li>
        ))}
      </ul>
    );
  }, [tpCategories, activeItem, setActiveItem])

  return (
    <div className={`bg-white absolute z-50 flex justify-between p-1 mt-3 w-full ${css.popup}`}>
      <div className="flex overflow-auto">
        {renderCategories}
      </div>
      <div className="flex overflow-auto">
        <CatalogSubMenu
          id={activeItem}
          childrens={currentChildren}
          activeItem={activeItem}
          onClose={onClose}
        />
      </div>
    </div>
  );
};

export default CatalogPopup;