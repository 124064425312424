import React, { FC, useEffect, useState } from "react";
import { RcFile } from "antd/es/upload";
import { createOrderDocument } from "@/api/orders.api";
import { useSelector } from "react-redux";
import { AppStateType } from "@/reducers";
import { roleResolver } from "@/utils/roleResolver";

import Button from "@/components/ui-kit/button";
import { Modal, Select, Upload } from "antd";

import css from "./style.modules.scss";

interface IUploadFilesModalProps {
  orderId: number;
  isOpen: boolean;
  onClose: () => void;
  fetchDocuments: () => void;
}

type AcceptedFileType = {
  extensions: string[];
  maxItemSize: number;
};

export type FileTypesValue = "commercial_offer" | "commercial_offer_disclosure" | "other";

const fileTypes: Record<string, string>[] = [
  { value: "commercial_offer", label: "Коммерческое предложение" },
  { value: "commercial_offer_disclosure", label: "Раскрытие коммерческого предложения" },
  { value: "other", label: "Другой документ" },
];

const extensions: string[] = [".doc", ".docx", ".xls", ".xlsx", ".pdf", ".png", ".jpg", ".jpeg"];

const acceptedFileType: AcceptedFileType = {
  // для каждого формата создается его версия в верхнем регистре
  extensions: extensions.flatMap((extension: string) => [extension, extension.toUpperCase()]),
  maxItemSize: 10 * 1024 * 1024,
};

const UploadFilesModal: FC<IUploadFilesModalProps> = ({
  orderId,
  isOpen,
  onClose,
  fetchDocuments,
}): JSX.Element => {
  const { userInfo } = useSelector((state: AppStateType) => state.account);
  const { isPurchaser } = roleResolver(userInfo?.roles);

  const [selectedFileType, setSelectedFileType] = useState<FileTypesValue | null>(null);
  const [documentFile, setDocumentFile] = useState<RcFile | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    isPurchaser && setSelectedFileType("other");
  }, [userInfo]);

  useEffect(() => {
    setErrorMessage(null);
  }, [documentFile]);

  const uploadDocument = (): void => {
    createOrderDocument(documentFile, orderId, selectedFileType)
      .then(() => fetchDocuments())
      .then(() => {
        setDocumentFile(null);
        onClose();
      })
      .catch((error: Error) => {
        setErrorMessage("Произошла непредвиденная ошибка. Пожалуйста, попробуйте позже");

        console.error(error);
      });
  };

  const validateFileSize = (file: RcFile, fileType: string): boolean => {
    const isValidFilesSize: boolean = acceptedFileType && file?.size <= (acceptedFileType.maxItemSize);

    const isValid: boolean = fileType && isValidFilesSize;

    if (!isValidFilesSize) {
      setErrorMessage(`Размер файла не должен превышать ${acceptedFileType.maxItemSize / 1024 / 1024}Мб`);
    }

    return isValid;
  };

  const validateFileExtension = (file: RcFile): { isValidExtension: boolean, fileType: string } => {
    const ext: string = file?.name?.split(".")?.pop()?.toLowerCase();
    const fileType: string = acceptedFileType.extensions.find((fileExt) => fileExt.includes(ext));
    const accept: string = acceptedFileType.extensions.join(", ");

    if (!fileType) {
      setErrorMessage(`Неверный формат файла. Допустимые форматы: ${accept}`);

      return {
        isValidExtension: false,
        fileType,
      };
    }

    return {
      isValidExtension: true,
      fileType,
    };
  };

  const handleFileValidate = (file: RcFile): boolean => {
    const { isValidExtension, fileType } = validateFileExtension(file);

    return isValidExtension && validateFileSize(file, fileType);
  };

  const handleBeforeUpload = (file: RcFile): boolean => {
    const isValid: boolean = handleFileValidate(file);

    isValid && setDocumentFile(file);

    return false;
  };

  const handleOnRemove = (): void => setDocumentFile(null);

  const handleOnSelect = (value: FileTypesValue): void => setSelectedFileType(value);

  const Dragger: JSX.Element = (
    <Upload.Dragger
      maxCount={1}
      onRemove={handleOnRemove}
      accept={acceptedFileType.extensions.join(", ")}
      fileList={documentFile ? [documentFile] : []}
      beforeUpload={handleBeforeUpload}
    >
      <div className="flex flex-col items-center p-3 text-black">
        <p className="text-black">Переместите сюда или выберите файл формата:</p>
        <p>DOC, DOCX, XLS, XLSX, PDF, PNG, JPG</p>
        <span className={`text-blue-grey ${css.fileSizeDesc}`}>Объём файла не должен превышать 10 мб</span>
        <Button theme="greyOutline" isUppercase={false}>Выбрать файл</Button>
      </div>
    </Upload.Dragger>
  );

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      closable
      footer={null}
    >
      <div>
        <p className={css.title}>Загрузка документа</p>
        <Select
          size="large"
          className={css.select}
          value={selectedFileType}
          onChange={handleOnSelect}
          options={fileTypes}
          disabled={isPurchaser}
          placeholder="Выберите тип загружаемого документа"
        />
        {Dragger}
        <span className="text-sm text-error">{errorMessage}</span>
        <div className={`flex items-center justify-end gap-2 ${css.controls}`}>
          <Button theme="greyOutline" onClick={onClose}>Отменить</Button>
          <Button
            theme="black"
            disabled={!documentFile || !selectedFileType}
            onClick={uploadDocument}
          >
            Подтвердить
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UploadFilesModal;
