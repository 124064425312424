import React, {
  FC,
  Dispatch,
  useState,
  useEffect,
  SetStateAction,
} from "react";
import * as ProductsAPI from "@/api/products.api";
import { AppStateType } from "@/reducers";
import { useDispatch, useSelector } from "react-redux";
import { ProductItemType } from "app/types";
import { getProductsList } from "@/actions/products.actions";
import ProductCardProvider from "@/components/ProductCardProvider";
import { CatalogVerificationTabs } from "./CatalogVerificationTabs/CatalogVerificationTabs";
import {
  DeclineReasonModal
} from "@/components/pages/platform/pages/Catalog/CatalogVerificationPage/CatalogVerificationList/DeclineReasonModal/DeclineReasonModal";
import Checkbox from "@/components/ui-kit/checkox";
import Preloader from "@/components/ui-kit/preloader";
import {
  Button,
  Pagination,
  PaginationProps,
} from "antd";
import { FloatButton } from 'antd';
import css from "../../Catalog.modules.scss";

interface ICatalogVerificationProps {
  currentPage: number;
  supplierId: number;
  productName: string;
  productArticle: string;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  currentActiveStatus: string;
  setCurrentActiveStatus: Dispatch<SetStateAction<string>>;
}

export const CatalogVerificationList:FC<ICatalogVerificationProps> = ({
  currentPage,
  supplierId,
  productName,
  productArticle,
  setCurrentPage,
  currentActiveStatus,
  setCurrentActiveStatus,
}) => {
  const dispatch = useDispatch();

  const {
    isFetching,
    productsList,
    productsListPages,
  } = useSelector((state: AppStateType) => state.products);

  const [isOpenDeclineReasonModal, setIsOpenDeclineReasonModal] = useState<boolean>(false);
  const [declineReasonText, setDeclineReasonText] = useState<string>("");
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);

  useEffect(() => {
    setSelectedProducts([]);
    setIsChecked(false);
  }, [currentActiveStatus]);

  const paginationChange: PaginationProps['onChange'] = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleCancel = (): void => {
    setIsOpenDeclineReasonModal(false);
  };

  const isProductCheckboxChecked = (id: number): boolean => selectedProducts?.includes(id);

  const handleProductCheckboxClick = (id: number): void => {
    if (isProductCheckboxChecked(id)) {
      setSelectedProducts(selectedProducts?.filter((productId: number) => productId !== id));
    } else {
      setSelectedProducts([...selectedProducts, id]);
    }
  };

  const toggleSelectAllProducts = (e: any): void => {
    const isChecked: boolean = e.target.checked;

    setIsChecked(isChecked);
    setSelectedProducts(isChecked ? productsList?.map(({id}) => id) : []);
  };

  const updateBulkSelectedProducts = (isVerified: boolean): void => {
    const statusForFilter: string = isVerified ? "declined" : "placed";

    const productsForUpdate: ProductItemType[] = productsList.filter(({id, status}) => {
      return selectedProducts.includes(id) && (status === "checking" || statusForFilter);
    }).map(({id}) => {
      return ({
        id,
        isVerified: isVerified,
        ...(!isVerified ? { declineReason: declineReasonText.trim() } : {})
      });
    });

    ProductsAPI.updateProduct(productsForUpdate)
      .then(() => {
        resetCheckboxes();
        !isVerified && setIsOpenDeclineReasonModal(false);
        !isVerified && setDeclineReasonText("");
        fetchProductsList();
      })
  };

  const fetchProductsList = (): void => {
    dispatch(getProductsList({
      page: currentPage,
      pageSize: 25,
      status: currentActiveStatus
    }));
  };

  const resetCheckboxes = (): void => {
    setIsChecked(false);
    setSelectedProducts([]);
  };

  return (
    <div>
      <CatalogVerificationTabs
        supplierId={supplierId}
        productName={productName}
        productArticle={productArticle}
        setCurrentActiveStatus={setCurrentActiveStatus}
      />
      {!!productsList?.length && !isFetching && (
        <>
          <div className="flex items-baseline ml-8 mb-5">
            <div className="flex">
              <Checkbox
                containerClassName="mr-5"
                label="Выбрать все"
                checked={isChecked || selectedProducts?.length === productsList?.length}
                onChange={toggleSelectAllProducts}
              />
              <Button
                type="primary"
                className="mr-3"
                disabled={!selectedProducts?.length}
                onClick={() => updateBulkSelectedProducts(true)}
              >
                Подтвердить все
              </Button>
              <Button
                disabled={!selectedProducts?.length}
                onClick={() => setIsOpenDeclineReasonModal(true)}
              >
                Отклонить все
              </Button>
            </div>
            {isOpenDeclineReasonModal && (
              <DeclineReasonModal
                handleCancel={handleCancel}
                declineReasonText={declineReasonText}
                handleApply={() => updateBulkSelectedProducts(false)}
                setDeclineReasonText={setDeclineReasonText}
                isOpen={isOpenDeclineReasonModal}
              />
            )}
          </div>
          {productsList?.map(({
            id,
            name,
            price,
            status,
            article,
            images,
            category,
            declineReason,
            tpCategoryName,
            currencyCharCode,
          }, index: number) => (
            <ProductCardProvider
              className="mb-1"
              id={id}
              key={id}
              name={name}
              price={+price}
              images={images}
              status={status}
              article={article}
              currency={currencyCharCode}
              category={category}
              isChecked={isProductCheckboxChecked(id)}
              aosDuration={`${index + 5}00`}
              declineReason={declineReason}
              tpCategoryName={tpCategoryName}
              resetCheckboxes={resetCheckboxes}
              handleCheckboxClick={handleProductCheckboxClick}
              fetchProductsList={fetchProductsList}
            />
          ))}
          <div className="text-center mt-3">
            <Pagination
              total={productsListPages * 10}
              onChange={paginationChange}
              defaultCurrent={currentPage}
              showSizeChanger={false}
              hideOnSinglePage
            />
          </div>
        </>
      )}
      {isFetching && (
        <div className="flex justify-center">
          <Preloader />
        </div>
      )}
      <FloatButton.BackTop />
    </div>
  );
};
