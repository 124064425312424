import axios from "@/utils/axios";
import {UploadFileType} from "app/types";

const API_BASE: string = process.env.GATSBY_API_BASE?.trimEnd();

export const importCategoriesFile = async (file: File|UploadFileType) => {
  return axios.POST_FOR_IMPORT(API_BASE, "/api/iss/v1/categories/import", {
    file: file,
  });
};

export const getTpCategories = async (maxLevel?: number, category_id?: number) => {
  return axios.GET(API_BASE, "/api/iss/v1/tp-categories", { maxLevel, category_id });
};
