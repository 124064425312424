import {
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAILURE,
  GET_ORDERS_LIST_REQUEST,
  GET_ORDERS_LIST_SUCCESS,
  GET_ORDERS_LIST_FAILURE,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAILURE,
  EDIT_ORDER_SUCCESS,
  EDIT_ORDER_FAILURE,
  EDIT_ORDER_POSITION_SUCCESS,
  EDIT_ORDER_POSITION_FAILURE,
  DELETE_ORDER_POSITION_SUCCESS,
  DELETE_ORDER_POSITION_FAILURE,
  APPROVE_ORDER_STATUS_SUCCESS,
  APPROVE_ORDER_STATUS_FAILURE,
  UPDATE_ORDER_STATUS_SUCCESS,
  UPDATE_ORDER_STATUS_FAILURE,
  GET_ORDER_ADDRESSES_SUCCESS,
  GET_ORDER_ADDRESSES_FAILURE,
  SAVE_ORDERS_SERVICE_SETTINGS_SUCCESS,
  SAVE_ORDERS_SERVICE_SETTINGS_FAILURE,
  GET_ORDERS_SERVICE_SETTINGS_SUCCESS,
  GET_ORDERS_SERVICE_SETTINGS_FAILURE,
  GET_ORDER_STATUS_SUCCESS,
  GET_ORDER_STATUS_FAILURE,
  UPDATE_ORDER_ENTITY_SUCCESS,
  UPDATE_ORDER_ENTITY_FAILURE,
  GET_ORDER_DOCUMENTS_REQUEST,
  GET_ORDER_DOCUMENTS_SUCCESS,
  GET_ORDER_DOCUMENTS_FAILURE,
  CREATE_ORDER_DOCUMENT_REQUEST,
  CREATE_ORDER_DOCUMENT_SUCCESS,
  CREATE_ORDER_DOCUMENT_FAILURE,
} from "@/constants/actionTypes";
import { ThunkAction } from "redux-thunk";
import { AppStateType } from "@/reducers";
import {
  OrderListType,
  OrdersServiceSettingsDataType,
  OrderType,
  UpdateOrderEntityParamsType,
  IDocument,
  UploadFileType,
  IRequestOrderListFilters,
} from "app/types";
import * as OrdersAPI from "@/api/orders.api";

export type OrdersActionsTypes =
  | CreateOrderSuccessType
  | CreateOrderFailureType
  | GetOrdersListRequestType
  | GetOrdersListSuccessType
  | GetOrdersListFailureType
  | GetOrderSuccessType
  | GetOrderFailureType
  | EditOrderSuccessType
  | EditOrderFailureType
  | EditOrderPositionSuccessType
  | EditOrderPositionFailureType
  | DeleteOrderPositionSuccessType
  | DeleteOrderPositionFailureType
  | ApproveOrderStatusSuccessType
  | ApproveOrderStatusFailureType
  | UpdateOrderStatusSuccessType
  | UpdateOrderStatusFailureType
  | GetOrderAddressesSuccessType
  | GetOrderAddressesFailureType
  | SaveOrderServiceSettingsSuccessType
  | SaveOrderServiceSettingsFailureType
  | GetOrderServiceSettingsSuccessType
  | GetOrderServiceSettingsFailureType
  | GetOrderStatusSuccessType
  | GetOrderStatusFailureType
  | UpdateOrderEntitySuccessType
  | UpdateOrderEntityFailureType
  | GetOrderDocumentsRequestType
  | GetOrderDocumentsSuccessType
  | GetOrderDocumentsFailureType
  | CreateOrderDocumentRequestType
  | CreateOrderDocumentSuccessType
  | CreateOrderDocumentFailureType
  ;

type CreateOrderSuccessType = {
  type: typeof CREATE_ORDER_SUCCESS;
  payload: {
    orderIds: number[];
  };
};

export const createOrderSuccess = (
  orderIds: number[]
): CreateOrderSuccessType => ({
  type: CREATE_ORDER_SUCCESS,
  payload: {
    orderIds,
  },
});

type CreateOrderFailureType = {
  type: typeof CREATE_ORDER_FAILURE;
  payload: {
    error: string;
  };
};

export const createOrderFailure = (
  error?: string
): CreateOrderFailureType => ({
  type: CREATE_ORDER_FAILURE,
  payload: {
    error,
  },
});

export const createNewOrder = (
  deliveryType: string,
  deliveryAddress: string,
  paymentType: string,
  deliveryDate: string,
  comment: string,
  commercialOfferType: string,
):ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  OrdersActionsTypes
  > => async (dispatch) => {
  try {
    const response = await OrdersAPI.createOrder(
      deliveryType,
      deliveryAddress,
      paymentType,
      deliveryDate,
      comment,
      commercialOfferType
    );

    dispatch(createOrderSuccess(response?.data
      ?.map((order: {[orderId: string]: number}) => order.orderId)));
  } catch (err) {
    dispatch(createOrderFailure(err?.response?.data?.detail));
  }
};

type GetOrdersListRequestType = {
  type: typeof GET_ORDERS_LIST_REQUEST;
};

export const getOrdersListRequest = (): GetOrdersListRequestType => ({
  type: GET_ORDERS_LIST_REQUEST
});

type GetOrdersListSuccessType = {
  type: typeof GET_ORDERS_LIST_SUCCESS;
  payload: {
    orders: OrderListType;
  };
};

export const getOrdersListSuccess = (
  orders: OrderListType
): GetOrdersListSuccessType => ({
  type: GET_ORDERS_LIST_SUCCESS,
  payload: {
    orders: orders,
  },
});

type GetOrdersListFailureType = {
  type: typeof GET_ORDERS_LIST_FAILURE;
  payload: {
    error: string;
  };
};

export const getOrdersListFailure = (
  error?: string
): GetOrdersListFailureType => ({
  type: GET_ORDERS_LIST_FAILURE,
  payload: {
    error,
  },
});

export const getOrdersList = (
  filters: IRequestOrderListFilters
):ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  OrdersActionsTypes
  > => async (dispatch) => {
  dispatch(getOrdersListRequest());

  try {
    const response = await OrdersAPI.getOrders(filters);

    dispatch(getOrdersListSuccess(response.data));
  } catch (err) {
    dispatch(getOrdersListFailure(err.response?.data?.detail));
  }
};

type GetOrderStatusSuccessType = {
  type: typeof GET_ORDER_STATUS_SUCCESS;
  payload: {
    getOrderStatus: number;
  };
};

export const getOrderStatusSuccess = (
  getOrderStatus: number
): GetOrderStatusSuccessType => ({
  type: GET_ORDER_STATUS_SUCCESS,
  payload: {
    getOrderStatus: getOrderStatus,
  },
});

type GetOrderSuccessType = {
  type: typeof GET_ORDER_SUCCESS;
  payload: {
    order: OrderType;
  };
};

export const getOrderSuccess = (
  order: OrderType
): GetOrderSuccessType => ({
  type: GET_ORDER_SUCCESS,
  payload: {
    order: order,
  },
});


type GetOrderStatusFailureType = {
  type: typeof GET_ORDER_STATUS_FAILURE;
  payload: {
    getOrderStatus: number;
  };
};

export const getOrderStatusFailure = (
  getOrderStatus: number
): GetOrderStatusFailureType => ({
  type: GET_ORDER_STATUS_FAILURE,
  payload: {
    getOrderStatus: getOrderStatus,
  },
});

type GetOrderFailureType = {
  type: typeof GET_ORDER_FAILURE;
  payload: {
    error: string;
  };
};

export const getOrderFailure = (
  error?: string
): GetOrderFailureType => ({
  type: GET_ORDER_FAILURE,
  payload: {
    error,
  },
});

export const getOrderInfo = (orderId: number)
  :ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  OrdersActionsTypes
  > => async (dispatch) => {
  try {
    const response = await OrdersAPI.getOrder(orderId);

    dispatch(getOrderSuccess(response.data));
    dispatch(getOrderStatusSuccess(response.status));
  } catch (err) {
    dispatch(getOrderFailure(err.response?.data?.detail));
    dispatch(getOrderStatusFailure(err.status));
  }
};

type EditOrderSuccessType = {
  type: typeof EDIT_ORDER_SUCCESS;
  payload: {
    editOrderStatus: number;
  };
};

export const editOrderSuccess = (
  editOrderStatus: number
): EditOrderSuccessType => ({
  type: EDIT_ORDER_SUCCESS,
  payload: {
    editOrderStatus: editOrderStatus,
  },
});

type EditOrderFailureType = {
  type: typeof EDIT_ORDER_FAILURE;
  payload: {
    error: string;
  };
};

export const editOrderFailure = (
  error?: string
): EditOrderFailureType => ({
  type: EDIT_ORDER_FAILURE,
  payload: {
    error,
  },
});

export const editOrderInfo = (
 orderId: number,
 delivery_type: string,
 delivery_address: string,
 payment_type: string,
 delivery_date: string,
 comment: string,
):ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  OrdersActionsTypes
  > => async (dispatch) => {
  try {
    const response = await OrdersAPI.editOrder(
      orderId,
      delivery_type,
      delivery_address,
      payment_type,
      delivery_date,
      comment,
    );

    dispatch(editOrderSuccess(response.data.status));
  } catch (err) {
    dispatch(editOrderFailure(err.response.data.detail));
  }
};

// редактирование заказа в карточке Заказа
type UpdateOrderEntitySuccessType = {
  type: typeof UPDATE_ORDER_ENTITY_SUCCESS;
  payload: {
    order: OrderType;
  };
};

export const updateOrderEntitySuccess = (
  order: OrderType
): UpdateOrderEntitySuccessType => ({
  type: UPDATE_ORDER_ENTITY_SUCCESS,
  payload: {
    order,
  },
});

type UpdateOrderEntityFailureType = {
  type: typeof UPDATE_ORDER_ENTITY_FAILURE;
  payload: {
    error: string;
  };
};

export const updateOrderEntityFailure = (
  error?: string
): UpdateOrderEntityFailureType => ({
  type: UPDATE_ORDER_ENTITY_FAILURE,
  payload: {
    error,
  },
});

export const updateOrder = (
  params: UpdateOrderEntityParamsType
):ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  OrdersActionsTypes
> => async (dispatch) => {
  try {
    const response = await OrdersAPI.updateOrderEntity(params);

    dispatch(updateOrderEntitySuccess(response.data));
  } catch (err) {
    dispatch(updateOrderEntityFailure(err.response.data.detail));
  }
};

type EditOrderPositionSuccessType = {
  type: typeof EDIT_ORDER_POSITION_SUCCESS;
  payload: {
    editOrderPositionStatus: number;
  };
};

export const editOrderPositionSuccess = (
  editOrderPositionStatus: number
): EditOrderPositionSuccessType => ({
  type: EDIT_ORDER_POSITION_SUCCESS,
  payload: {
    editOrderPositionStatus: editOrderPositionStatus,
  },
});

type EditOrderPositionFailureType = {
  type: typeof EDIT_ORDER_POSITION_FAILURE;
  payload: {
    error: string;
  };
};

export const editOrderPositionFailure = (
  error?: string
): EditOrderPositionFailureType => ({
  type: EDIT_ORDER_POSITION_FAILURE,
  payload: {
    error,
  },
});

export const editOrderQuantityInfo = (
 orderId: number,
 positionId: number,
 quantity: number,
):ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  OrdersActionsTypes
  > => async (dispatch) => {
  try {
    const response = await OrdersAPI.editOrderQuantity(
      orderId,
      positionId,
      quantity,
    );

    dispatch(editOrderPositionSuccess(response.data.status));
  } catch (err) {
    dispatch(editOrderPositionFailure(err.response.data.detail));
  }
};

type DeleteOrderPositionSuccessType = {
  type: typeof DELETE_ORDER_POSITION_SUCCESS;
  payload: {
    deleteOrderPositionStatus: number;
  };
};

export const deleteOrderPositionSuccess = (
  deleteOrderPositionStatus: number
): DeleteOrderPositionSuccessType => ({
  type: DELETE_ORDER_POSITION_SUCCESS,
  payload: {
    deleteOrderPositionStatus: deleteOrderPositionStatus,
  },
});

type DeleteOrderPositionFailureType = {
  type: typeof DELETE_ORDER_POSITION_FAILURE;
  payload: {
    error: string;
  };
};

export const deleteOrderPositionFailure = (
  error?: string
): DeleteOrderPositionFailureType => ({
  type: DELETE_ORDER_POSITION_FAILURE,
  payload: {
    error,
  },
});

export const deleteOrderQuantityInfo = (
 orderId: number,
 positionId: number,
 quantity?: number,
):ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  OrdersActionsTypes
  > => async (dispatch) => {
  try {
    const response = await OrdersAPI.deleteOrderQuantity(
      orderId,
      positionId,
      quantity,
    );

    dispatch(deleteOrderPositionSuccess(response.data.status));
  } catch (err) {
    dispatch(deleteOrderPositionFailure(err.response.data.detail));
  }
};

// Согласование заказа: approved: true - Согласован, approved: false - Заказ отменен.
type ApproveOrderStatusSuccessType = {
  type: typeof APPROVE_ORDER_STATUS_SUCCESS;
  payload: {
    status: number;
  };
};

export const approveOrderStatusSuccess = (
  status: number
): ApproveOrderStatusSuccessType => ({
  type: APPROVE_ORDER_STATUS_SUCCESS,
  payload: {
    status,
  },
});

type ApproveOrderStatusFailureType = {
  type: typeof APPROVE_ORDER_STATUS_FAILURE;
  payload: {
    error: string;
  };
};

export const approveOrderStatusFailure = (
  error?: string
): ApproveOrderStatusFailureType => ({
  type: APPROVE_ORDER_STATUS_FAILURE,
  payload: {
    error,
  },
});

export const approveOrderStatusInfo = (orderId: number, approved: boolean):
  ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    OrdersActionsTypes
  > => async (dispatch) => {
  try {
    const response = await OrdersAPI.approveOrderStatus(orderId, approved);

    dispatch(approveOrderStatusSuccess(response.status));
  } catch (err) {
    dispatch(approveOrderStatusFailure(err.response?.data.detail));
  }
};

// Перевод статуса заказа. Доступен перевод только поставщику из статуса "Новый" в статус "В обработке".
// Руками статус не передаем, бэк сам проверяет
type UpdateOrderStatusSuccessType = {
  type: typeof UPDATE_ORDER_STATUS_SUCCESS;
  payload: {
    status: number;
  };
};

export const updateOrderStatusSuccess = (
  status: number
): UpdateOrderStatusSuccessType => ({
  type: UPDATE_ORDER_STATUS_SUCCESS,
  payload: {
    status,
  },
});

type UpdateOrderStatusFailureType = {
  type: typeof UPDATE_ORDER_STATUS_FAILURE;
  payload: {
    error: string;
  };
};

export const updateOrderStatusFailure = (
  error?: string
): UpdateOrderStatusFailureType => ({
  type: UPDATE_ORDER_STATUS_FAILURE,
  payload: {
    error,
  },
});

export const updateOrderStatusInfo = (orderId: number):
  ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    OrdersActionsTypes
  > => async (dispatch) => {
  try {
    const response = await OrdersAPI.updateOrderStatus(orderId);

    dispatch(updateOrderStatusSuccess(response?.status));
  } catch (err) {
    dispatch(updateOrderStatusFailure(err?.response?.data?.detail));
  }
};

type GetOrderAddressesSuccessType = {
  type: typeof GET_ORDER_ADDRESSES_SUCCESS;
  payload: {
    orderAddresses: string[];
  };
};

export const getOrderAddressesSuccess = (
  orderAddresses: string[]
): GetOrderAddressesSuccessType => ({
  type: GET_ORDER_ADDRESSES_SUCCESS,
  payload: {
    orderAddresses: orderAddresses,
  },
});

type GetOrderAddressesFailureType = {
  type: typeof GET_ORDER_ADDRESSES_FAILURE;
  payload: {
    error: string;
  };
};

export const getOrderAddressesFailure = (
  error?: string
): GetOrderAddressesFailureType => ({
  type: GET_ORDER_ADDRESSES_FAILURE,
  payload: {
    error,
  },
});

export const getOrderAddressesInfo = (page?: number, pageSize?: number)
  :ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  OrdersActionsTypes
  > => async (dispatch) => {
  try {
    const response = await OrdersAPI.getOrderAddresses(page, pageSize);

    dispatch(getOrderAddressesSuccess(response.data.records));
  } catch (err) {
    dispatch(getOrderAddressesFailure(err.response.data.detail));
  }
};

type SaveOrderServiceSettingsSuccessType = {
  type: typeof SAVE_ORDERS_SERVICE_SETTINGS_SUCCESS;
  payload: {
    saveOrderServiceSettingsStatus: number;
  };
};

export const saveOrderServiceSettingsSuccess = (
  saveOrderServiceSettingsStatus: number
): SaveOrderServiceSettingsSuccessType => ({
  type: SAVE_ORDERS_SERVICE_SETTINGS_SUCCESS,
  payload: {
    saveOrderServiceSettingsStatus: saveOrderServiceSettingsStatus,
  },
});

type SaveOrderServiceSettingsFailureType = {
  type: typeof SAVE_ORDERS_SERVICE_SETTINGS_FAILURE;
  payload: {
    error: string;
  };
};

export const saveOrderServiceSettingsFailure = (
  error?: string
): SaveOrderServiceSettingsFailureType => ({
  type: SAVE_ORDERS_SERVICE_SETTINGS_FAILURE,
  payload: {
    error,
  },
});

export const saveOrderServiceSettingsData = (
  sendOrdersToTpPeriod: number,
  updateOrdersFromTpPeriod: number,
  sendOrderStatusToTpPeriod: number,
  autocancelOrdersPeriod: number,
  notifyOrderExpiredAcceptanceDays: number,
):ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  OrdersActionsTypes
  > => async (dispatch) => {
  try {
    const response = await OrdersAPI.saveOrderServiceSettings(
      sendOrdersToTpPeriod,
      updateOrdersFromTpPeriod,
      sendOrderStatusToTpPeriod,
      autocancelOrdersPeriod,
      notifyOrderExpiredAcceptanceDays,
    );

    dispatch(saveOrderServiceSettingsSuccess(response.status));
  } catch (err) {
    dispatch(saveOrderServiceSettingsFailure(err.response.data.detail));
  }
};

type GetOrderServiceSettingsSuccessType = {
  type: typeof GET_ORDERS_SERVICE_SETTINGS_SUCCESS;
  payload: {
    orderServiceSettingsData: OrdersServiceSettingsDataType;
  };
};

export const getOrderServiceSettingsSuccess = (
  orderServiceSettingsData: OrdersServiceSettingsDataType
): GetOrderServiceSettingsSuccessType => ({
  type: GET_ORDERS_SERVICE_SETTINGS_SUCCESS,
  payload: {
    orderServiceSettingsData: orderServiceSettingsData,
  },
});

type GetOrderServiceSettingsFailureType = {
  type: typeof GET_ORDERS_SERVICE_SETTINGS_FAILURE;
  payload: {
    error: string;
  };
};

export const getOrderServiceSettingsFailure = (
  error?: string
): GetOrderServiceSettingsFailureType => ({
  type: GET_ORDERS_SERVICE_SETTINGS_FAILURE,
  payload: {
    error,
  },
});

export const getOrderServiceSettingsData = ():
  ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  OrdersActionsTypes
  > => async (dispatch) => {
  try {
    const response = await OrdersAPI.getOrderServiceSettings();

    dispatch(getOrderServiceSettingsSuccess(response.data));
  } catch (err) {
    dispatch(getOrderServiceSettingsFailure(err.response.data.detail));
  }
};

// Получение документов
type GetOrderDocumentsRequestType = {
  type: typeof GET_ORDER_DOCUMENTS_REQUEST;
};

export const getOrderDocumentsRequest = (): GetOrderDocumentsRequestType => ({
  type: GET_ORDER_DOCUMENTS_REQUEST,
});

type GetOrderDocumentsSuccessType = {
  type: typeof GET_ORDER_DOCUMENTS_SUCCESS;
  payload: {
    orderDocumentsData: IDocument[];
  };
};

export const getOrderDocumentsSuccess = (
  orderDocumentsData: IDocument[],
): GetOrderDocumentsSuccessType => ({
  type: GET_ORDER_DOCUMENTS_SUCCESS,
  payload: {
    orderDocumentsData: orderDocumentsData,
  },
});

type GetOrderDocumentsFailureType = {
  type: typeof GET_ORDER_DOCUMENTS_FAILURE;
  payload: {
    error: any;
  };
};

export const getOrderDocumentsFailure = (
  error?: any
): GetOrderDocumentsFailureType => ({
  type: GET_ORDER_DOCUMENTS_FAILURE,
  payload: {
    error,
  },
});

export const getOrderDocumentsData = (
  orderId: number
): ThunkAction<
    Promise<Promise<any>>,
    AppStateType,
    undefined,
    OrdersActionsTypes
  > => async (dispatch) => {
    dispatch(getOrderDocumentsRequest);

    try {
      const response = await OrdersAPI.getOrderDocuments(orderId);

      dispatch(getOrderDocumentsSuccess(response.data));

      return Promise.resolve(response.data);
    } catch (err) {
      dispatch(getOrderDocumentsFailure(err));

      return Promise.reject(err);
    }
  };

// Создание документа
type CreateOrderDocumentRequestType = {
  type: typeof CREATE_ORDER_DOCUMENT_REQUEST;
  payload: {
    orderId: number,
    file: File | UploadFileType,
  };
};

export const createOrderDocumentRequest = (
  orderId: number,
  file: File | UploadFileType,
): CreateOrderDocumentRequestType => ({
  type: CREATE_ORDER_DOCUMENT_REQUEST,
  payload: {
    orderId,
    file
  }
});

type CreateOrderDocumentSuccessType = {
  type: typeof CREATE_ORDER_DOCUMENT_SUCCESS;
  payload: {
    response: string;
  };
};

export const createOrderDocumentSuccess = (
  response: string,
): CreateOrderDocumentSuccessType => ({
  type: CREATE_ORDER_DOCUMENT_SUCCESS,
  payload: {
    response,
  },
});

type CreateOrderDocumentFailureType = {
  type: typeof CREATE_ORDER_DOCUMENT_FAILURE;
  payload: {
    error: string;
  };
};

export const createOrderDocumentFailure = (
  error?: string
): CreateOrderDocumentFailureType => ({
  type: CREATE_ORDER_DOCUMENT_FAILURE,
  payload: {
    error,
  },
});

export const createOrderDocumentData = (file: File | UploadFileType, orderId: number):
  ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    OrdersActionsTypes
  > => async (dispatch) => {
    try {
      const response = await OrdersAPI.createOrderDocument(file, orderId);

      dispatch(createOrderDocumentSuccess(response.data));

      return Promise.resolve(response.data);
    } catch (err) {
      dispatch(createOrderDocumentFailure(err.response));

      return Promise.reject(err);
    }
  };
