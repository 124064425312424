import React, { FC } from "react";

import cn from "classnames";

import QuestionIcon from "@/assets/icons/question-for-circle.inline.svg";

import css from "./questionInCircle.modules.scss";

type QuestionInCircleIconType = {
  className?: string;
  isHover?: boolean;
};

const QuestionInCircleIcon: FC<QuestionInCircleIconType> = ({
  className = "",
  isHover = false,
}) => (
  <div
    className={cn(`${css.container} ${className}`, {
      [css.container__hover]: isHover,
    })}
  >
    <QuestionIcon />
  </div>
);

export default QuestionInCircleIcon;
