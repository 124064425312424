const range = require("lodash/range");

const grid = (columns) =>
  range(1, columns).reduce(
    (grid, i) => ({
      ...grid,
      [`${i}/${columns}`]: `${(i / columns) * 100}%`,
    }),
    {}
  );

const rangeColumns = {
  0: "0",
  1: "0.4rem",
  2: "0.8rem",
  3: "1.2rem",
  4: "1.6rem",
  5: "2rem",
  6: "2.4rem",
  7: "2.8rem",
  8: "3.2rem",
  9: "3.6rem",
  10: "4rem",
  11: "4.4rem",
  12: "4.8rem",
  13: "5.2rem",
  14: "5.6rem",
  15: "6rem",
  16: "6.4rem",
  17: "6.8rem",
  18: "7.2rem",
  19: "7.6rem",
  20: "8rem",
};

const colors = {
  transparent: "transparent",
  black: "#000",
  white: "#fff",
  "dark-gray": "#AEB6C3",
  "pale-gray": "#F5F5F5",
  "electric-blue": "#1E4BD2",
  "pale-grey-global-bg": "#F2F3F5",
  "blue-grey": "#808188",
  "dark-grey-txt": "#E1E3E7",
  cyan: "#00AAFF",
  violet: "#8949F5",
  "light-green": "#02D14C",
  "warning": "#faad14",
  green: "#02D14C",
  blue: "#4CC3FF",
  "light-blue": "#3438E0",
  "light-blue-transparent": "#3438E033",
  "sea​-wave": "#0D8BF6",
  coral: "#FF8552",
  silver: "#C3C2C2",
  "dark-vivid-purple-crayola": "#8F479C",
  asparagus: "#80AB4F",
  "light-carmine-pink": "#EB5757",
  "light-blue-gray": "#648BA3",
  "blue-frosty-sky": "#03A9FC",
  "pearlescent-light-gray": "#A19291",
  "blue-crayola": "#157CF2",
  "olive-yellow": "#A1A150",
  "dark-yellow": "#f2c94c",
  "dark-asparagus": "#6BB14E",
  "vivid-purple-crayola": "#8B469E",
  "silver-two": "#C4C4C4",
  error: "#EB5757",
};

module.exports = {
  theme: {
    colors: colors,
    textColor: colors,
    borderRadius: {
      none: "none",
      "2": "0.2rem",
    },
    inset: {
      ...rangeColumns,
    },
    opacity: {
      "0": "0",
      "10": ".1",
      "20": ".2",
      "30": ".3",
      "40": ".4",
      "50": ".5",
      "60": ".6",
      "70": ".7",
      "80": ".8",
      "90": ".9",
      "100": "1",
    },
    borderColor: (theme) => ({
      ...theme("borderColors"),
      ...colors,
    }),
    screens: {
      mobile: { "max": "767px" },
      tablet: "768px",
      desktop: "1024px",
      desktop1280: "1280px",
      // desktop1360: "1360px", // вкл при необходимости
    },
    borderWidth: {
      default: "0.1rem",
      "0": "0",
      "1": "0.1rem",
      "2": "0.2rem",
    },
    flex: {
      "1": "1 1 0%",
      "0": "0 0 auto",
      auto: "1 1 auto",
      initial: "0 1 auto",
      none: "none",
    },
    spacing: range(0, 21).reduce(
      (acc, step) => ({ ...acc, [step]: `${step / 2.5}rem` }),
      {
        25: "10rem",
        30: "12rem",
        35: "14rem",
        40: "16rem",
      }
    ),
    width: {
      auto: "auto",
      "1/2": "50%",
      ...grid(3),
      ...grid(4),
      ...grid(5),
      ...grid(6),
      ...grid(7),
      // ...grid(12), // вкл при необходимости
      ...rangeColumns,
      full: "100%",
      screen: "100vw",
    },
    listStylePosition: false,
    listStyleType: false,
    boxShadow: false,
    tableLayout: false,
    verticalAlign: false,
    backgroundPosition: false,
    fontFamily: false,
    fontSize: false,
    height: {
      ...rangeColumns,
      full: "100%",
      "1/2": "50%",
      screen: "100vh",
      "1": "0.4rem",
      "4": "1rem",
      "16": "4rem",
    },
    letterSpacing: false,
  },
  variants: {
    opacity: ["hover"],
  },
  plugins: [],
  purge: ["./src/**/*.tsx", "./src/**/*.js"],
};
