import React, { FC, useState } from "react";
import useMobile from "@/hooks/useMobile";
import Card from "@/components/ui-kit/card";
import { OrderType } from "app/types";
import moment from "moment";
import { formatAmount } from "@/utils/formatPrice";
import { paymentMethodsNames } from "@/utils/paymentMethodsNames";
import AdaptableBlock from "@/components/ui-kit/adaptableBlock";
import WarningInfoCard from "@/components/pages/platform/pages/CardOrder/description/WarningInfoCard/WarningInfoCard";
import { AnimatePresence, motion } from "framer-motion";
import { DatePicker, Tooltip } from "antd";
import css from "./description.modules.scss";
import {
  CheckCircleFilled,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import dayjs from "dayjs";

type DescriptionPropsType = {
  currency: string;
  isEditMode: boolean;
  orderSum: number;
  isShowWarning: boolean;
  supplierName: string;
  purchaserName: string;
  dateCreated: string;
  deliveryAddress: string;
  deliveryType: string;
  payType: string;
  deliveryDate: string;
  msg: string;
  prepay: string;
  commercialOffer: string;
  setCurrentOrder: React.Dispatch<React.SetStateAction<OrderType>>;
};

const commercialOfferType = {
  formalized_commercial_offer: "Загрузить вручную",
  autogenerated_commercial_offer: "Автоматическая генерация"
};

const Description: FC<DescriptionPropsType> = ({
  currency,
  isEditMode,
  orderSum,
  isShowWarning,
  supplierName,
  purchaserName,
  dateCreated,
  deliveryAddress,
  deliveryType,
  payType,
  deliveryDate,
  msg,
  commercialOffer,
  setCurrentOrder,
}) => {
  const { isMobile } = useMobile();
  const [isTextShow, setIsTextShow] = useState<boolean>(false);

  const updateDeliveryDate = (date: string): void => {
    setCurrentOrder((prev: OrderType) => {
      return ({
        ...prev,
        deliveryDate: date
      });
    })
  };

  return (
    <Card
      title="Описание"
      className="mt-8 tablet:mt-5"
      containerClassName="flex flex-col"
      descriptionCardShown
    >
      <div className="flex flex-col tablet:flex-row">
        {isMobile ? (
          <>
            <div className="flex justify-between">
              {/*TODO: пока скрываем от всех стоимость */}
              {/*<div>*/}
              {/*  <div className={css.textGrey}>*/}
              {/*    Стоимость*/}
              {/*  </div>*/}
              {/*  <div className={`mt-1 ${css.textBlack}`}>*/}
              {/*    {!!orderSum ? formatAmount(orderSum?.toString(), currency) : "На согласовании"}*/}
              {/*  </div>*/}
              {/*</div>*/}

              <div>
                <div className={`tablet:mt-6 ${css.textGrey}`}>
                  Тип коммерческого предложения
                </div>
                <div className={`mt-1 ${css.textBlack}`}>
                  {commercialOfferType[commercialOffer] ?? "нет"}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className={css.price}>
            {/*TODO: пока скрываем от всех стоимость */}
            {/*<div className={css.textGrey}>*/}
            {/*  Стоимость*/}
            {/*</div>*/}
            {/*<div className={`mt-2 ${css.textBlack}`}>*/}
            {/*  {!!orderSum ? formatAmount(orderSum?.toString(), currency) : "На согласовании"}*/}
            {/*</div>*/}
            <div className={`${css.textGrey}`}>
              Тип коммерческого предложения
            </div>
            <div className={`mt-2 ${css.textBlack}`}>
              {commercialOfferType[commercialOffer] ?? "нет"}
              {!!commercialOfferType[commercialOffer] && (
                <>
                  {commercialOffer === "formalized_commercial_offer" ? (
                    <ExclamationCircleFilled className="ml-2 w-1 text-warning" rev={undefined} />
                  ) : (
                    <CheckCircleFilled className="ml-2 w-1 text-light-green" rev={undefined} />
                  )}
                </>
              )}
            </div>
          </div>
        )}
        <div className={`mt-6 tablet:mt-0 ${css.provider}`}>
          <AdaptableBlock
            title="Поставщик"
            itemName={supplierName}
            titleClassName={css.textGrey}
          />
          <div className="mt-6">
            <AdaptableBlock
              title="Заказчик"
              itemName={purchaserName}
              titleClassName={css.textGrey}
            />
          </div>
        </div>
        <div className={`mt-6 tablet:mt-0 tablet:ml-4 ${css.delivery}`}>
          <div className={css.textGrey}>
            Дата создания
          </div>
          <div className={`mt-1 tablet:mt-2 ${css.textBlack}`}>
            {moment(dateCreated).format("DD.MM.YYYY HH:mm")}
          </div>
          <div className={`mt-4 tablet:mt-7 ${css.textGrey}`}>
            Адрес доставки
          </div>
          <div className={`mt-1 tablet:mt-2 ${css.textBlack}`}>
            {deliveryAddress?.length > 80 ? (
              <Tooltip title={deliveryAddress}>
                {deliveryAddress?.substring(0, 80).trim()}
                {deliveryAddress?.length > 80 && <span>...</span>}
              </Tooltip>
            ) : (
              <p>{deliveryAddress}</p>
            )}
          </div>
        </div>
        <div className="mt-4 ml-0 tablet:ml-4 tablet:mt-0">
          <div className="flex justify-between">
            <div className={css.getting}>
              <div className={css.textGrey}>
                Способ получения
              </div>
              <div className={`mt-1 ${css.textBlack}`}>
                {deliveryType !== "delivery" ? "Самовывоз" : "Доставка"}
              </div>
            </div>
            <div className={css.getting}>
              <div className={css.textGrey}>
                Дата доставки
              </div>
              {isEditMode ? (
                <DatePicker
                  defaultValue={dayjs(deliveryDate)}
                  placeholder="Выберите дату"
                  onChange={(date: dayjs.Dayjs, dateString: string) => updateDeliveryDate(dateString)}
                  disabledDate={(current: dayjs.Dayjs) => current && current < dayjs().endOf('day')}
                />
              ) : (
                <div className={`mt-1 ${css.textBlack}`}>
                  {dayjs(deliveryDate).format("DD.MM.YYYY")}
                </div>
              )}
            </div>
          </div>
          <div className={`mt-4 tablet:mt-7 flex ${css.payment}`}>
            <div className={css.paymentBlock}>
              <div className={css.textGrey}>Тип оплаты</div>
              <div className={`mt-1 ${css.textBlack}`}>
                {paymentMethodsNames(payType)}
                <br />
              </div>
            </div>
          </div>
          <motion.div className={`mt-6 ${css.message}`}>
            <AnimatePresence>
              <div className={css.textGrey}>Комментарий</div>
              <div className={`mt-1 ${css.textBlack}`}>
                {msg?.length > 110 && !isTextShow ? (
                  <>
                    {msg.substring(0, 110).trim()}
                    {` ... `}
                    <button className="text-electric-blue underline" onClick={() => setIsTextShow(true)}>
                      + читать
                    </button>
                  </>
                ) : (
                  <>
                    {msg}
                    {msg?.length > 110 && (
                      <button
                        className="text-electric-blue underline"
                        onClick={() => setIsTextShow(false)}
                      >
                        - скрыть
                      </button>
                    )}
                  </>
                )}
              </div>
            </AnimatePresence>
          </motion.div>
        </div>
      </div>
      {isShowWarning && <WarningInfoCard />}
    </Card>
  );
};

export default Description;
