import React, { FC } from "react";
import cn from "classnames/bind";
import css from "./description.modules.scss";

const cx = cn.bind(css);

interface DescriptionProps {
  title: string;
  className: string;
}

const Description: FC<DescriptionProps> = ({ title, children, className }) => {
  return (
    <div className={cx("flex flex-row tablet:flex-col", className)}>
      <p className={cx("text-blue-grey text-n2 mb-1 mr-6 tablet:mr-0", "title")}>
        {title}
      </p>
      <div className={cx("children")}>{children}</div>
    </div>
  );
};

export default Description;
