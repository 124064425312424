import React, { FC, useEffect, useRef } from "react";

import cn from "classnames";
import lottie from "lottie-web";

import animationData from "@/assets/animations/preloader.json";

type PreloaderPropsType = {
  className?: string;
};

const Preloader: FC<PreloaderPropsType> = ({ className }) => {
  const ref = useRef(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      name: "preloader",
      container: ref.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData,
    });

    return () => {
      anim.destroy();
    };
  }, []);
  return (
    <div className={cn("flex justify-center", { [className]: className })}>
      <div ref={ref} />
    </div>
  );
};

export default Preloader;
