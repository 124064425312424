import React, { FC } from "react";
import cn from "classnames";
import css from "./tabButton.modules.scss";

type TabButtonPropsType = {
  className?: string;
  onClick: () => void;
  isActive: boolean;
  type?: "primary" | "secondary";
  disable?: boolean;
};

const TabButton: FC<TabButtonPropsType> = ({
  children,
  className,
  onClick,
  isActive,
  type = "primary",
  disable,
}) => {
  return (
    <button
      className={cn(`whitespace-no-wrap font-medium ${className}`, {
        [`text-n1 ${css.tabPrimaryButton}`]: type === "primary",
        [css.activePrimaryTab]: isActive && type === "primary",
        [`text-s1 ${css.tabSecondaryButton}`]: type === "secondary",
        [css.activeSecondaryTab]: isActive && type === "secondary",
      })}
      onClick={onClick}
      disabled={disable}
    >
      {children}
    </button>
  );
};

export default TabButton;
