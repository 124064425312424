import {
  SUPPLIERS_SUCCESS,
  SUPPLIERS_FAILURE,
  CREATE_SUPPLIER_SUCCESS,
  CREATE_SUPPLIER_FAILURE,
  GET_SUPPLIER_SUCCESS,
  GET_SUPPLIER_FAILURE,
  UPDATE_SUPPLIER_SUCCESS,
  UPDATE_SUPPLIER_FAILURE,
  DELETE_SUPPLIER_SUCCESS,
  DELETE_SUPPLIER_FAILURE,
  CREATE_SUPPLIER_STATUS_SUCCESS,
  CREATE_SUPPLIER_STATUS_FAILURE,
  UPDATE_CURRENT_SUPPLIER_SUCCESS,
  SAVE_SUPPLIER_SERVICE_SETTINGS_SUCCESS,
  SAVE_SUPPLIER_SERVICE_SETTINGS_FAILURE,
  GET_SUPPLIER_SERVICE_SETTINGS_SUCCESS,
  GET_SUPPLIER_SERVICE_SETTINGS_FAILURE,
} from "@/constants/actionTypes";
import { ThunkAction } from "redux-thunk";
import { AppStateType } from "@/reducers";
import {
  SupplierListModelType,
  SupplierModelType,
  SupplierParamsType,
  SupplierServiceSettingsDataType,
  SuppliersListParamsType,
} from "app/types";
import * as SuppliersAPI from "@/api/suppliers.api";

export type SuppliersActionsType =
  | SuppliersSuccessType
  | SuppliersFailureType
  | GetSupplierSuccessType
  | GetSupplierFailureType
  | CreateSupplierSuccessType
  | CreateSupplierFailureType
  | UpdateSupplierSuccessType
  | UpdateSupplierFailureType
  | DeleteSupplierSuccessType
  | DeleteSupplierFailureType
  | CreateSupplierStatusSuccessType
  | CreateSupplierStatusFailureType
  | UpdateCurrentSupplierSuccessType
  | SaveSupplierServiceSettingsSuccessType
  | SaveSupplierServiceSettingsFailureType
  | GetSupplierServiceSettingsSuccessType
  | GetSupplierServiceSettingsFailureType;

type SuppliersSuccessType = {
  type: typeof SUPPLIERS_SUCCESS;
  payload: {
    suppliers: SupplierListModelType;
  };
};

export const suppliersSuccess = (suppliers: SupplierListModelType): SuppliersSuccessType => ({
  type: SUPPLIERS_SUCCESS,
  payload: {
    suppliers,
  },
});

type SuppliersFailureType = {
  type: typeof SUPPLIERS_FAILURE;
  payload: {
    error: string;
  };
};

const suppliersFailure = (error?: string): SuppliersFailureType => ({
  type: SUPPLIERS_FAILURE,
  payload: {
    error,
  },
});

// получение списка поставщиков
export const getSuppliersList = (params: SuppliersListParamsType): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  SuppliersActionsType
> => async (dispatch) => {
  try {
    const response = await SuppliersAPI.getSuppliers(params);

    dispatch(suppliersSuccess(response.data));
  } catch (err) {
    dispatch(suppliersFailure(err.message));
  }
};

type CreateSupplierSuccessType = {
  type: typeof CREATE_SUPPLIER_SUCCESS;
  payload: {
    newSuppler: SupplierModelType;
  };
};

export const createSuppliersSuccess = (newSuppler: SupplierModelType): CreateSupplierSuccessType => ({
  type: CREATE_SUPPLIER_SUCCESS,
  payload: {
    newSuppler,
  },
});

type CreateSupplierFailureType = {
  type: typeof CREATE_SUPPLIER_FAILURE;
  payload: {
    error: string;
  };
};

export const createSuppliersFailure = (error?: string): CreateSupplierFailureType => ({
  type: CREATE_SUPPLIER_FAILURE,
  payload: {
    error,
  },
});

type CreateSupplierStatusSuccessType = {
  type: typeof CREATE_SUPPLIER_STATUS_SUCCESS;
  payload: {
    createSupplierStatus: number;
  };
};

export const createSupplierStatusSuccess = (createSupplierStatus: number): CreateSupplierStatusSuccessType => ({
  type: CREATE_SUPPLIER_STATUS_SUCCESS,
  payload: {
    createSupplierStatus,
  },
});

type CreateSupplierStatusFailureType = {
  type: typeof CREATE_SUPPLIER_STATUS_FAILURE;
  payload: {
    error: string;
  };
};

export const createSupplierStatusFailure = (error?: string): CreateSupplierStatusFailureType => ({
  type: CREATE_SUPPLIER_STATUS_FAILURE,
  payload: {
    error,
  },
});

export const createNewSupplier = (
  name: string,
  inn: string,
  email: string,
  phone: string
): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  SuppliersActionsType
> => async (dispatch) => {
  try {
    const response = await SuppliersAPI.createSupplier(
      name,
      inn,
      email,
      phone
    );

    dispatch(createSupplierStatusSuccess(response.status))
    dispatch(createSuppliersSuccess(response.data));
  } catch (err) {
    dispatch(createSupplierStatusFailure(err.response.status));
    dispatch(createSuppliersFailure(err.response.data.detail));
  }
};

type GetSupplierSuccessType = {
  type: typeof GET_SUPPLIER_SUCCESS;
  payload: {
    supplier: SupplierModelType;
  };
};

const getSupplierSuccess = (supplier: SupplierModelType): GetSupplierSuccessType => ({
  type: GET_SUPPLIER_SUCCESS,
  payload: {
    supplier
  },
});

type GetSupplierFailureType = {
  type: typeof GET_SUPPLIER_FAILURE;
  payload: {
    error: string;
  };
};

export const getSupplierFailure = (error?: string): GetSupplierFailureType => ({
  type: GET_SUPPLIER_FAILURE,
  payload: {
    error,
  },
});

export const getSupplierInfo = (
  supplierId: number
): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  SuppliersActionsType
> => async (dispatch) => {
  try {
    const response = await SuppliersAPI.getSupplier(supplierId);

    dispatch(getSupplierSuccess(response.data));
  } catch (err) {
    dispatch(getSupplierFailure(err.message));
  }
};

type UpdateCurrentSupplierSuccessType = {
  type: typeof UPDATE_CURRENT_SUPPLIER_SUCCESS;
  payload: {
    supplierName: string;
  };
};

const updateCurrentSupplierSuccess = (supplierName: string): UpdateCurrentSupplierSuccessType => ({
  type: UPDATE_CURRENT_SUPPLIER_SUCCESS,
  payload: {
    supplierName
  },
});

type UpdateSupplierSuccessType = {
  type: typeof UPDATE_SUPPLIER_SUCCESS;
  payload: {
    updateSupplerStatus: number;
  };
};

const updateSupplierSuccess = (updateSupplerStatus: number): UpdateSupplierSuccessType => ({
  type: UPDATE_SUPPLIER_SUCCESS,
  payload: {
    updateSupplerStatus
  },
});

type UpdateSupplierFailureType = {
  type: typeof UPDATE_SUPPLIER_FAILURE;
  payload: {
    error: string;
  };
};

export const updateSupplierFailure = (error?: string): UpdateSupplierFailureType => ({
  type: UPDATE_SUPPLIER_FAILURE,
  payload: {
    error,
  },
});

export const updateSupplierInfo = (
  supplierId: number,
  params: SupplierParamsType
): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  SuppliersActionsType
> => async (dispatch) => {
  try {
    const response = await SuppliersAPI.updateSupplier(supplierId, params);

    dispatch(updateSupplierSuccess(response.status));
  } catch (err) {
    dispatch(updateSupplierFailure(err?.response?.data?.detail));
  }
};

type DeleteSupplierSuccessType = {
  type: typeof DELETE_SUPPLIER_SUCCESS;
  payload: {
    deleteSupplerStatus: number;
  };
};

const deleteSupplierSuccess = (deleteSupplerStatus: number): DeleteSupplierSuccessType => ({
  type: DELETE_SUPPLIER_SUCCESS,
  payload: {
    deleteSupplerStatus
  },
});

type DeleteSupplierFailureType = {
  type: typeof DELETE_SUPPLIER_FAILURE;
  payload: {
    error: string;
  };
};

export const deleteSupplierFailure = (error?: string): DeleteSupplierFailureType => ({
  type: DELETE_SUPPLIER_FAILURE,
  payload: {
    error,
  },
});

export const deleteSupplierFromList = (supplierId: number):
ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  SuppliersActionsType
> => async (dispatch) => {
  try {
    const response = await SuppliersAPI.deleteSupplier(supplierId);

    dispatch(deleteSupplierSuccess(response.status));
  } catch (err) {
    dispatch(deleteSupplierFailure(err.message));
  }
}

type SaveSupplierServiceSettingsSuccessType = {
  type: typeof SAVE_SUPPLIER_SERVICE_SETTINGS_SUCCESS;
  payload: {
    saveSupplierServiceSettingsStatus: number;
  };
};

const saveSupplierServiceSettingsSuccess = (saveSupplierServiceSettingsStatus: number): SaveSupplierServiceSettingsSuccessType => ({
  type: SAVE_SUPPLIER_SERVICE_SETTINGS_SUCCESS,
  payload: {
    saveSupplierServiceSettingsStatus
  },
});

type SaveSupplierServiceSettingsFailureType = {
  type: typeof SAVE_SUPPLIER_SERVICE_SETTINGS_FAILURE;
  payload: {
    error: string;
  };
};

export const saveSupplierServiceSettingsFailure = (error?: string):SaveSupplierServiceSettingsFailureType => ({
  type: SAVE_SUPPLIER_SERVICE_SETTINGS_FAILURE,
  payload: {
    error,
  },
});

export const saveSupplierServiceSettingsData = (
  getNewProductsFromTpPeriod: number,
  sendContractInTpPeriod: number,
  syncProductsWithTpPeriod: number,
  syncSuppliersWithTpPeriod: number,
  syncTpCategoriesPeriod: number,
  updateProductsFromTpPeriod: number,
): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  SuppliersActionsType
> => async (dispatch) => {
  try {
    const response = await SuppliersAPI.saveSupplierServiceSettings(
      getNewProductsFromTpPeriod,
      sendContractInTpPeriod,
      syncProductsWithTpPeriod,
      syncSuppliersWithTpPeriod,
      syncTpCategoriesPeriod,
      updateProductsFromTpPeriod,
    );

    dispatch(saveSupplierServiceSettingsSuccess(response.status));
  } catch (err) {
    dispatch(saveSupplierServiceSettingsFailure(err.message));
  }
}

type GetSupplierServiceSettingsSuccessType = {
  type: typeof GET_SUPPLIER_SERVICE_SETTINGS_SUCCESS;
  payload: {
    supplierServiceSettingsData: SupplierServiceSettingsDataType;
  };
};

const getSupplierServiceSettingsSuccess = (
supplierServiceSettingsData: SupplierServiceSettingsDataType
): GetSupplierServiceSettingsSuccessType => ({
  type: GET_SUPPLIER_SERVICE_SETTINGS_SUCCESS,
  payload: {
    supplierServiceSettingsData
  },
});

type GetSupplierServiceSettingsFailureType = {
  type: typeof GET_SUPPLIER_SERVICE_SETTINGS_FAILURE;
  payload: {
    error: string;
  };
};

export const getSupplierServiceSettingsFailure = (error?: string):GetSupplierServiceSettingsFailureType => ({
  type: GET_SUPPLIER_SERVICE_SETTINGS_FAILURE,
  payload: {
    error,
  },
});

export const getSupplierServiceSettingsData = ():
  ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  SuppliersActionsType
> => async (dispatch) => {
  try {
    const response = await SuppliersAPI.getSupplierServiceSettings();

    dispatch(getSupplierServiceSettingsSuccess(response.data));
  } catch (err) {
    dispatch(getSupplierServiceSettingsFailure(err.message));
  }
}
