import { createSelector } from "reselect";
import { AppStateType } from "@/reducers";
import { InitialStateType as SearchStateType } from "@/reducers/search.reducer";
import { InitialStateType as AccountStateType } from "@/reducers/account.reducer";
import { SearchProductsType } from "app/types";

type SearchObj = {
  isClient: boolean;
  results: any[];
  searchValue: string | null;
  sections: any[];
  isFetching: boolean;
  products: false | SearchProductsType;
  isFAQFetching: boolean;
  requests: string[];
};

export const searchSelector = createSelector(
  (state: AppStateType): AccountStateType => state.account,
  (state: AppStateType): SearchStateType => state.search,
  (account, search): SearchObj => {
    const { userData } = account;
    const isClient = Boolean(userData.id);
    const {
      results,
      searchValue,
      sections,
      isFetching,
      isFAQFetching,
      faq,
    } = search;

    const products = !results.length
      ? false
      : {
          name: results[0].name,
          items: results[0].results.map(({ id, name }) => ({ id, name })),
          isFetching: results[0].isFetching,
          currentCount: results[0].results.length,
          totalCount: results[0].count,
        };

    const requests = [...faq]
      .sort((a, b) => a.order - b.order)
      .map(({ request }) => request);

    return {
      isClient,
      results,
      searchValue,
      sections,
      isFetching,
      products,
      isFAQFetching,
      requests,
    };
  }
);
