import axios from "@/utils/axios";

const API_BASE: string = process.env.GATSBY_API_BASE?.trimEnd();

export const getUserCart = async () => {
  return axios.GET(API_BASE, "/api/orders/v1/cart");
};

export const addProductInCart = async (productId: number, quantity: number) => {
  return axios.POST(API_BASE, "/api/orders/v1/cart", {
    productId: productId,
    quantity: quantity,
  });
};

export const removeProductFromCart = async (productId: number, quantity?: number) => {
  return axios.DELETE(API_BASE, `/api/orders/v1/cart/item/${productId}`, {
    quantity: quantity,
  });
};

export const updateProductInCart = async (productId: number, quantity: number) => {
  return axios.PATCH(API_BASE, `/api/orders/v1/cart/item/${productId}`, {
    quantity: quantity,
  });
};

