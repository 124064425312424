import React, {
  FC,
  Dispatch,
  useState,
  SetStateAction,
} from "react";
import TabButton from "@/components/ui-kit/tabButton";
import { getProductsList } from "@/actions/products.actions";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "@/reducers";

interface ICatalogVerificationTabsProps {
  supplierId: number;
  productName: string;
  productArticle: string;
  setCurrentActiveStatus: Dispatch<SetStateAction<string>>;
}

export const CatalogVerificationTabs: FC<ICatalogVerificationTabsProps> = ({
  supplierId,
  productName,
  productArticle,
  setCurrentActiveStatus,
}) => {
  const dispatch = useDispatch();

  const { isFetching } = useSelector((state: AppStateType) => state.products);

  const [tab, setTab] = useState<"placed" | "declined" | "checking" | null>(null);

  const onTabChange = (tabName: "placed" | "declined" | "checking" | null): void => {
    setTab(tabName);
    setCurrentActiveStatus(tabName);

    fetchProductsList(tabName);
  };

  const fetchProductsList = (status: string | null): void => {
    dispatch(getProductsList({
      page: 1,
      pageSize: 25,
      status,
      search: null,
      name: productName,
      supplierId,
      article: productArticle,
    }));
  };

  return (
    <div className="mb-5">
      <TabButton
        className='mr-5'
        onClick={() => onTabChange(null)}
        isActive={!tab}
        disable={isFetching}
      >
        ВСЕ
      </TabButton>
      <TabButton
        className='mr-5'
        onClick={() => onTabChange("placed")}
        isActive={tab === "placed"}
        disable={isFetching}
      >
        Размещено
      </TabButton>
      <TabButton
        className='mr-5'
        onClick={() => onTabChange("checking")}
        isActive={tab === "checking"}
        disable={isFetching}
      >
        На верификации
      </TabButton>
      <TabButton
        onClick={() => onTabChange("declined")}
        isActive={tab === "declined"}
        disable={isFetching}
      >
        Отклонено
      </TabButton>
    </div>
  );
};
