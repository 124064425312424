import React, { FC } from "react";

import SearchIcon from "@/assets/icons/search-middle.inline.svg";

import css from "./searchInput.modules.scss";

type SearchInputPropsType = {
  changeValue: (value: string) => void;
  maxLength?: number;
  placeholder?: string;
};

const SearchInput: FC<SearchInputPropsType> = ({ changeValue, maxLength, placeholder }) => (
  <div className={`relative flex ${css.searchWrapper}`}>
    <SearchIcon className={css.serchIcon} />
    <input
      className={`text-s1 ${css.searchInput}`}
      type="text"
      maxLength={maxLength}
      placeholder={ placeholder || `Найти в разделе `}
      onChange={(e) => {
        const value = e.target.value.trim();
        changeValue(value);
      }}
    />
  </div>
);

export default SearchInput;
