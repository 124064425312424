import React, { FC } from "react";
import ReactModal from "react-modal";
import useBodyScrollLock from "@/hooks/useBodyScrollLock";
import CloseIcon from "@/assets/icons/close.inline.svg";
import style from "./modal.modules.scss";

ReactModal.setAppElement(`#___gatsby`);

type ModalPropsType = {
  isOpen: boolean;
  children: React.ReactNode;
  onClose: () => void;
  overlayClassName?: string;
  className?: string;
  onAfterClose?: () => void;
  showCloseButton?: boolean;
};

const Modal: FC<ModalPropsType> = ({
  isOpen,
  onClose,
  children,
  overlayClassName = "",
  className = "",
  onAfterClose,
  showCloseButton
}) => {
  const scrollLockRef = useBodyScrollLock(isOpen);

  const renderCloseButton = () => showCloseButton && (
    <button
      className={`absolute flex items-center justify-center z-10 cursor-pointer text-white ${style.closeButton}`}
      onClick={onClose}
    >
      <CloseIcon />
    </button>
  );

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName={`fixed inset-0 z-50 w-full ${style.overlay} ${overlayClassName}`}
      className={`w-full ${className}`}
      contentLabel="Modal"
      ref={scrollLockRef}
      closeTimeoutMS={250}
      onAfterClose={onAfterClose}
    >
      {children}
      {renderCloseButton()}
    </ReactModal>
  );
};

export default Modal;
