import React, { useCallback, useRef, useEffect } from "react";

import CarouselNuka from "nuka-carousel";
import cn from "classnames";

import style from "./carousel.modules.scss";

const Carousel = ({
  children,
  slidesToShow,
  hiddenDots,
  cellSpacing = 40,
  showControls,
  disableWrapAround = false,
  frameOverflow = "hidden",
  disableDragging = false,
  count,
  setPage = (v) => {},
  page,
  isNextImportant = true,
  isControlSlider = true,
}) => {
  const defaultControlsConfig = {
    pagingDotsClassName: style.pagingDots,
    prevButtonText: " ",
    nextButtonText: " ",
    nextButtonStyle: { display: `${showControls ? "block" : "none"}` },
    prevButtonStyle: { display: `${showControls ? "block" : "none"}` },
    nextButtonClassName: style.nextButton,
    prevButtonClassName: style.prevButton,
    containerClassName: style.containerClassName,
  };

  const ref = useRef();

  useEffect(() => {
    let timer;
    if (children && page !== 1) {
      timer = setTimeout(() => (ref.current.state.currentSlide = 1), 600);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [page, children, count]);

  const renderDotControls = ({ currentSlide, slideCount }) => (
    <div
      className={cn(style.dotsContainer, {
        [style.hiddenDots]: hiddenDots,
      })}
    >
      <ul className={style.dots}>
        {[...Array(slideCount)].map((sc, i) => (
          <li
            className={cn(style.dot, {
              [style.dotActive]: currentSlide === i,
            })}
            key={i}
          ></li>
        ))}
      </ul>
    </div>
  );

  const onNextPage = useCallback(
    (value) => {
      const currentCount = count - 3;

      if (value === currentCount && isNextImportant) {
        setTimeout(() => setPage((prev) => prev + 1), 1400);
      }
      if (value === 0) {
        setTimeout(() => {
          setPage((prev) => {
            if (prev === 1) {
              return 1;
            }
            return prev - 1;
          }, 1400);
        });
      }
    },
    [count, isNextImportant]
  );

  return isControlSlider ? (
    <CarouselNuka
      ref={ref}
      defaultControlsConfig={defaultControlsConfig}
      renderBottomCenterControls={renderDotControls}
      wrapAround={!disableWrapAround}
      cellSpacing={cellSpacing}
      slidesToShow={slidesToShow}
      frameOverflow={frameOverflow}
      disableEdgeSwiping={true}
      // framePadding={showControls ? "3.2rem 0 0" : "0"}
      dragging={!disableDragging}
      afterSlide={onNextPage}
      renderCenterLeftControls={({
        previousSlide,
        currentSlide,
        slideCount,
      }) => {
        const isDisabled = currentSlide === 0;

        return (
          <button
            className={cn(`${style.prevButton}`, {
              [style.prevButton__disabled]: isDisabled,
            })}
            onClick={previousSlide}
            disabled={isDisabled}
          ></button>
        );
      }}
      renderCenterRightControls={({ nextSlide, currentSlide, slideCount }) => (
        <button
          className={cn(`${style.nextButton}`, {
            [style.nextButton__disabled]: currentSlide + 3 === slideCount,
          })}
          onClick={nextSlide}
          disabled={currentSlide + 3 === slideCount}
        ></button>
      )}
    >
      {children}
    </CarouselNuka>
  ) : (
    <CarouselNuka
      ref={ref}
      defaultControlsConfig={defaultControlsConfig}
      renderBottomCenterControls={renderDotControls}
      wrapAround={!disableWrapAround}
      cellSpacing={cellSpacing}
      slidesToShow={slidesToShow}
      frameOverflow={frameOverflow}
      disableEdgeSwiping={true}
      // framePadding={showControls ? "3.2rem 0 0" : "0"}
      dragging={!disableDragging}
      afterSlide={onNextPage}
    >
      {children}
    </CarouselNuka>
  );
};

export default Carousel;
