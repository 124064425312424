import React, { FC } from "react";

import css from "./emptyResult.modules.scss";

type EmptyResultPropsType = {
  text: string;
};

const EmptyResult: FC<EmptyResultPropsType> = ({ text }) => (
  <div className="w-full">
    <hr className={`w-full ${css.emptyResult__hr}`} />
    <div className={`mt-5 tablet:mt-8 ${css.emptyResult__text}`}>{text}</div>
  </div>
);

export default EmptyResult;
