import React from "react";
import css from "./noresult.modules.scss";

const NoResult = () => (
  <div className={`text-x2 ${css.noresult}`}>
    По вашему запросу ничего не найдено, <br />
    попробуйте изменить запрос
  </div>
);

export default NoResult;
