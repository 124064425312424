import React, {
  FC,
  Dispatch,
  useState,
  useEffect,
  SetStateAction,
} from "react";
import { AppStateType } from "@/reducers";
import { RouteComponentProps } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { getProductsList } from "@/actions/products.actions";
import { CatalogFilters } from "@/components/pages/platform/pages/Catalog/CatalogFilters/CatalogFilters";
import { CatalogVerificationList } from "../CatalogVerificationPage/CatalogVerificationList/CatalogVerificationList";
import EmptyResult from "@/components/ui-kit/emptyResult";
import { Button } from "antd";
import { ControlTwoTone } from "@ant-design/icons";
import css from "@/components/pages/platform/pages/Catalog/Catalog.modules.scss";

interface ICatalogVerificationProps extends RouteComponentProps {
  currentPage?: number;
  setCurrentPage?: Dispatch<SetStateAction<number>>;
  currentActiveStatus?: string;
  supplierId?: number;
  productName?: string;
  setSupplierId?: Dispatch<SetStateAction<number>>;
  setProductName?: Dispatch<SetStateAction<string>>;
  productArticle?: string;
  setProductArticle?: Dispatch<SetStateAction<string>>;
  setCurrentActiveStatus?: Dispatch<SetStateAction<string>>;
  isVerificationFiltersHovered?: boolean;
  setVerificationFiltersHovered?: Dispatch<SetStateAction<boolean>>;
}

const CatalogVerificationPage: FC<ICatalogVerificationProps> = ({
  currentPage,
  setCurrentPage,
  currentActiveStatus,
  supplierId,
  productName,
  setSupplierId,
  setProductName,
  productArticle,
  setProductArticle,
  setCurrentActiveStatus,
  isVerificationFiltersHovered,
  setVerificationFiltersHovered,
}) => {
  const dispatch = useDispatch();

  const { productsList, updateProductStatus } = useSelector((state: AppStateType) => state.products);

  useEffect(() => {
    dispatch(
      getProductsList({
        page: currentPage,
        pageSize: 25,
        status: currentActiveStatus,
        search: null,
        name: productName || null,
        supplierId: supplierId || null,
        article: productArticle || null,
      }),
    );
  }, [
    supplierId,
    productName,
    currentPage,
    productArticle,
    updateProductStatus,
  ]);

  return (
    <div>
      <h2 data-aos="fade-zoom-in" data-aos-once="true" className={`mb-6 hidden tablet:block text-x1`}>
        Верификация товаров
      </h2>
      <div className="mb-5 text-right">
        <Button
          className="relative"
          type="text"
          onClick={() => setVerificationFiltersHovered(!isVerificationFiltersHovered)}
          icon={<ControlTwoTone className={css.catalogBtnFilerIcon} rev={undefined} />}
        />
      </div>
      <div className={isVerificationFiltersHovered ? css.catalogFilterHidden : css.catalogFilterShown}>
        <CatalogFilters
          setCurrentPage={setCurrentPage}
          setSupplierId={setSupplierId}
          setProductName={setProductName}
          setProductArticle={setProductArticle}
        />
      </div>
      <div className={isVerificationFiltersHovered ? css.catalogListHidden : css.catalogListShown}>
        <CatalogVerificationList
          supplierId={supplierId}
          productName={productName}
          productArticle={productArticle}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          currentActiveStatus={currentActiveStatus}
          setCurrentActiveStatus={setCurrentActiveStatus}
        />
        {!productsList?.length && <EmptyResult text="Здесь будет список товаров" />}
      </div>
    </div>
  );
};

export default CatalogVerificationPage;
