import axios from "@/utils/axios";
import {
  ProductItemType,
  IRequestSupplierProductsCatalogFilters,
  IProductsVerificationCatalogFilters,
} from "app/types";

const API_BASE: string = process.env.GATSBY_API_BASE?.trimEnd();

// Получение списка товаров в разделе верификации
export const getProducts = async (filters: IProductsVerificationCatalogFilters) => {
  return axios.GET(API_BASE, "/api/iss/v1/products", filters);
};

// получение списка товаров поставщика
export const getSupplierProducts = async (filters: IRequestSupplierProductsCatalogFilters) => {
  return axios.GET(API_BASE, "/api/iss/v1/products/supplier", filters);
};

export const getProductsCatalog = async (filters: IRequestSupplierProductsCatalogFilters) => {
  return axios.GET(API_BASE, "/api/iss/v1/products/catalog", filters);
};

export const getProduct = async (productId?: number) => {
  return axios.GET(API_BASE, `/api/iss/v1/product/${productId}`);
};

export const deleteProduct = async (productId?: number) => {
  return axios.DELETE(API_BASE, `/api/iss/v1/product/${productId}`);
};

export const updateProduct = async (items?: ProductItemType[]) => {
  return axios.POST(API_BASE, "/api/iss/v1/products/update", { items });
};
