import React, { FC } from "react";
import cn from "classnames";
import DeleteIcon from "@/assets/icons/basket.inline.svg";
import css from "./buttonDelete.modules.scss";

type ButtonDeletePropsType = {
  onClick?: () => void;
  theme?: "primary" | "secondary";
  className?: string;
  disabled?: boolean;
  type?: "button" | "reset" | "submit";
};

const ButtonDelete: FC<ButtonDeletePropsType> = ({
  onClick,
  theme,
  className,
  disabled = false,
  type = "button",
}) => {
  const buttonTheme = theme || "primary";

  return (
    <button
      type={type}
      className={`flex items-center justify-center ${css.container} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      <DeleteIcon
        className={cn(`${css.icon}`, {
          [css.primary]: buttonTheme === "primary",
          [css.secondary]: buttonTheme === "secondary",
        })}
      />
    </button>
  );
};

export default ButtonDelete;
