import React, { FC } from "react";
import { Link as GatsbyLink } from "gatsby";
import { Link } from "@reach/router";
import TickRight from "@/assets/icons/tick-right.inline.svg";
import css from "./showAllLink.modules.scss";

type ShowAllLinkPropsType = {
  to: string;
  children: React.ReactNode;
  className?: string;
  tickClassName?: string;
};

const ShowAllLink: FC<ShowAllLinkPropsType> = ({
  to,
  children,
  className,
  tickClassName,
}) => {
  return (
    <Link
      to={to}
      className={`flex items-center text-n1 uppercase border-none font-medium p-0 tablet:hover:text-electric-blue ${css.link} ${className}`}
    >
      {children}
      <TickRight className={`ml-1 stroke-current ${tickClassName}`} />
    </Link>
  );
};

export default ShowAllLink;
