import React, {
  FC,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import { AppStateType } from "@/reducers";
import { useDispatch, useSelector } from "react-redux";
import { getUserCartProducts } from "@/actions/cart.actions";
import { CatalogListItem } from "./CatalogListItem/CatalogListItem";
import {
  Pagination,
  PaginationProps,
} from "antd";
import { FloatButton } from 'antd';
import { roleResolver } from "@/utils/roleResolver";

interface ICatalogListPageProps {
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

export const CatalogList: FC<ICatalogListPageProps> = ({
  currentPage,
  setCurrentPage,
}) => {
  const dispatch = useDispatch();

  const {
    productsCatalogList,
    productsCatalogListPages,
  } = useSelector((state: AppStateType) => state.products);

  const { userInfo } = useSelector((state: AppStateType) => state.account);

  const { isSupplier, isAuditor, isConcordant, isController } = roleResolver(userInfo?.roles);

  const canGetCart: boolean = !isSupplier && !isAuditor && !isConcordant && !isController;

  useEffect(() => {
    if (userInfo && canGetCart) {
      dispatch(getUserCartProducts());
    }
  }, [userInfo, productsCatalogList]);

  const paginationChange: PaginationProps['onChange'] = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      {!!productsCatalogList?.length && (
        <div>
          {productsCatalogList?.map(({
            id,
            name,
            rest,
            price,
            status,
            images,
            article,
            category,
            supplier,
            properties,
            measureUnitName,
            currencyCharCode,
          }, index) => (
            <CatalogListItem
              key={id}
              id={id}
              name={name}
              rest={rest}
              price={Number.parseFloat(price)}
              status={status}
              images={images}
              article={article}
              category={category}
              supplier={supplier}
              currency={currencyCharCode}
              properties={properties}
              aosDuration={`${index + 5}00`}
              measureUnitName={measureUnitName}
            />
          ))
          }
          <div className='text-center'>
            <Pagination
              total={productsCatalogListPages * 10}
              onChange={paginationChange}
              defaultCurrent={currentPage}
              showSizeChanger={false}
              hideOnSinglePage
            />
          </div>
          <FloatButton.BackTop />
        </div>
      )}
    </>
  );
};
