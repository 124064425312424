import React, { FC, useState } from "react";
import { RouteComponentProps } from "@reach/router";

import ReportsForm from "@/components/pages/platform/pages/Reports/ReportsForm/ReportsForm";

interface IReportsProps extends RouteComponentProps {}

const Reports: FC<IReportsProps> = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const cursorStyle: string = isLoading ? "cursor-wait" : "cursor-default";

  return (
    <div className={cursorStyle}>
      <h1 className="text-x1 mb-7">Отчеты</h1>
      <ReportsForm isLoading={isLoading} setIsLoading={setIsLoading} />
    </div>
  );
};

export default Reports;
