import React, {
  FC,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useDispatch } from "react-redux";

import { AnimatePresence, motion } from "framer-motion";
import useRootClose from "react-overlays/useRootClose";
import moment from "moment";

import useMobile from "@/hooks/useMobile";
import { NotificationListType } from "app/types";

import Common from "./common";
import DeliveryCalculation from "./deliveryСalculation";
import { readNotifications } from "@/actions/notifications.actions";

import css from "./listNotifications.modules.scss";

enum typeNotification {
  common = "EVENT_DELIVERY_CALCULATION",
  deliveryCalculation = "DELIVERY_CALCULATION",
  eventBidNoWinner = "EVENT_BID_NO_WINNER",
  eventUncommentedBet = "EVENT_UNCOMMENTED_BET",
  eventRetrade = "EVENT_RETRADE",
}

type PropsNotificationListType = {
  notificationsList: NotificationListType[];
  isNotification: boolean;
  setIsNotification: (boolean) => void;
};

const ListNotifications: FC<PropsNotificationListType> = ({
  notificationsList = [],
  isNotification,
  setIsNotification,
}) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const handleRootClose = () => setIsNotification(false);

  useRootClose(ref, handleRootClose, {
    disabled: !isNotification,
  });

  const { isMobile } = useMobile();

  const maxLengthString = isMobile ? 44 : 34;

  const getCurrentDate = useCallback((dateValue: string) => {

    const localDate = new Date();
    const utc = localDate.getTime() + (localDate.getTimezoneOffset() * 60000);
    const moscowTime = utc + (60000 * 180);

    const dateDifference = moment(moscowTime).diff(moment(dateValue));
    const dateDifferenceHours = moment.duration(dateDifference).asHours();

    if (dateDifferenceHours <= 24) {
      return `${Math.floor(dateDifferenceHours)} Ч. НАЗАД`;
    } else if (dateDifferenceHours <= 48) {
      return `ВЧЕРА, ${moment(dateValue).format("h:mm")}`;
    }
    return moment(dateValue).format("DD.MM.YYYY, h:mm");
  }, []);

  const onReadNotification = useCallback(
    (id: number, url: string, e: any) => {
      e.preventDefault();
      dispatch(readNotifications(id));
    },
    []
  );

  const renderNotificationSwitch = useCallback(
    ({ id, event, message, redirectUrl, createdAt, isRead }) => {
      switch (event) {
        case typeNotification.common:
          return (
            <Common
              id={id}
              message={message}
              redirectUrl={redirectUrl}
              createdAt={createdAt}
              isRead={isRead}
              onReadNotification={onReadNotification}
              getCurrentDate={getCurrentDate}
            />
          );
        case typeNotification.deliveryCalculation:
          return (
            <DeliveryCalculation
              message={message}
              isRead={isRead}
            />
          );
        case typeNotification.eventBidNoWinner:
          return (
            <Common
              id={id}
              message={message}
              redirectUrl={redirectUrl}
              createdAt={createdAt}
              isRead={isRead}
              onReadNotification={onReadNotification}
              getCurrentDate={getCurrentDate}
            />
          )
        case typeNotification.eventUncommentedBet:
          return (
            <Common
              id={id}
              message={message}
              redirectUrl={redirectUrl}
              createdAt={createdAt}
              isRead={isRead}
              onReadNotification={onReadNotification}
              getCurrentDate={getCurrentDate}
            />
          )
        case typeNotification.eventRetrade:
          return (
            <Common
              id={id}
              message={message}
              redirectUrl={redirectUrl}
              createdAt={createdAt}
              isRead={isRead}
              onReadNotification={onReadNotification}
              getCurrentDate={getCurrentDate}
            />
          )
        default:
          return (
            <Common
              id={id}
              message={message}
              redirectUrl={redirectUrl}
              createdAt={createdAt}
              isRead={isRead}
              onReadNotification={onReadNotification}
              getCurrentDate={getCurrentDate}
            />
          );
      }
    },
    [onReadNotification, getCurrentDate]
  );

  const renderLists = useMemo(
    () =>
      notificationsList.map(
        ({
           id,
           isRead,
           message,
           createdAt,
           redirectUrl,
         }) => (
          <div className="flex flex-col relative" key={id}>
            <div className={css.hover}>
              {renderNotificationSwitch({
                id,
                message,
                redirectUrl,
                createdAt,
                isRead,
              })}
            </div>
            <div className="mt-3 mb-5 tablet:pl-3 tablet:mt-2 tablet:mb-3">
              <hr className={css.hr} />
            </div>
          </div>
        )
      ),
    [notificationsList, isMobile, maxLengthString, getCurrentDate, renderNotificationSwitch]
  );

  return (
    <div className={css.content}>
      {isMobile ? (
        <div ref={ref}>{renderLists}</div>
      ) : (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { duration: 0.3 },
            }}
            exit={{
              opacity: 0,
              transition: { duration: 0.3 },
            }}
            className={css.profileMenu}
            ref={ref}
          >
            <div className={css.sideBarScrollable}>
              {renderLists}
            </div>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
};

export default ListNotifications;
