import { createSelector } from "reselect";
import { AppStateType } from "@/reducers";
import { InitialStateType as CurrentOrderStateType } from "@/reducers/currentOrder.reducer";
import { InitialStateType as AccountStateType } from "@/reducers/account.reducer";
import { ItemsProductType, ItemsBidType } from "app/types";

type OrderDataType = {
  name: string;
  sum: string;
  originalSum: string;
  statuses: { name: string; isActive: boolean }[];
  deliverySum: string;
  supplierName: string;
  dateCreated: string;
  deliveryAddress: string;
  isSelfPickUp: boolean;
  payType: string;
  deliveryDate: string;
  msg: string;
  prepay: string;
  currency: string;
  discount: number;
  entities: ItemsProductType[] | ItemsBidType[];
  isProducts: boolean;
  docs: { id: number; document: string }[];
  isFetching: boolean;
  isDocsRequestFailed: boolean;
  isCancelable?: boolean;
  isCanceled: boolean;
  isCancelRequestFailed: boolean;
};

type OptionsType = {
  options: { value: number; label: string }[];
  isFetching: boolean;
};

export const currentOrderSelector = createSelector(
  (state: AppStateType): CurrentOrderStateType => state.currentOrder,
  (state: AppStateType): AccountStateType => state.account,
  (
    {
      orderData,
      isFetchingOrder,
      orderDocs,
      isFetchingDocs,
      isDocsRequestFailed,
      isCanceled,
      isCancelRequestFailed,
    },
  ): OrderDataType => {
    const {
      order_name: name,
      order_sum: sum,
      original_order_sum: originalSum,
      avaliable_statuses = [],
      status,
      delivery_sum: deliverySum,
      date_created: dateCreated,
      delivery_address: deliveryAddress,
      is_selfpickup: isSelfPickUp,
      delivery_date: deliveryDate,
      supplier,
      pay_type_name: payType,
      prepay_percent: prepay,
      currency: currency,
      items = [],
      bids = [],
    } = orderData ?? {};

    const isProducts = !!items.length;

    const entities = isProducts
      ? items.map(
          ({
            id,
            modification,
            count,
            item_price: price,
            original_item_price: originalPrice,
            order_item_sum: sum,
            original_order_item_sum: originalSum,
          }) => {
            const img = modification?.product?.images?.[0]?.image;
            const name = modification?.product?.name;

            return {
              id,
              name,
              img,
              count,
              price,
              originalPrice,
              sum,
              originalSum,
              itemDiscount: +originalSum - +sum,
            };
          }
        )
      : [...bids]
          .sort((a, b) => a.order - b.order)
          .map(({ id, name }) => ({ id, name }));

    const statuses = avaliable_statuses.map(({ id, name }) => ({
      name,
      isActive: id <= status,
    }));

    const discount = +originalSum - +sum;

    const docs = orderDocs?.map(({ id, document }) => ({ id, document }));

    return {
      name,
      supplierName: supplier?.name,
      msg: supplier?.profile?.about_us ?? "",
      dateCreated,
      sum,
      originalSum,
      deliverySum,
      deliveryAddress,
      deliveryDate,
      isSelfPickUp,
      payType,
      prepay,
      currency,
      statuses,
      discount,
      entities,
      isProducts,
      docs,
      isFetching: isFetchingDocs || isFetchingOrder,
      isDocsRequestFailed,
      isCanceled,
      isCancelRequestFailed,
    };
  }
);

export const currentOrderOptionsSelector = createSelector(
  (state: AppStateType): CurrentOrderStateType => state.currentOrder,
  ({ orderOptions, isFetchingOptions: isFetching }): OptionsType => {
    const options = orderOptions?.map((option, index) => ({
      value: index,
      label: option,
    }));

    return {
      options,
      isFetching,
    };
  }
);
