enum STATUS_NAME {
  NEW = "NEW",
  UPDATED = "UPDATED",
  PROCESSING = "PROCESSING",
  CANCELLED = "CANCELLED",
  CLOSED = "CLOSED",
  PREPARATION = "PREPARATION",
  APPROVAL = "APPROVAL",
}

export const statusNameOrder = (statusName: string): string => {
  switch (statusName?.toUpperCase()) {
    case STATUS_NAME.NEW:
      return "Новый заказ";
    case STATUS_NAME.UPDATED:
      return "Согласование изменений";
    case STATUS_NAME.PROCESSING:
      return "В обработке";
    case STATUS_NAME.CANCELLED:
      return "Отменён";
    case STATUS_NAME.CLOSED:
      return "Выполнен";
    case STATUS_NAME.PREPARATION:
      return "Подготовка КП";
    case STATUS_NAME.APPROVAL:
      return "На согласовании КП";
    default:
      return "";
  }
};
