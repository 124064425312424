import React, {
  FC,
  useMemo,
  useState,
  Dispatch,
  useEffect,
  useCallback,
  SetStateAction,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "@reach/router";
import cn from "classnames";
import useMobile from "@/hooks/useMobile";

import { ordersSelector } from "@/selectors/orders.selector";

import TabsBlock from "./tabsBlock";
import OrderCard from "@/components/orderCard";
import SearchInput from "@/components/ui-kit/searchInput";
import EmptyResult from "@/components/ui-kit/emptyResult";
import Button from "@/components/ui-kit/button";
import ArrowDownIcon from "@/assets/icons/arrow-down.inline.svg";
import DownloadLink from "@/components/ui-kit/downloadLink";
import Preloader from "@/components/ui-kit/preloader";
import css from "./orders.modules.scss";
import { OrderType } from "app/types";
import { AppStateType } from "@/reducers";
import { FloatButton, Pagination, PaginationProps } from "antd";
import { getOrdersList } from "@/actions/orders.actions";

interface IOrdersPropsType extends RouteComponentProps {
  facets: { name: string; status: string; }[];
  ordersData: OrderType[];
  currentPage: number;
  activeTabIndex: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  handleTabClick: (index: number, statusName?: string) => void;
  isOrdersFiltersHovered: boolean;
}

const Orders: FC<IOrdersPropsType> = ({
  facets,
  ordersData,
  currentPage,
  setCurrentPage,
  activeTabIndex,
  handleTabClick,
}) => {
  const { isMobile } = useMobile();

  const { ordersDataPages } = useSelector((state: AppStateType) => state.orders);

  const renderOrderCard = useMemo(() => {
    if (!ordersData?.length)
      return (
        <div className="mt-4">
          <EmptyResult text="Ничего не найдено. Попробуйте изменить запрос" />
        </div>
      );

    return (
      <div className="tablet:mt-5">
        {ordersData?.map((order, index) => (
          <OrderCard key={order.id} item={order} aosDuration={`${index + 5}00`} />
        ))}
        <FloatButton.BackTop />
      </div>
    );
  }, [ordersData]);

  const paginationChange: PaginationProps['onChange'] = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className={css.orders}>
      <div
        className={`flex items-center tablet:justify-between ${css.tabBlock}`}
      >
        <TabsBlock
          facets={facets}
          handleTabClick={handleTabClick}
          activeIndex={activeTabIndex}
        />
      </div>
      {renderOrderCard}
      {!!ordersData.length && (
        <div className="mt-6 tablet:mt-8">
          {isMobile ? (
            <Button
              className={`flex tablet:hidden w-full`}
              data-aos="fade-zoom-in"
              data-aos-once="true"
            >
              показать ещё
              <ArrowDownIcon className={`stroke-current ml-2`} />
            </Button>
          ) : (
            <Pagination
              total={ordersDataPages * 10}
              onChange={paginationChange}
              defaultCurrent={currentPage}
              showSizeChanger={false}
              hideOnSinglePage
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Orders;
